import React, { useState, useEffect } from 'react';

import political_quiz_json from '../data/political_quiz.js'

const archetypeMap = {
    "utopian-virtuoso": "justice-warrior",
    "justice-warrior": "justice-warrior",

    "astute-logician": "astute-logician",

    "stalwart-nationalist": "stalwart-nationalist",

    "freedom-steward": "freedom-steward",
    "growth-capitalist": "freedom-steward",

    "social-guardian": "social-guardian",
    "civic-observer": "social-guardian"
}

function maxTraitValuesForQustion(question) {
    var maxScores = new Object();
    for (let answerKey of Object.keys(question.answers)) {
        var answerValue = question.answers[answerKey];
        for (let trait of Object.keys(answerValue)) {
            var value = Number(answerValue[trait]);
            if (!isNaN(value)) {
                maxScores[trait] = Math.max(value, maxScores[trait] || 0);
            }
        }
    }
    return maxScores;
}

function sumOfMaxTraitValues() {
    const questions = political_quiz_json.questions
    var sumOfTraitValues = new Object();
    for (let question of questions) {
        var maxTraitValue = maxTraitValuesForQustion(question);
        for (let trait of Object.keys(maxTraitValue)) {
            var value = Number(maxTraitValue[trait]);
            if (!isNaN(value)) {
                sumOfTraitValues[trait] = value + (sumOfTraitValues[trait] || 0);
            }
        }
    }
    return sumOfTraitValues;
}

function getSelectionFromUser(userAnswers) {
    const questions = political_quiz_json.questions
    var max = sumOfMaxTraitValues(questions);
    var totalsOfSelection = new Object();
    for (let i = 0; i < questions.length; i++) {

        var selection = userAnswers[i] // null // input.val();

        // TODO traitValues = trait values correlated to answer
        var traitValues = questions[i].answers[selection - 1];

        for (let trait of Object.keys(traitValues)) {
            var value = Number(traitValues[trait]);
            if (!isNaN(value)) {
                totalsOfSelection[trait] = (value / max[trait]) + (totalsOfSelection[trait] || 0);
            }
        }
    }
    return totalsOfSelection;
}


function findClosestMatch(userTraits) {
    var closestArchetype;
    var lowestSumOfDifference = Number.MAX_VALUE;
    const archetypes = political_quiz_json.archetypes
    for (let archetype of archetypes) {
        var sumOfTraitsDifferences = 0;
        for (let archetypeTrait of Object.keys(archetype)) {
            var archetypeTraitValue = Number(archetype[archetypeTrait]);
            var userTraitValue = Number(userTraits[archetypeTrait]);
            if (!isNaN(archetypeTraitValue) && !isNaN(userTraitValue)) {
                sumOfTraitsDifferences += Math.abs(archetypeTraitValue - userTraitValue);
            }
        }
        if (lowestSumOfDifference > sumOfTraitsDifferences) {
            closestArchetype = archetype;
            lowestSumOfDifference = sumOfTraitsDifferences;
        }
    }
    return closestArchetype;
}


function getArchetype(userAnswers) {
    var userTraits = getSelectionFromUser(userAnswers);
    if (userTraits) {
        var closestMatch = findClosestMatch(userTraits);
        return [closestMatch, userTraits]
    }
}

export const ArchetypeChartContainer = () => {
    const [values, setValues] = useState(Array(16).fill(1));
    const [archetype, setArchetype] = useState(null);
    const [userTraits, setUserTraits] = useState(null);
    const [hoveredQuestion, setHoveredQuestion] = useState(null);

    const handleChange = (index, value) => {
        const newValues = [...values];
        newValues[index] = parseInt(value);
        setValues(newValues);
        const [archetype, userTraits] = getArchetype(newValues);
        console.log(archetype, userTraits)
        setArchetype(archetype);
        setUserTraits(userTraits);
    };

    // Add this new function to randomize values
    const randomizeValues = () => {
        const newValues = values.map(() => Math.floor(Math.random() * 5) + 1);
        setValues(newValues);
        const [newArchetype, newUserTraits] = getArchetype(newValues);
        console.log(newArchetype, newUserTraits)

        setArchetype(newArchetype);
        setUserTraits(newUserTraits);
    };

    // Add this new function for the stress test
    const stressTest = () => {
        const archetypeCounts = {};
        for (let i = 0; i < 10000; i++) {
            const testValues = values.map(() => Math.floor(Math.random() * 5) + 1);
            const [testArchetype] = getArchetype(testValues);
            if (testArchetype) {
                archetypeCounts[testArchetype.name] = (archetypeCounts[testArchetype.name] || 0) + 1;
            }
        }
        console.log("Stress Test Results:", archetypeCounts);
    };

    // Add this new function to find the astute-logician
    const findAstuteLogician = () => {
        let attempts = 0;
        const maxAttempts = 10000; // Limit to prevent infinite loop

        const findArchetype = () => {
            attempts++;
            const newValues = values.map(() => Math.floor(Math.random() * 5) + 1);
            const [newArchetype, newUserTraits] = getArchetype(newValues);

            if (newArchetype && newArchetype.name === 'astute-logician') {
                setValues(newValues);
                setArchetype(newArchetype);
                setUserTraits(newUserTraits);
                console.log(`Found astute-logician after ${attempts} attempts`);
            } else if (attempts < maxAttempts) {
                setTimeout(findArchetype, 0); // Use setTimeout to prevent blocking
            } else {
                console.log(`Failed to find astute-logician after ${maxAttempts} attempts`);
            }
        };

        findArchetype();
    };

    useEffect(() => {
        const tooltip = document.querySelector('.answer-attributes');
        if (tooltip) {
            // setTooltipHeight(tooltip.offsetHeight);
        }
    }, [hoveredQuestion]); // Recalculate height when hovered question changes

    return (
        <div className="archetype-chart-container" style={{ backgroundColor: 'white', display: 'flex' }}>
            <div className="questions-section" style={{ flex: '1', marginRight: '20px' }}>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <button
                        onClick={randomizeValues}
                        style={{
                            padding: '10px 20px',
                            margin: '10px 0',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        Randomize Answers
                    </button>
                    <button
                        onClick={stressTest}
                        style={{
                            padding: '10px 20px',
                            margin: '10px 0',
                            backgroundColor: '#2196F3',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        Stress Test
                    </button>
                    <button
                        onClick={findAstuteLogician}
                        style={{
                            padding: '10px 20px',
                            margin: '10px 0',
                            backgroundColor: '#FF9800',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        Find Astute Logician
                    </button>
                </div>
                <div className="questions-container">
                    {values.map((value, index) => (
                        <div
                            key={index}
                            className="question-group"
                            style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                            onMouseEnter={() => setHoveredQuestion(index)}
                            onMouseLeave={() => setHoveredQuestion(null)}
                        >
                            <div className="options-container" style={{ display: 'flex', marginRight: '10px' }}>
                                {[1, 2, 3, 4, 5].map((option) => (
                                    <button
                                        key={option}
                                        className={`option-button ${value === option ? 'selected' : ''}`}
                                        onClick={() => handleChange(index, option)}
                                        style={{
                                            border: value === option ? '2px solid green' : '1px solid #ccc',
                                            padding: '10px',
                                            margin: '0 2px',
                                            borderRadius: '5px',
                                            fontSize: '12px',
                                            background: value === option ? '#e6ffe6' : 'white',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {option}
                                    </button>
                                ))}
                            </div>
                            <h3 className="question-text" style={{ flex: '1', fontSize: '12px' }}>
                                {political_quiz_json.questions[index].question}
                            </h3>
                        </div>
                    ))}
                </div>
            </div>
            <div className="archetype-section" style={{ flex: '1', maxWidth: '300px' }}>
                <h2 className="archetype-result">Determined Archetype: {archetype && archetypeMap[archetype.name]}</h2>
                {archetype
                    && archetype.name !== archetypeMap[archetype.name]
                    && <h5 className="archetype-result">remapped from <br /> Archetype: {archetype.name}</h5>}
                {archetype && (
                    <div className="archetype-properties" style={{ marginBottom: '20px' }}>
                        <h3>Archetype Properties:</h3>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th style={{ padding: '5px', textAlign: 'right' }}>Property</th>
                                        <th style={{ padding: '5px', textAlign: 'left' }}>Archetype</th>
                                        <th style={{ padding: '5px', textAlign: 'left' }}>User Traits</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(archetype).map(([key, value]) => (
                                        key !== 'name' && (
                                            <tr key={key}>
                                                <td style={{ padding: '5px', textAlign: 'right', fontWeight: 'bold' }}>
                                                    {key}:
                                                </td>
                                                <td style={{ padding: '5px', textAlign: 'left' }}>
                                                    {value}
                                                </td>
                                                <td style={{ padding: '5px', textAlign: 'left' }}>
                                                    {userTraits[key] !== undefined && (
                                                        <span style={{
                                                            color: userTraits[key] > value ? 'green' : 'red'
                                                        }}>
                                                            {userTraits[key].toFixed(2)}
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    ))}
                                </tbody>
                            </table>
                        </ul>
                    </div>
                )}
            </div>
            <div className="answer-attributes-section" style={{ flex: '1', minWidth: '300px', marginLeft: '20px' }}>
                {hoveredQuestion !== null && (
                    <div className="answer-attributes">
                        <h3>Question {hoveredQuestion + 1} Weights:</h3>
                        {political_quiz_json.questions[hoveredQuestion].answers.map((answer, answerIndex) => (
                            <div key={answerIndex}>
                                <strong style={{ fontSize: '12px' }}>Button {answerIndex + 1}:</strong>
                                <ul style={{ margin: '5px 0', paddingLeft: '40px' }}>
                                    {Object.entries(answer).map(([key, value]) => (
                                        key !== 'answer' && (
                                            <div style={{ textAlign: 'left' }}>
                                                - <span key={key}>{key}: {value}</span><br />
                                            </div>
                                        )
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
