import { updateDoc, getDocs, query, collection, where, get, count, average } from "firebase/firestore";
import { useState, useContext } from "react";
import { Button } from "semantic-ui-react"

import { doc, getFirestore } from "firebase/firestore";

import gameshowQs from '../data/gameshowQs.js';
import political_quiz_json from "../data/political_quiz.js";

import { getTeamName, fallbackResponse } from "../commonFunctions.js";
import '../css/AdminPanel.css'

const getDataForAllQuestions = (data) => {
    //NOTE!!!! some of these will be undefined
    return [data[`quizQ0`], data[`quizQ1`], data[`quizQ2`],
    data[`quizQ3`], data[`quizQ4`], data[`quizQ5`],
    data[`quizQ6`], data[`quizQ7`], data[`quizQ8`],
    data[`quizQ9`], data[`quizQ10`], data[`quizQ11`]]
}


function _objectWithoutProperties(obj, keys) {
    var target = {};
    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}

const answerToLetter = ['A', 'B', 'C', 'D']

export default function AdminQuizPanel(props) {

    const MAXTIME = 10 * 1000;

    const [questionNumber, setQuestionNumber] = useState(props.publicData.questionNumber)
    const globalContext = useContext(window.GlobalContext)
    let { isAnswered, setIsAnswered, user, showQuizResults } = globalContext

    const [dataToSendOut, setDataToSendOut] = useState({})
    const [doneCalculatingQuizResults, setDoneCalculatingQuizResults] = useState(false)

    const fs = getFirestore()

    let timerInterval = null

    const nextQuestion = async (e) => {
        if (questionNumber + 1 >= gameshowQs.length) return;
        // e.preventDefault();

        let newQuestionNumber = questionNumber === NaN ? 0 : questionNumber + 1
        console.log(newQuestionNumber);
        await updateDoc(doc(fs, "public", "globalState"),
            {
                'questionNumber': newQuestionNumber,
                'showQuizResults': false,
                'showTopicsOfInterest': false,
                'timeLeft': 0,
                'questionDone': false,
                doneCalculating: false,
            }
        );
        if (timerInterval) clearInterval(timerInterval)
        setQuestionNumber((prevNumber) => prevNumber + 1)
        setDoneCalculatingQuizResults(false)

        // setShowQuizResults(false)
    }


    const startTimer = async (e) => {
        let timeLeft = MAXTIME
        if (timerInterval !== null) return

        timerInterval = setInterval(async () => {

            let update = {
                timeLeft, MAXTIME,
                'questionDone': timeLeft === 0 ? true : false
            }

            console.log(update);

            await updateDoc(doc(fs, "public", "globalState"),
                update
            );
            if (timeLeft <= 0) {
                clearInterval(timerInterval)
            } else {
                timeLeft -= 1000
            }
        }, 1 * 1000);

    }


    const calculateQuizResults = async (value) => {
        const cueRef = doc(fs, 'public', 'globalState');

        setDoneCalculatingQuizResults(false)

        const quizRef = collection(fs, 'quiz')
        const quizQuestionKey = `quizQ${questionNumber}`

        // NOTE!!!!
        // a lot of processing is happening right now + I'm not sure how it'll scale,
        // definitely try to test if you get the chance
        // also it's pretty inneficient, but I think it works

        const collectionRef =
            getDocs(query(collection(fs, 'quiz')))
                .then(async (snapshot) => {

                    let quizAnswersForGroups = { allResults: [] },
                        quizTeamStats = {},
                        allAnswers = []

                    let allCorrectAnswers = props.publicData.allCorrectAnswers
                    allCorrectAnswers ||= {}

                    snapshot.docs.forEach(async (userQuizDataDoc) => {

                        const userData = userQuizDataDoc.data()

                        let userQuestionData = getDataForAllQuestions(userData).slice(0, questionNumber + 1)

                        console.log('userQuestionData: ', userQuestionData);

                        // if (userQuestionData[questionNumber]) {
                        allAnswers.push(fallbackResponse(userQuestionData[questionNumber]).response)
                        // } else {
                        // fallbackResponse(undefined).response
                        // }

                        userQuestionData = userQuestionData.map(fallbackResponse)
                        console.log({ userQuestionData });

                        quizAnswersForGroups.allResults ||= {}
                        quizAnswersForGroups.allResults.questionData ||= []
                        quizAnswersForGroups.allResults.ids ||= []
                        quizAnswersForGroups.allResults.displayNames ||= []
                        quizAnswersForGroups.allResults.userSortedTotalScoresWithIds ||= []

                        quizAnswersForGroups.allResults.questionData.push(userQuestionData)
                        quizAnswersForGroups.allResults.ids.push(userQuizDataDoc.id)


                        let displayName = userData.displayName === undefined ? 'testQuizAnswer' : userData.displayName;

                        quizAnswersForGroups.allResults.displayNames.push(displayName)

                        quizAnswersForGroups[userData.archetypeName] ||= {}
                        quizAnswersForGroups[userData.archetypeName].questionData ||= []
                        quizAnswersForGroups[userData.archetypeName].ids ||= []
                        quizAnswersForGroups[userData.archetypeName].displayNames ||= []
                        quizAnswersForGroups[userData.archetypeName].userSortedTotalScoresWithIds ||= []

                        quizAnswersForGroups[userData.archetypeName].questionData.push(userQuestionData)
                        quizAnswersForGroups[userData.archetypeName].ids.push(userQuizDataDoc.id)


                        quizAnswersForGroups[userData.archetypeName].displayNames.push(displayName)


                    })


                    // console.log({ quizAnswersForGroups });

                    // console.log(allAnswers);
                    // console.log(quizAnswersForGroups);

                    let answerOccurnaces = allAnswers.reduce(function (acc, curr) { return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc }, {});
                    // console.log(answerOccurnaces);
                    delete answerOccurnaces[-1]
                    const topAnswerOccurances = Math.max(...[...Object.values(answerOccurnaces), 0])
                    console.log(topAnswerOccurances);
                    let newCorrectAnswers = Object.keys(answerOccurnaces).filter(key => answerOccurnaces[key] !== undefined && answerOccurnaces[key] == topAnswerOccurances).map(Number)

                    // if there's a tie, pick one of the two answers randomly
                    // console.log({ newCorrectAnswers });
                    if (newCorrectAnswers.length > 1 || newCorrectAnswers.length === 0) {
                        newCorrectAnswers = [newCorrectAnswers[Math.floor(Math.random() * newCorrectAnswers.length)]]
                    }

                    allCorrectAnswers[questionNumber] = newCorrectAnswers
                    // console.log(newCorrectAnswers, allCorrectAnswers;

                    // if no answers for some reason, just make one up
                    // allCorrectAnswers[questionNumber] ||= Math.floor(Math.random() * 4)

                    // console.log(allAnswers, answerOccurnaces, topAnswerOccurances, newCorrectAnswers);

                    // bandaid
                    // if(topAnswerOccurances === NaN)

                    // console.log({ allCorrectAnswers, answerOccurnaces });

                    let answerCounts = {}

                    Object.keys(quizAnswersForGroups).forEach((group) => {

                        let quizDataForGroup = quizAnswersForGroups[group]

                        let userSortedTotalScoresWithIds = []

                        answerCounts[group] ||= [0, 0, 0, 0]

                        // console.log(quizDataForGroup, group);
                        // answerCounts[group] ||= [0, 0, 0, 0]

                        // if(users.questionData === undefined){
                        // }

                        quizDataForGroup.questionData.forEach((dataForQuestions, i) => {
                            // let dataForQuestions = getDataForAllQuestions(userData).slice(0, questionNumber + 1)
                            console.log({ dataForQuestions });

                            if (dataForQuestions[questionNumber].response !== undefined) {
                                answerCounts[group][dataForQuestions[questionNumber].response] += 1
                            }

                            let userTotalScore = dataForQuestions.reduce((a, b, i) => {
                                b = fallbackResponse(b)
                                // console.log(allCorrectAnswers[i], b.response, b.score);

                                allCorrectAnswers[i] ||= []// I HATE THIS I DO NOT WANNA HAVE TO DO THIS BUT I DON'T HAVE FKN TIME

                                console.log(b.response);

                                return a + ((allCorrectAnswers[i].includes(b.response)) ? Math.max(b.score, 0) : 0)
                            }, 0)

                            console.log(quizDataForGroup, i);
                            userSortedTotalScoresWithIds.push({ userTotalScore: userTotalScore, id: quizDataForGroup.ids[i], displayName: quizDataForGroup.displayNames[i] })

                            // console.log(userData, dataForQuestions, userTotalScore);
                        })

                        // NOTE: iterates through all question numbers starting from question 0,
                        // there is an error when a user has answered, say, question 3 but not question 1 (q1 is undefined)
                        // you should not encounter this error during the play, as you will always start at question 0

                        let correctUsers = quizDataForGroup.questionData.filter((answers) => {
                            // new method
                            if (answers[questionNumber] === undefined) {
                                return false
                            } else {
                                return newCorrectAnswers.includes(answers[questionNumber].response);
                            }
                            // old method
                            // return answers[questionNumber].correct
                        })

                        let numberofCorrectUsers = correctUsers.length,
                            usersCorrectOutOf = quizDataForGroup.questionData.length;

                        let totalScore = correctUsers.reduce((a, b) => a + Math.max(b[questionNumber].score, 0), 0)

                        let averageCorrectQuestionScore = totalScore / usersCorrectOutOf;
                        // correctUsers.reduce((a, b) => a + Math.max(b[questionNumber].score, 0), 0) / usersCorrectOutOf;
                        // let totalScore = Math.max(quizAllQuestionData.splice(0, questionNumber).reduce((a, b) => a + b.score, 0), 0) + questionScore;

                        userSortedTotalScoresWithIds.sort((a, b) =>
                            b.userTotalScore - a.userTotalScore
                        )

                        // TODO: fix, but not showing it right now so we're fine
                        // let totalTeamScore = correctUsers.reduce((a, b) => a + b.score, 0);

                        let huh = { usersCorrect: numberofCorrectUsers, usersCorrectOutOf: usersCorrectOutOf, averageQuestionScore: averageCorrectQuestionScore, totalScore: totalScore, userSortedTotalScoresWithIds }
                        quizTeamStats[group] = huh
                    })

                    // console.log({ answerCounts });


                    let quizTeamStatsWithoutAll = _objectWithoutProperties(quizTeamStats, 'allResults')

                    delete quizTeamStatsWithoutAll["undefined"]

                    // redundant but I don't care
                    const topGroupIndex =
                        Object.keys(quizTeamStatsWithoutAll).map(o => quizTeamStatsWithoutAll[o].totalScore)
                            .reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);

                    const bottomGroupIndex =
                        Object.keys(quizTeamStatsWithoutAll).map(o => quizTeamStatsWithoutAll[o].totalScore)
                            .reduce((iMin, x, i, arr) => x < arr[iMin] ? i : iMin, 0);

                    console.log(quizTeamStatsWithoutAll);

                    const topGroup = Object.keys(quizTeamStatsWithoutAll)[topGroupIndex]
                    const bottomGroup = Object.keys(quizTeamStatsWithoutAll)[bottomGroupIndex]


                    // console.log(userSortedTotalScoresWithIds);


                    // console.log(userSortedTotalScoresWithIds);

                    // case where there are literally no answers, just pick one at random
                    // allCorrectAnswers[questionNumber][0] ||= Math.floor(Math.random() * 4)

                    // allCorrectAnswers[questionNumber][0] ||= Math.floor(Math.random() * 4)

                    let AHHHHHH = {
                        quizTeamStats: { topGroup, bottomGroup, ...quizTeamStats, answerCounts },
                        allCorrectAnswers,
                        answerCounts,
                        doneCalculating: true,
                        'timestamp': Date.now()
                    }

                    console.log(AHHHHHH);

                    await updateDoc(cueRef, AHHHHHH)
                    window.showLocalNotification('done calculating', { body: '', displayTo: political_quiz_json.archetypesNameList })
                    setDoneCalculatingQuizResults(true)
                })
    }

    const sendOutQuizResults = async (e, value) => {

        const cueRef = doc(fs, 'public', 'globalState');
        await updateDoc(cueRef, {
            'showQuizResults': value,

        })
    }

    const previousQuestion = async (e) => {
        if (questionNumber - 1 < 0) return;
        // e.preventDefault();
        // let newQuestionNumber = questionNumber === NaN ? 0 : questionNumber + 1
        await updateDoc(doc(fs, "public", "globalState"),
            {
                'questionNumber': questionNumber - 1,
                'showQuizResults': false,
                'showTopicsOfInterest': false,
                'timeLeft': 0,
                'questionDone': false,
                doneCalculating: false,
            }
        )
        if (timerInterval) clearInterval(timerInterval)
        setQuestionNumber((prevNumber) => prevNumber - 1)
        setDoneCalculatingQuizResults(false)
        // setShowQuizResults(false)
    }

    const showTopicsOfInterest = async (e) => {
        await updateDoc(doc(fs, "public", "globalState"),
            {
                'showTopicsOfInterest': true
            }
        )
        if (timerInterval) clearInterval(timerInterval)
        // setShowQuizResults(false)
    }

    // props.publicData.allCorrectAnswers = [[-1], [-1], [-1], [-1], [-1], [-1], [-1], [-1], [-1], [-1], [-1], [-1]]
    // console.log(props.publicData.allCorrectAnswers, questionNumber);

    return <div className="panel quizPanel">
        <div>

            Quiz Question
            <br />

            <Button
                content='prevQuestion'
                icon='left arrow'
                labelPosition='left'
                onClick={(e) => previousQuestion(e)}
            />

            <Button
                content='nextQuestion'
                icon='left arrow'
                labelPosition='left'
                onClick={(e) => nextQuestion(e)}
            />
            <br />
            <span>NOTE!!!!! do not change questions quickly, phones could desync</span>

            <br />
            <span>{`current question number: ${questionNumber}`}</span>

            <progress className='timeLeftBar' max="10000" value={props.publicData.timeLeft}></progress>

            <div className='quizButton'>
                <input type="button" disabled={props.publicData.cue !== 2} onClick={(e) => startTimer()} value={'startQuestionTimer'} />
            </div>

            <div className='quizButton'>
                <input type="button" disabled={props.publicData.cue !== 2} onClick={(e) => calculateQuizResults(true)} value={'calculateQuizResults'} />
                {/* <input type="checkbox" checked={calculateQuizResults} readonly={true} /> calculatingQuizResults */}
            </div>

            <div className='quizButton'>
                <input type="button" disabled={!doneCalculatingQuizResults} onClick={(e) => sendOutQuizResults(e, true)} value={'showQuizResults'} />
                <input type="checkbox" checked={props.publicData.showQuizResults} readonly={true} />
            </div>

            {/* <div className='quizButton'>
                <input type="button" onClick={(e) => showTopicsOfInterest(e.target.checked)} value={'showTopicsOfInterest'} />
                <input type="checkbox" checked={props.publicData.showTopicsOfInterest} readonly={true} />
            </div> */}

            {(props.publicData.allCorrectAnswers === undefined || props.publicData.allCorrectAnswers[questionNumber] === undefined) ? <></>
                :
                <div className="quizResults">
                    {`correct answer: ${props.publicData?.allCorrectAnswers[questionNumber][0]}/${answerToLetter[props.publicData.allCorrectAnswers[questionNumber][0]]}`}
                    <br />
                    {`${gameshowQs[questionNumber].answers[props.publicData.allCorrectAnswers[questionNumber][0]]}`}
                    <br />
                    {`best team: ${getTeamName(props.publicData.quizTeamStats.topGroup)}`}
                </div>
            }


        </div>

    </div >
}