import React from "react";
import { Component } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.fontless.min.css";
import { useState, useEffect, useContext } from 'react';

import tstTheme from "../data/tst-theme.json";
// import ContrastDark from "survey-core/themes/contrast-dark.js"
import PropagandaTheme from "../data/survey_theme.json";

import introQuestions from "../data/intro-questions.js"
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";

import '../css/SurveyComponent.css'

// taken in part from https://www.politicalpersonality.org/test/
// and https://www.politicalpersonality.org/wp-content/themes/Impreza/js/quiz.json
import political_quiz_json from '../data/political_quiz.js'

import { getTeamName } from "../commonFunctions.js"

const archetypeMap = {
    "utopian-virtuoso": "justice-warrior",
    "justice-warrior": "justice-warrior",

    "astute-logician": "astute-logician",

    "stalwart-nationalist": "stalwart-nationalist",

    "freedom-steward": "freedom-steward",
    "growth-capitalist": "freedom-steward",

    "social-guardian": "social-guardian",
    "civic-observer": "social-guardian"
}

function maxTraitValuesForQustion(question) {
    var maxScores = new Object();
    for (let answerKey of Object.keys(question.answers)) {
        var answerValue = question.answers[answerKey];
        for (let trait of Object.keys(answerValue)) {
            var value = Number(answerValue[trait]);
            if (!isNaN(value)) {
                maxScores[trait] = Math.max(value, maxScores[trait] || 0);
            }
        }
    }
    return maxScores;
}

function sumOfMaxTraitValues() {
    const questions = political_quiz_json.questions
    var sumOfTraitValues = new Object();
    for (let question of questions) {
        var maxTraitValue = maxTraitValuesForQustion(question);
        for (let trait of Object.keys(maxTraitValue)) {
            var value = Number(maxTraitValue[trait]);
            if (!isNaN(value)) {
                sumOfTraitValues[trait] = value + (sumOfTraitValues[trait] || 0);
            }
        }
    }
    return sumOfTraitValues;
}

function getSelectionFromUser(userAnswers) {
    const questions = political_quiz_json.questions
    var max = sumOfMaxTraitValues(questions);
    var totalsOfSelection = new Object();
    for (let i = 0; i < questions.length; i++) {

        var selection = userAnswers[i] // null // input.val();

        // TODO traitValues = trait values correlated to answer
        var traitValues = questions[i].answers[selection - 1];

        for (let trait of Object.keys(traitValues)) {
            var value = Number(traitValues[trait]);
            if (!isNaN(value)) {
                totalsOfSelection[trait] = (value / max[trait]) + (totalsOfSelection[trait] || 0);
            }
        }
    }
    return totalsOfSelection;
}


function findClosestMatch(userTraits) {
    var closestArchetype;
    var lowestSumOfDifference = Number.MAX_VALUE;
    const archetypes = political_quiz_json.archetypes
    for (let archetype of archetypes) {
        var sumOfTraitsDifferences = 0;
        for (let archetypeTrait of Object.keys(archetype)) {
            var archetypeTraitValue = Number(archetype[archetypeTrait]);
            var userTraitValue = Number(userTraits[archetypeTrait]);
            if (!isNaN(archetypeTraitValue) && !isNaN(userTraitValue)) {
                sumOfTraitsDifferences += Math.abs(archetypeTraitValue - userTraitValue);
            }
        }
        if (lowestSumOfDifference > sumOfTraitsDifferences) {
            closestArchetype = archetype;
            lowestSumOfDifference = sumOfTraitsDifferences;
        }
    }
    return closestArchetype;
}


function getArchetype(userAnswers) {
    var userTraits = getSelectionFromUser(userAnswers);
    if (userTraits) {
        var closestMatch = findClosestMatch(userTraits);
        return closestMatch
    }
}

class SurveyComponent extends React.Component {

    render() {

        const fs = getFirestore();

        // const globalContext = useContext(window.GlobalContext)
        let { user, setUserData, onComplete } = this.props

        const survey = new Model(introQuestions);

        survey.applyTheme(PropagandaTheme);

        const currentShowCodeArg = window.location.search.substring(1)

        survey.onAfterRenderPage.add((sender, options) => {
            // console.log({ sender, options });


            if (sender.currentPage.name === 'agreementPage') {
                const nextButton = document.querySelector(".sd-navigation__next-btn");
                if (nextButton) {
                    nextButton.value = "Agree";
                }
            }


            const completeButton = document.querySelector(".sd-navigation__complete-btn");

            if (completeButton)
                completeButton.value = "Enter Chat";


            // console.log(currentShowCodeArg);

            if (sender.currentPage.name === 'finalPage') {

                // console.log("INHERE!!!!!!!!!!!!!!!!!!!!!!");

                const completeText = document.querySelector(".htmlFinalText");
                const allAnswers = sender.valuesHash
                const quizAnswers = [allAnswers.q1, allAnswers.q2, allAnswers.q3, allAnswers.q4, allAnswers.q5, allAnswers.q6, allAnswers.q7, allAnswers.q8, allAnswers.q9, allAnswers.q10, allAnswers.q11, allAnswers.q12, allAnswers.q13, allAnswers.q14, allAnswers.q15, allAnswers.q16]
                const archetype = getArchetype(quizAnswers)
                archetype.name = archetypeMap[archetype.name]
                let displayArchetypeName = getTeamName(archetype.name)



                // // for debugging
                // let displayArchetypeName = "Social Guardian"
                // let archetype = {
                //     name: "social-guardian"
                // }

                // completeButton.value = "Enter Chat";
                completeText.innerHTML = `
                    <span style="font-family: Arial, sans-serif; line-height: 1.6; font-size: 22px;">
                        <div style="font-size: 18px; font-weight: bold; margin-bottom: 10px;">We are processing your answers.</div>
                        <div style="font-size: 26px; color: rgba(255, 152, 20, 1); margin-bottom: 15px;">You are a ${displayArchetypeName}.</div>
                        <div style="font-style: italic; margin-bottom: 15px; font-size: 17px; text-align: justify; line-height: 1.2.em;">${political_quiz_json.welcomeMessages[archetype.name]}</div>
                        <div style="padding: 0px; padding-top: 10px; border-radius: 5px;">
                            <p style="margin-bottom: 0px; font-size: 16px;">Enter the chat where you can interact with other audience members who answered similarly.  </p>
                            <p style="margin-bottom: 0; font-size: 16px;">The chat will remain open until the show begins. Take this opportunity to get to know each other!</p>
                        </div>
                    </span>`;
            }
        });



        survey.onComplete.add(async (sender, options) => {

            // real silly but simple
            const userAnswers = [sender.data.q1, sender.data.q2, sender.data.q3, sender.data.q4, sender.data.q5, sender.data.q6, sender.data.q7, sender.data.q8, sender.data.q9, sender.data.q10, sender.data.q11, sender.data.q12, sender.data.q13, sender.data.q14, sender.data.q15, sender.data.q16]
            let archetype = getArchetype(userAnswers)

            archetype.name = archetypeMap[archetype.name]

            // console.log(archetype, archetypeMap[archetype.name]);

            updateProfile(user, {
                displayName: sender.data.displayName
            })


            const userData = {
                'displayName': sender.data.displayName,
                'seat': sender.data.seatLetter + sender.data.seatNumber,
                // 'email': user.email,
                'introAnswers': sender.data,
                'archetype': archetype,
                'role': 'audience',
                'playInstance': 0,
                'showCode': currentShowCodeArg
            }

            // window.showLocalNotification('Welcome!',
            //     {
            //         body: 'Thank you for your answers! We are processing them now. While you wait you can now chat with others in the audience who answered in a similar manner. We will explain this in a moment :)',
            //         displayTo: political_quiz_json.archetypesNameList
            //     }
            // )

            setUserData(userData)
            // console.log(userData);
            try {
                await setDoc(doc(fs, `users`, user.uid), userData);
                this.props.onComplete();
            } catch (err) {
                alert(`${err.message} Likely an invalid show code. If you believe this is an error please contact an usher.`);
            }

        });

        return <>{<Survey model={survey} />}</>;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return false
    }
}

export default SurveyComponent;