import { Timestamp, getFirestore, doc, setDoc, collection, query, orderBy, limitToLast, addDoc, updateDoc } from 'firebase/firestore'
import { getAuth } from "firebase/auth";

import { Button, FormInput } from 'semantic-ui-react';
import { useState, useContext } from 'react';

import political_quiz_json from '../data/political_quiz'
import { getTeamName } from '../commonFunctions';

export default function AdminNotificationPanel(props) {
    const db = getFirestore();

    const globalContext = useContext(window.GlobalContext)
    let { publicData, vibratingIntervalVar, setVibratingIntervalVar } = globalContext

    const [titleValue, setTitleValue] = useState('');
    const [bodyValue, setBodyValue] = useState('');

    const [vibrating, setVibrating] = useState(false);
    const [flashPoliticalGroup, setFlashPoliticalGroup] = useState(publicData.flashPoliticalGroup);

    const [archetypeChatMessageBoxes, setArchetypeChatMessageBoxes] = useState([true, true, true, true, true, true, true, true])
    const [toggleAll, setToggleAll] = useState(true);

    const sendNotification = async (e) => {
        e.preventDefault();

        let displayTo = political_quiz_json.archetypesNameList.filter((arch, i) => archetypeChatMessageBoxes[i])

        await setDoc(doc(db, "notifications", "public"),
            {
                title: titleValue,
                img: 'Fire2.gif',
                body: bodyValue,
                createdAt: Timestamp.now(),
                displayTo
            }
        );
        console.log('sent notification');


        setTitleValue('')
        setBodyValue('')

    }

    const toggleFlashPoliticalGroup = async (e) => {
        const cueRef = doc(db, 'public', 'globalState');
        setFlashPoliticalGroup(e.target.checked)
        await updateDoc(cueRef, {
            'flashPoliticalGroup': e.target.checked
        })
    }


    const toggleVibrating = async (e) => {

        let displayTo = political_quiz_json.archetypesNameList //.filter((arch, i) => archetypeChatMessageBoxes[i])

        setVibrating(e.target.checked)

        if (vibratingIntervalVar === null) {
            let msgSendCount = 0
            setVibratingIntervalVar(setInterval(() => {
                // navigator.vibrate(1000)
                setDoc(doc(db, "notifications", "public"),
                    {
                        title: '__vibrating__',
                        icon: "bruh",
                        body: msgSendCount++,
                        createdAt: Timestamp.now(),
                        displayTo
                    }
                );
                console.log("triggering vibrate");
            }, 1000))
        } else {
            clearInterval(vibratingIntervalVar)
            setVibratingIntervalVar(null)
            console.log(vibratingIntervalVar);
        }
    }

    const testVideo = 'https://video.twimg.com/ext_tw_video/1816240485800747008/pu/vid/avc1/750x618/fogPhvTy1E3b__uj.mp4?tag=12'
    const pushVideo = async (e) => {
        console.log("AHHHHH");

        let displayTo = political_quiz_json.archetypesNameList //.filter((arch, i) => archetypeChatMessageBoxes[i])

        setDoc(doc(db, "notifications", "public"),
            {
                title: '__pushVideo__',
                icon: "bruh",
                body: testVideo,
                createdAt: Timestamp.now(),
                displayTo
            }
        );

    }

    const showGroupNotification = async (e, archetype) => {
        // e.preventDefault();
        let displayTo = [archetype]

        let notification = {
            title: 'Welcome!',
            icon: "bruh",
            body: `You are a ${getTeamName(archetype)}!!!`,
            createdAt: Timestamp.now(),
            displayTo
        };

        await setDoc(doc(db, "notifications", "public"), notification);
        setTitleValue('')
        setBodyValue('')
    }

    return <>
        <div className='panel notificationPanel'>

            {/* send notification */}
            {/* <form className='sendNotification' onSubmit={sendNotification}>

                <input value={titleValue} onChange={(e) => setTitleValue(e.target.value)} placeholder="title" />
                <input value={bodyValue} onChange={(e) => setBodyValue(e.target.value)} placeholder="body" />

                <div className='boxLabelPair'>
                    <input type="checkbox"
                        value={toggleAll}
                        checked={toggleAll}
                        onChange={(e) => {
                            setToggleAll(e.target.checked)
                            e.target.checked
                                ? setArchetypeChatMessageBoxes([true, true, true, true, true, true, true, true])
                                : setArchetypeChatMessageBoxes([false, false, false, false, false, false, false, false])
                        }}
                    />
                    toggleAll
                </div>

                <div className='responses'>
                    {political_quiz_json.archetypesNameList.map((archetype, i) => {
                        return <div className='boxLabelPair'>
                            <input type="checkbox"
                                value={archetypeChatMessageBoxes[i]}
                                checked={archetypeChatMessageBoxes[i]}
                                onChange={(e) => setArchetypeChatMessageBoxes((prev) => {
                                    return prev.map((isChecked, j) => i === j ? e.target.checked : isChecked)
                                })}
                            />
                            {archetype}
                        </div>
                    })}
                </div>
                <button type="submit" className='submitButton' disabled={!(titleValue && bodyValue)}>🕊️</button>

                <div className='boxLabelPair'>
                    <span>toggle global vibration</span>
                    <input type="checkbox"
                        checked={vibratingIntervalVar === null ? false : true}
                        value={vibratingIntervalVar === null ? false : true}
                        onChange={(e) => {
                            toggleVibrating(e)
                        }} />
                </div>

                <div className='boxLabelPair'>
                    <span>toggle flash political group</span>
                    <input type="checkbox"
                        checked={flashPoliticalGroup}
                        value={flashPoliticalGroup}
                        onChange={(e) => {
                            toggleFlashPoliticalGroup(e)
                        }} />
                </div>

            </form>
 */}
            {political_quiz_json.archetypesNameList.map((archetype, i) => {
                return <Button
                    content={`intro ${archetype} message`}
                    icon='left arrow'
                    labelPosition='left'
                    onClick={(e) => showGroupNotification(e, archetype)}
                />
            })}

            {/* <button type="submit" className='addRandomVideoButton' onClick={(e) => pushVideo(e)} >pushVideo</button> */}

        </div>
    </>
}
