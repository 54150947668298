import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import { Timestamp, getFirestore, doc, setDoc, collection, query, orderBy, limitToLast, addDoc, updateDoc } from 'firebase/firestore'
import { logDOM } from "@testing-library/react";
import seedrandom from 'seedrandom';

import username_avatar_data from '../data/username_avatar_data';

const fileTypes = ['json'];

const reader = new FileReader()


const changeTweetUserData = (post) => {
    let newPostDataIndex = Math.floor(rng() * username_avatar_data.length)
    post.displayName = username_avatar_data[newPostDataIndex].displayName
    post.username = username_avatar_data[newPostDataIndex].username
    post.avatar = username_avatar_data[newPostDataIndex].avatar
    return post
}

const rng = seedrandom("tweetSeed");


function DragDropData(props) {

    const fs = getFirestore();
    const tbaPostsRef = collection(fs, 'tbaPosts');
    const tbaMessagesRef = collection(fs, 'tbaMessages');

    const addTBAPost = async (e, post) => {
        e.preventDefault();
        let huh = {
            'avatar': post.avatar,
            'username': post.username,
            'displayName': post.displayName,
            'text': (post.text === undefined || post?.text === '') ? '' : post.text,
            'media': post?.media,
            'createdAt': Timestamp.now()
        }
        console.log(huh);
        let docRef = await addDoc(tbaPostsRef, huh)

        await updateDoc(docRef, {
            'id': docRef.id
        })

    }

    const addTBAMessage = async (e, message) => {
        e.preventDefault();
        let docRef = await addDoc(tbaMessagesRef,
            {
                'displayName': message.displayName,
                'text': message.text,
                'photoURL': message.photoURL,
                'uid': "admin",
                'createdAt': Timestamp.now()
            }
        )

        await updateDoc(docRef, {
            'id': docRef.id
        })


    }

    const [file, setFile] = useState(null);
    const [jsonData, setJsonData] = useState(null);

    const handleChange = (file) => {
        reader.readAsText(file)
    };

    reader.onload = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const data = JSON.parse(reader.result)



        if (data?.tweets) {

            data.tweets.forEach(post => {

                if (post.displayName === "Atlas Burn"
                    && post?.username === 'AtlasBurn31480'
                    && post?.avatar === "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png") {

                    post = changeTweetUserData(post)
                    console.log(post);

                }

                addTBAPost(e, post)
            });
        } else if (data?.messages) {
            data.messages.forEach(post => {
                addTBAMessage(e, post)
            });

        } else if (data.notifications) {

        }




    }

    return (
        <div className="panel dragDropData">

            <FileUploader className='fileUploader' handleChange={handleChange} name="file" types={fileTypes} />
        </div>
    );
}

export default DragDropData;