import useHydra from './useHydra'
import { useEffect, useRef, useContext } from 'react'

import '../css/HydraCanvas.css'
import StockTicker from './StockTicker'
import { getDoc, getFirestore, doc } from 'firebase/firestore'

const avatarImages = {
    "justice-warrior": "AOC_200.png",
    "social-guardian": "kermit_200.png", // Mr. Rogers
    "stalwart-nationalist": "QAnon_Shaman_200.png",
    "freedom-steward": "musk_200.png",
    "astute-logician": "Spock_200.png", // get new image for spock, his head cuts off
    // "utopian-virtuoso": "kermit_.png"
}

export default function HydraCanvas(props) {
    // const [canvasRef, hydraLoaded] = useHydra()

    const archetypeName = props.userData.archetype.name;


    // useEffect(() => {
    //     if (!hydraLoaded) return;

    //     const h = window.hydra.synth

    //     if (props.hydraFunc) {
    //         props.hydraFunc(h)
    //     } else {

    //         h.s0.initImage(avatarImage)

    //         let avatar = h.src(h.s0).mask(h.shape(4).scale(1 / 0.31))

    //         h.shape(6, 0.2, 0.3)
    //             .color(0.5, 0.2, 20)
    //             .scale(() => (Math.sin(h.time / 10) * 0.2 + 2) * 1.4)
    //             .repeat(() => (Math.sin(h.time / 10) + 2) * 4)
    //             .modulateRotate(h.o0)
    //             .scale(() => (Math.sin(h.time) * 0.2 + 2))
    //             .rotate(0, .2)
    //             .modulate(h.noise(2, 0.1))
    //             // .layer(avatar.scale(0.5).modulate(h.noise(1.2)))
    //             // .invert(2.4)
    //             .out()

    //         // let pattern = () => h.osc(100, 0).kaleid(100).scale(1, 1)
    //         // //
    //         // pattern()
    //         //     .scrollX(0.2)
    //         //     .mult(pattern())
    //         //     .scrollX(0.4, 0.01)
    //         //     .colorama(0.7)
    //         //     // .layer(avatar.scale(0.5).modulate(h.noise(1.2)))
    //         //     .out()


    //         // h.osc(5, .5, 1)
    //         //     .kaleid()
    //         //     .mask(h.shape(4, 0.1, 0.5))
    //         //     .modulateRotate(h.shape(4, 0.1, 0.5))
    //         //     .modulateRotate(h.shape(4, 0.1, 0.9))
    //         //     .modulateRotate(h.shape(4, 0.1, 0.4))
    //         //     .scale(0.3)
    //         //     .add(h.shape(4, 0.2, 1).color(0.1, 0.1, .5, .5)).rotate(() => h.time / 20)
    //         //     // .blend(avatar.mask(h.shape(4).scale(2.8)).scale(0.5).modulate(h.noise(1.2)), 0.2)
    //         //     .out()

    //         // h.noise(18, 0.1, 7)
    //         //     .rotate(1, -0.02)
    //         //     .modulateScale(h.o0)
    //         //     .modulateScale(h.o0, 2,)
    //         //     // .blend(h.o0)
    //         //     .saturate(0.8)
    //         //     .colorama(0.8)
    //         //     .blend(h.o0)
    //         //     .blend(h.o0)
    //         //     .blend(h.o0)
    //         //     // .layer(avatar.scale(0.5).modulate(h.noise(1.2)))
    //         //     .out(h.o0)

    //         // h.noise(18)
    //         //     .colorama(1)
    //         //     .posterize(2)
    //         //     .kaleid(50)
    //         //     .mask(
    //         //         h.shape(25, 0.25).modulateScale(
    //         //             h.noise(400.5, 0.5)
    //         //         )
    //         //     )
    //         //     .mask(h.shape(400, 1, 2.125))
    //         //     .modulateScale(h.osc(6, 0.125, 0.05).kaleid(50))
    //         //     .mult(h.osc(20, 0.05, 2.4).kaleid(50), 0.25)
    //         //     .scale(1.75, 0.65, 0.5)
    //         //     .modulate(h.noise(0.5))
    //         //     .saturate(6)
    //         //     .posterize(4, 0.2)
    //         //     .scale(1.5)
    //         //     .out();


    //         // h.src(h.o0)
    //         //     .saturate(1.01)
    //         //     .scale(.999)
    //         //     .color(1.01, 1.01, 1.01)
    //         //     .hue(.01)
    //         //     .modulateHue(h.src(h.o1)
    //         //         .hue(.3).posterize(-1).contrast(.7), 1)
    //         //     .layer(h.src(h.o1)
    //         //         .luma()
    //         //         .mult(h.gradient(1)
    //         //             .saturate(.2)))
    //         //     .out(h.o0)
    //         // h.noise(1, .2)
    //         //     .rotate(2, .5)
    //         //     .layer(h.src(h.o0)
    //         //         .scrollX(.2))
    //         //     .invert(1)
    //         //     .out(h.o1)
    //         // h.render(h.o0)
    //     }


    // }, [hydraLoaded])

    // console.log(props);

    return (
        // TODO: make hold screen very similar to the powerplay hold screen
        <>
            <div className='holdScreen'>
                <>
                    <img className="avatarImg" alt={avatarImages[archetypeName]} src={avatarImages[archetypeName]}></img>
                </>
                <h1 className={`holdText ${props.publicData.rebrand ? "rebrand" : "preRebrand"}`}>HOLDING...</h1>
                {
                    props.publicData.stockTickerOnLandingEnabled ?
                        <StockTicker publicData={props.publicData}></StockTicker>
                        :
                        <></>
                }
            </div>
        </>
    )
}