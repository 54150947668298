import { doc, updateDoc, getFirestore, collection, getDocs, query, getDoc, orderBy, setDoc } from 'firebase/firestore'

import { useState, useContext, useEffect, useRef } from 'react';
import { Checkbox } from 'semantic-ui-react';

import { getDatabase, ref, set, update } from "firebase/database";

import political_quiz_json from '../data/political_quiz';
import { logDOM } from '@testing-library/react';

import { fallbackResponse, fallbackStockResponse, getRound, calcStockValue, stockConstants } from '../commonFunctions';

const STOCKINDEXINTERVALLENGTH = 2
const STOCKINTERVALLENGTH = 3 * 1000;
const SLIDERAGGRAGATEINTERVAL = 1 * 1000;

// https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

// START https://observablehq.com/@smitop/prices-noise

export default function AdminRealtimeDataPanel(props) {

    const fs = getFirestore()
    const db = getDatabase();

    // const stockIntervalLength = 3 * 1000;

    const globalContext = useContext(window.GlobalContext)
    let { publicData, isAggregatingSliderValues, setIsAggregatingSliderValues,
        isRunningStocks, setIsRunningStocks,
        giantSliderEnabled, setGiantSliderEnabled } = globalContext

    let nIntervalId = undefined
    let stockIntervalId = undefined

    const isRunningStocksCheckbox = useRef(null)


    const startAggregateSliderValues = () => {
        if (!nIntervalId) {
            setIsAggregatingSliderValues(setInterval(aggregateSliderValues, (SLIDERAGGRAGATEINTERVAL)))
            console.log(isAggregatingSliderValues, nIntervalId);
        }
    }

    const stopAggregateSliderValues = () => {
        setIsAggregatingSliderValues(clearInterval(isAggregatingSliderValues))
        // nIntervalId = null
        // setIsAggregatingSliderValues(false)
        console.log(isAggregatingSliderValues, nIntervalId);
    }


    const aggregateSliderValues = async () => {

        console.log('aggregateSliderValues fired');
        const collectionRef =
            getDocs(query(collection(fs, 'giantSliders'), orderBy('archetypeName')))
                .then((snapshot) => {
                    let sliderValues = {
                        allValues: []
                    }

                    if (snapshot.docs.length === 0)
                        return

                    snapshot.docs.forEach(async (sliderDoc) => {
                        sliderValues.allValues.push(sliderDoc.data().sliderValue)
                        sliderValues[sliderDoc.data().archetypeName] ||= []
                        sliderValues[sliderDoc.data().archetypeName].push(sliderDoc.data().sliderValue)
                    })

                    let sliderValueAverage = sliderValues.allValues.reduce((a, b) => a + b) / sliderValues.allValues.length;

                    Object.keys(sliderValues).forEach((key) => {
                        sliderValues[key] = sliderValues[key].reduce((a, b) => a + b) / sliderValues[key].length;
                        // sliderValues[key] = Math.random() * 100;
                    })

                    political_quiz_json.archetypesNameList.forEach((name) => {
                        if (sliderValues[name] === undefined)
                            sliderValues[name] = 50
                    })

                    // console.log(sliderValues);

                    update(ref(db, 'sliderValueStuff/'), sliderValues);

                })
    }



    const startStocks = () => {
        if (!stockIntervalId) {
            setIsRunningStocks(setInterval(runStocks, STOCKINTERVALLENGTH))
            console.log(isRunningStocks, stockIntervalId);
        }
    }

    const resetStocks = () => {
        updateDoc(doc(fs, `public`, 'globalState'), {
            stockIndex: 0
        })
    }

    const stopStocks = () => {
        setIsRunningStocks(clearInterval(isRunningStocks))
        // nIntervalId = null
        // setIsAggregatingSliderValues(false)
        console.log(isRunningStocks, stockIntervalId);
    }

    // all user investments
    const stocksCollectionRef = collection(fs, 'stocks')

    // the aggregated data
    const stocksDocRef = doc(fs, 'public', 'globalState')

    const runStocks = () => {

        let stockIndex;

        // hate this, but it's not updating properly
        getDoc(doc(fs, 'public', 'globalState')).then((doc) => {
            stockIndex = doc.data().stockIndex
        })

        const collectionRef =
            // getDocs(query(collection(fs, 'giantSliders'), orderBy('archetypeName')))
            getDocs(query(collection(fs, 'stocks')))
                .then((snapshot) => {

                    if (isNaN(stockIndex) || stockIndex === undefined)
                        stockIndex = 0;


                    let newStockObj = {
                        "totalInvestment": [0, 0],
                        "social-guardian": [0, 0],
                        "justice-warrior": [0, 0],
                        "astute-logician": [0, 0],
                        "freedom-steward": [0, 0],
                        "stalwart-nationalist": [0, 0]
                    }


                    let totalInvestmentObj = {
                        "totalInvestment": [0, 0],
                        "social-guardian": [0, 0],
                        "justice-warrior": [0, 0],
                        "astute-logician": [0, 0],
                        "freedom-steward": [0, 0],
                        "stalwart-nationalist": [0, 0]
                    }

                    snapshot.docs.forEach(async (stockDoc) => {
                        // let data = fallbackStockResponse(stockDoc.data())
                        let data = stockDoc.data()
                        // console.log('after fallbackStockResponse', data);

                        let newStockData = [0, 0]

                        newStockData = calcStockValue(data, stockConstants.initialData, stockIndex)
                        // console.log(newStockData);

                        newStockObj[data.archetypeName][0] += newStockData[0]
                        newStockObj[data.archetypeName][1] += newStockData[1]

                        newStockObj.totalInvestment[0] += newStockData[0]
                        newStockObj.totalInvestment[1] += newStockData[1]


                        totalInvestmentObj[data.archetypeName][data.finalInvestedIn] += data.finalInvested
                    })

                    console.log(totalInvestmentObj);

                    stockIndex += STOCKINDEXINTERVALLENGTH
                    // console.log(stockIndex);
                    let stockObj = newStockObj

                    // console.log("newStockObj: ", stockObj);
                    // console.log("social-guardian: ", stockObj["social-guardian"][0], stockObj["social-guardian"][1]);

                    updateDoc(doc(fs, 'public', 'globalState'), {
                        stockObj,
                        totalInvestmentObj,
                        stockIndex: stockIndex
                    })

                })
    }


    const changeGiantSlider = async (e) => {
        const cueRef = doc(fs, 'public', 'globalState');
        setGiantSliderEnabled(e.target.checked)
        if (e.target.checked)
            startAggregateSliderValues()
        else
            stopAggregateSliderValues()

        // no fucking clue why it needs to be flipped 
        // ??????
        await updateDoc(cueRef, {
            'giantSliderEnabled': !giantSliderEnabled
        })
    }

    return (<>
        <div className='panel giantSliderPanel'>

            <div className={`boxLabelPair ${giantSliderEnabled ? 'checked' : 'unchecked'}`}
                onClick={() => {
                    console.log("clicked");
                    isRunningStocksCheckbox.current.click()
                }}
            >
                <span>"giantSlider enabled", click it twice before it does anything </span>
                <input type="checkbox"
                    checked={giantSliderEnabled}
                    value={giantSliderEnabled}
                    ref={isRunningStocksCheckbox}
                    onChange={(e) => {
                        changeGiantSlider(e)
                    }} />
            </div>

            <div className='startStopButtons'>
                <button type="submit" onClick={(e) => startAggregateSliderValues()} >startGettingAverage</button>
                <button type="submit" onClick={(e) => stopAggregateSliderValues()} >stopGettingAverage</button>
            </div>

            <div className='boxLabelPair'>
                <span>isAggregatingSliderValues:      </span>
                <input type="checkbox" checked={isAggregatingSliderValues ? true : false} readonly={true} />
            </div>

            <br />



            <div className='startStopButtons'>
                <button type="submit" onClick={(e) => startStocks()} >startStocks</button>
                <button type="submit" onClick={(e) => resetStocks()} >resetStocks</button>
                <button type="submit" onClick={(e) => stopStocks()} >stopStocks</button>
            </div>

            <div className={`boxLabelPair ${isRunningStocks ? 'checked' : 'unchecked'}`}
            >
                <span>isRunningStocks:      </span>
                <input type="checkbox" checked={isRunningStocks ? true : false} readonly={true} />
                <span>stockIndex:{publicData.stockIndex}</span>
            </div>

            <div className="stockIndexSlider">
                <span>Stock Index: {publicData.stockIndex}</span>
                <input
                    type="range"
                    min="0"
                    max={stockConstants.STEPS}
                    value={publicData.stockIndex}
                    style={{ width: '100%' }}
                    onChange={(e) => {
                        const newValue = parseInt(e.target.value);
                        updateDoc(doc(fs, 'public', 'globalState'), { stockIndex: newValue });
                    }}
                />
            </div>

        </div>
    </>)
}
