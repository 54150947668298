import { useState, Fragment } from 'react';
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react';

import GoogleButton from 'react-google-button'

import '../css/Login.css'

export default function Login(props) {
    const [loginEmail, setLoginEmail] = useState(null);
    const [loginPassword, setLoginPassword] = useState(null);

    const signIn = () => {
        props.signInWithEmail(loginEmail, loginPassword);
    }

    let isAdmin = props.userData === null ? false : props.userData['role'] === 'admin';
    let loginButton = (loginEmail === '' || loginEmail === undefined || loginPassword === '' || loginPassword === undefined) ?
        <Button className='loginButton' disabled color='blue' fluid size='large' onClick={signIn}>Login</Button> :
        <Button className='loginButton' color='blue' fluid size='large' onClick={signIn}>Login</Button>;

    return (
        <>
            <div className='loginContainer'>
                <div className='loginText'>

                    <img className='tech_logo' src='logo.png'></img>
                    {/* TODO: TECH LOGO */}
                    <h3> Welcome to the <br />
                        Technocracy Foundation </h3>
                    <span className='loginTextFooter'>
                        Please enter any email address and <br />
                        a six-digit passcode <br />
                        (something as simple as '123456') <br />
                        {/* This can simply be 123456.  */}
                        All data will be deleted after the performance.
                    </span>
                </div>
                <Fragment>
                    <div className='loginFormContainer'>
                        {<Grid className='loginFormGrid' style={{ margin: '0' }} textAlign='center' verticalAlign='middle'>
                            <Grid.Column className='loginFormColumn' style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', minWidth: '300px' }}>
                                <Segment>
                                    <Header className='loginFormContainerHeader' size='huge' color='blue' textAlign='center'>
                                        Create/Log-in to your account
                                    </Header>
                                    <Form size='large'>
                                        <Segment>
                                            <Form.Input
                                                fluid icon='envelope'
                                                iconPosition='left'
                                                placeholder='E-mail Address'
                                                value={loginEmail || ''}
                                                onChange={(e) => setLoginEmail(e.target.value)}
                                                width={'auto'}
                                                className='loginFormInput'
                                            />
                                            <Form.Input
                                                fluid
                                                icon='id card'
                                                iconPosition='left'
                                                type='password'
                                                placeholder='password'
                                                value={loginPassword || ''}
                                                onChange={(e) => setLoginPassword(e.target.value)}
                                                width={'auto'}
                                                className='loginFormInput'
                                            />
                                            {loginButton}
                                        </Segment>
                                    </Form>
                                </Segment>
                            </Grid.Column>
                        </Grid>
                        }
                    </div>
                </Fragment>
            </div>
        </>
    )
}