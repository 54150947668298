import { Timestamp, getFirestore, doc, deleteDoc, collection, query, orderBy, limitToLast, addDoc, updateDoc } from 'firebase/firestore'
import { getAuth } from "firebase/auth";

import { Button, FormInput } from 'semantic-ui-react';
import { useState, useContext } from 'react';

import political_quiz_json from '../data/political_quiz'

import { useCollectionData } from 'react-firebase-hooks/firestore';

import sampleTweets from '../data/sampleTweets.json'
import '../css/SocialFeed.css'

function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    let urls = text.match(urlRegex)
    if (urls === null) {
        return text
    } else {
        return <div> {text.replace(urlRegex, '')} {urls.map(url => <a href={url} target='_blank'>{url}</a>)} </div>
    }
}

export default function AdminPremadePostPanel(props) {
    const fs = getFirestore();

    const [username, setUsername] = useState('');
    const [avatar, setAvatar] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [text, setText] = useState('');
    const [media, setMedia] = useState('');


    const globalContext = useContext(window.GlobalContext)
    let { user, userData, publicData } = globalContext

    // TODO: change this

    const postsRef = collection(fs, 'posts');
    const tbaPostsRef = collection(fs, 'tbaPosts');

    const tbaPostsQuery = query(tbaPostsRef, orderBy('createdAt'), limitToLast(150))

    const [posts] = useCollectionData(tbaPostsQuery, { idField: 'id' });

    const deletePost = async (postId) => {
        await deleteDoc(doc(fs, 'tbaPosts', postId))
    }

    const addPremadePost = async (e, premadePost, archetypeChatMessageBoxes) => {
        e.preventDefault();

        let displayTo = political_quiz_json.archetypesNameList.filter((arch, i) => archetypeChatMessageBoxes[i])

        let { username, avatar, displayName, text, media, id } = premadePost


        let post = {
            username,
            avatar,
            displayName,
            text,
            media,
            createdAt: Timestamp.now(),
            displayTo
        }

        let docRef = await addDoc(postsRef, post)

        await updateDoc(docRef, {
            'id': docRef.id
        })

        deleteDoc(doc(fs, 'tbaPosts', id))

    }


    return <>

        <div className='panel premadePostPanel'>
            add post
            <div className="premadeSocialFeedContainer">

                {posts && Object.keys(posts).map(key => <FeedPost deletePost={deletePost} addPremadePost={addPremadePost} publicData={publicData} userData={userData} postObject={posts[key]} />)}

            </div >


        </div>

    </>
}

function FeedPost(props) {
    const { username, displayName, avatar, text, media, id, db } = props.postObject;

    const [toggleAll, setToggleAll] = useState(true);
    const [archetypeChatMessageBoxes, setArchetypeChatMessageBoxes] = useState([true, true, true, true, true, true, true, true])


    if (props.postObject?.displayTo
        && !props.postObject.displayTo.includes(props.userData.archetype.name)) return;

    // very strange bug where useCollectionData returns all loaded messages when going from `messages` to `posts`
    // bandaid fix: skip if media===undefined
    // posts without images should have media = []
    if (media === undefined) return

    return (
        <>
            <div className='post'>

                <div className='post_Body'>
                    <div className='post_Header'>
                        <div className='post_TopBar'>
                            {<span className='addPostPlus' onClick={(e) => props.addPremadePost(e, props.postObject, archetypeChatMessageBoxes)}>+</span>}
                            {<span className='deletePost' onClick={(e) => props.deletePost(id)}>X</span>}
                            <div className='post_Avatar'>
                                <img src={avatar}></img>
                            </div>
                            <div className='post_HeaderText'>
                                <h3>
                                    {displayName}
                                    <br />
                                    <span className='post_Username'>
                                        @{username}
                                    </span>
                                </h3>
                            </div>
                        </div>

                        <div className='post_HeaderContent'>
                            <p> {text === undefined ? <></> : urlify(text)} </p>
                        </div>

                        {(media.length === 0 || media[0] == "") // stupid fix, should really be sovled in the tweet grabber
                            ? <></>
                            : <img src={media[0]}></img>}

                    </div>

                    {/* <div className='boxLabelPair'>
                        <input type="checkbox"
                            value={toggleAll}
                            checked={toggleAll}
                            onChange={(e) => {
                                setToggleAll(e.target.checked)
                                e.target.checked
                                    ? setArchetypeChatMessageBoxes([true, true, true, true, true, true, true, true])
                                    : setArchetypeChatMessageBoxes([false, false, false, false, false, false, false, false])
                                // console.log(e.target.checked, archetypeChatMessageBoxes);
                            }}
                        />
                        toggleAll
                    </div> */}

                    {/* <div className='responses'>
                        {political_quiz_json.archetypesNameList.map((archetype, i) => {
                            return <div className='boxLabelPair'>
                                <input type="checkbox"
                                    value={archetypeChatMessageBoxes[i]}
                                    checked={archetypeChatMessageBoxes[i]}
                                    onChange={(e) => setArchetypeChatMessageBoxes((prev) => {
                                        let huh = prev.map((isChecked, j) => i === j ? e.target.checked : isChecked)
                                        return huh
                                    })}
                                />
                                {archetype}
                            </div>
                        })}
                    </div> */}


                </div>
            </div>

        </>

    )
}