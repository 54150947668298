import React from 'react';
import gameshowQs from '../data/gameshowQs.js';
import political_quiz_json from "../data/political_quiz.js";

import { getTeamName, getRound } from '../commonFunctions.js';
import '../css/VideoTeamView.css'
import { onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Button } from 'semantic-ui-react'
import { LabelList } from 'recharts';

import { getDocs, query, collection, getFirestore } from 'firebase/firestore';

import { ArchetypeChartContainer } from './ArchetypeChartContainer.js';

const answerToLetter = ['A', 'B', 'C', 'D']

const FONT_SIZE_MULTIPLIER = 1.5;

const formatter = new Intl.NumberFormat('en-US');

export default function VideoTeamView(props) {

    let questionNumber = props.publicData.questionNumber

    const [lastTimestamp, setLastTimestamp] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [currentView, setCurrentView] = useState('quiz');

    // const [investmentData, setInvestmentData] = useState([])

    const { quizTeamStats } = props.publicData
    const { answerCounts } = quizTeamStats

    useEffect(() => {
        if (loaded) {
            // if (lastTimestamp !== undefined && lastTimestamp !== props.publicData.timestamp) {
            //     window.showLocalNotification('done calculating', { body: '', displayTo: political_quiz_json.archetypesNameList })
            // }
            setLastTimestamp(props.publicData.timestamp)
            // console.log(lastTimestamp,);
        } else {
            setLoaded(true)
        }
    }, [props.publicData])


    const roundNumber = getRound(questionNumber)
    let currentStockObj = props.publicData.finalInvestmentPageEnabled ? props.publicData.totalInvestmentObj : props.publicData.stockObj

    const currentInvestmentData = [
        {
            name: 'ATLAS',
            'Justice Warrior': currentStockObj?.['justice-warrior']?.[0] || 0,
            'Astute Logician': currentStockObj?.['astute-logician']?.[0] || 0,
            'Freedom Capitalist': currentStockObj?.['freedom-steward']?.[0] || 0,
            'Stalwart Nationalist': currentStockObj?.['stalwart-nationalist']?.[0] || 0,
            'Social Guardian': currentStockObj?.['social-guardian']?.[0] || 0,
        },
        {
            name: 'SPEEF',
            'Justice Warrior': currentStockObj?.['justice-warrior']?.[1] || 0,
            'Astute Logician': currentStockObj?.['astute-logician']?.[1] || 0,
            'Freedom Capitalist': currentStockObj?.['freedom-steward']?.[1] || 0,
            'Stalwart Nationalist': currentStockObj?.['stalwart-nationalist']?.[1] || 0,
            'Social Guardian': currentStockObj?.['social-guardian']?.[1] || 0,
        }
    ];


    // const investmentData = [
    //     {
    //         name: 'ATLAS',
    //         'Justice Warrior': props.publicData.stockObj?.['justice-warrior']?.[0] || 0,
    //         'Astute Logician': props.publicData.stockObj?.['astute-logician']?.[0] || 0,
    //         'Freedom Capitalist': props.publicData.stockObj?.['freedom-steward']?.[0] || 0,
    //         'Stalwart Nationalist': props.publicData.stockObj?.['stalwart-nationalist']?.[0] || 0,
    //         'Social Guardian': props.publicData.stockObj?.['social-guardian']?.[0] || 0,
    //     },
    //     {
    //         name: 'SPEEF',
    //         'Justice Warrior': props.publicData.stockObj?.['justice-warrior']?.[1] || 0,
    //         'Astute Logician': props.publicData.stockObj?.['astute-logician']?.[1] || 0,
    //         'Freedom Capitalist': props.publicData.stockObj?.['freedom-steward']?.[1] || 0,
    //         'Stalwart Nationalist': props.publicData.stockObj?.['stalwart-nationalist']?.[1] || 0,
    //         'Social Guardian': props.publicData.stockObj?.['social-guardian']?.[1] || 0,
    //     }
    // ];


    const colors = ['#8884d8', '#82ca9d', 'rgb(218, 168, 30)', '#ff7300', '#0088fe'];

    const answersChartData = [
        {
            name: 'A',
            'answers': answerCounts['allResults']?.[0] || 0,
            // 'Astute Logician': answerCounts['astute-logician']?.[0] || 0,
            // 'Freedom Steward': answerCounts['freedom-steward']?.[1] || 0,
            // 'Stalwart Nationalist': answerCounts['stalwart-nationalist']?.[0] || 0,
            // 'Social Guardian': answerCounts['social-guardian']?.[0] || 0,

        },
        {
            name: 'B',
            'answers': answerCounts['allResults']?.[1] || 0,
            // 'Astute Logician': answerCounts['astute-logician']?.[1] || 0,
            // 'Freedom Steward': answerCounts['freedom-steward']?.[1] || 0,
            // 'Stalwart Nationalist': answerCounts['stalwart-nationalist']?.[1] || 0,
            // 'Social Guardian': answerCounts['social-guardian']?.[1] || 0,
        },
        {
            name: 'C',
            'answers': answerCounts['allResults']?.[2] || 0,
            // 'Astute Logician': answerCounts['astute-logician']?.[2] || 0,
            // 'Freedom Steward': answerCounts['freedom-steward']?.[2] || 0,
            // 'Stalwart Nationalist': answerCounts['stalwart-nationalist']?.[2] || 0,
            // 'Social Guardian': answerCounts['social-guardian']?.[2] || 0,
        },
        {
            name: 'D',
            'answers': answerCounts['allResults']?.[3] || 0,
            // 'Astute Logician': answerCounts['astute-logician']?.[3] || 0,
            // 'Freedom Steward': answerCounts['freedom-steward']?.[3] || 0,
            // 'Stalwart Nationalist': answerCounts['stalwart-nationalist']?.[3] || 0,
            // 'Social Guardian': answerCounts['social-guardian']?.[3] || 0,
        }
    ];

    // console.log(props.publicData.totalInvestmentObj);


    // TANYA account:
    // email: tayna@admin.com
    // password: 123456

    // ERIN account:
    // email: erin@admin.com
    // password: erin@admin.com


    return (
        props.userData.displayName === "Tanya"
            ? <>
                {
                    currentView === 'quiz' && (
                        < div className='videoTeamView viewComponent' >

                            <div className="quizSection tanya">
                                <div className="questionText">
                                    question: {((questionNumber % 3) + 1)}
                                    <br />
                                    {gameshowQs[questionNumber].question}
                                </div>

                                <div className="answerButtons">
                                    {gameshowQs[questionNumber].answers.map((answer, i) => (
                                        <button
                                            key={i}
                                            className={`answerButton ${props.publicData.allCorrectAnswers &&
                                                props.publicData.allCorrectAnswers[questionNumber] !== undefined &&
                                                props.publicData.allCorrectAnswers[questionNumber][0] === i ? 'correctAnswer' &&
                                                    props.publicData.doneCalculating ? 'correctAnswer' : ''
                                                : ''
                                                }`}
                                        >
                                            <span className="answerLetter">{answerToLetter[i]}</span>
                                            <span className="answerText">{answer}</span>
                                            {roundNumber === 2
                                                && props.publicData.doneCalculating
                                                && <span className="answerCount">{answerCounts['allResults']?.[i] || 0}</span>}
                                        </button>
                                    ))}
                                </div>

                                <progress className='videoTeamViewTimeLeftBar' max="10000" value={props.publicData.timeLeft}></progress>

                                {props.publicData.allCorrectAnswers && props.publicData.allCorrectAnswers[questionNumber] !== undefined && (
                                    <div className="videoTeamViewQuizResults">
                                        <span className='bestTeam'>
                                            {`Best team: ${getTeamName(props.publicData.quizTeamStats.topGroup)}`}
                                        </span>
                                        <span className='bestTeam'>
                                            {`Worst team: ${getTeamName(props.publicData.quizTeamStats?.bottomGroup)}`}
                                        </span>
                                        <span className={props.publicData.showQuizResults ? "showingResults" : "notShowingResults"}>
                                            {props.publicData.showQuizResults ? "Showing quiz results to audience" : "Not showing quiz results to audience"}
                                        </span>
                                    </div>
                                )}

                                {roundNumber === 2 && <div className="investmentBreakdown tanya" style={{ width: '100%', margin: '0px 0' }}>
                                    {/* <h3 style={{ textAlign: 'center', marginBottom: '15px' }}>Investment Breakdown</h3> */}
                                    <table className="investmentTable" style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 4px' }}>
                                        <thead>
                                            <tr style={{}}>
                                                <th style={{ padding: '8px', borderRadius: '5px 0 0 5px' }}>Team</th>
                                                <th style={{ padding: '8px' }}>ATLAS</th>
                                                <th style={{ padding: '8px', borderRadius: '0 5px 5px 0' }}>SPEEF</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: 'left' }}>
                                            {Object.entries(currentStockObj || {}).sort((a, b) => {
                                                const totalA = a[1][0] + a[1][1];
                                                const totalB = b[1][0] + b[1][1];
                                                return totalB - totalA;
                                            }).map(([group, values]) => {
                                                if (group !== 'totalInvestment') {
                                                    return (
                                                        <tr key={group} style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                                            <td style={{ padding: '8px', borderRadius: '5px 0 0 5px' }}>{getTeamName(group)}</td>
                                                            <td style={{ padding: '8px' }}>${values[0].toFixed(2)}</td>
                                                            <td style={{ padding: '8px', borderRadius: '0 5px 5px 0' }}>${values[1].toFixed(2)}</td>
                                                        </tr>
                                                    );
                                                }
                                                return null;
                                            })}
                                            <tr className="totalRow" style={{ fontWeight: 'bold', backgroundColor: 'rgba(255, 152, 20, 0.6)' }}>
                                                <td style={{ padding: '10px', borderRadius: '5px 0 0 5px' }}>Total</td>
                                                <td style={{ padding: '10px' }}>${currentStockObj?.totalInvestment[0].toFixed(2)}</td>
                                                <td style={{ padding: '10px', borderRadius: '0 5px 5px 0' }}>${currentStockObj?.totalInvestment[1].toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>}
                            </div>
                        </div>
                    )
                }

            </>
            : <>
                < div className='videoTeamView viewComponent' >
                    <div className="viewButtons">
                        <Button className={`viewButton ${currentView === 'quiz' ? 'activeViewButton' : ''}`} onClick={() => setCurrentView('quiz')}>Quiz View</Button>
                        <Button className={`viewButton ${currentView === 'answers' ? 'activeViewButton' : ''}`} onClick={() => setCurrentView('answers')}>Answers Chart</Button>
                        <Button className={`viewButton ${currentView === 'investment' ? 'activeViewButton' : ''}`} onClick={() => setCurrentView('investment')}>{props.publicData.finalInvestmentPageEnabled ? 'Final' : ''} Investment Chart</Button>
                        <Button className={`viewButton ${currentView === 'archetype' ? 'activeViewButton' : ''}`} onClick={() => setCurrentView('archetype')}>Archetype Chart</Button>
                    </div>

                    {
                        currentView === 'quiz' && (
                            <div className="quizSection">
                                <div className="questionText">
                                    question: {((questionNumber % 3) + 1)}
                                    <br />
                                    {gameshowQs[questionNumber].question}
                                </div>

                                <div className="answerButtons">
                                    {gameshowQs[questionNumber].answers.map((answer, i) => (
                                        <button
                                            key={i}
                                            className={`answerButton ${props.publicData.allCorrectAnswers &&
                                                props.publicData.allCorrectAnswers[questionNumber] !== undefined &&
                                                props.publicData.allCorrectAnswers[questionNumber][0] === i ? 'correctAnswer' &&
                                                    props.publicData.doneCalculating ? 'correctAnswer' : ''
                                                : ''
                                                }`}
                                        >
                                            <span className="answerLetter">{answerToLetter[i]}</span>
                                            <span className="answerText">{answer}</span>
                                            {roundNumber === 2
                                                && props.publicData.doneCalculating
                                                && <span className="answerCount">{answerCounts['allResults']?.[i] || 0}</span>}
                                        </button>
                                    ))}
                                </div>

                                <progress className='videoTeamViewTimeLeftBar' max="10000" value={props.publicData.timeLeft}></progress>

                                {props.publicData.allCorrectAnswers && props.publicData.allCorrectAnswers[questionNumber] !== undefined && (
                                    <div className="videoTeamViewQuizResults">
                                        <span className='bestTeam'>
                                            {`Best team: ${getTeamName(props.publicData.quizTeamStats.topGroup)}`}
                                        </span>
                                        <span className='bestTeam'>
                                            {`Worst team: ${getTeamName(props.publicData.quizTeamStats?.bottomGroup)}`}
                                        </span>
                                        <span className={props.publicData.showQuizResults ? "showingResults" : "notShowingResults"}>
                                            {props.publicData.showQuizResults ? "Showing quiz results to audience" : "Not showing quiz results to audience"}
                                        </span>
                                    </div>
                                )}



                                {roundNumber === 2 && <div className="investmentBreakdown" style={{ width: '100%', margin: '0px 0' }}>
                                    {/* <h3 style={{ textAlign: 'center', marginBottom: '15px' }}>Investment Breakdown</h3> */}
                                    <table className="investmentTable" style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 4px' }}>
                                        <thead>
                                            <tr style={{}}>
                                                <th style={{ padding: '8px', borderRadius: '5px 0 0 5px' }}>Team</th>
                                                <th style={{ padding: '8px' }}>ATLAS</th>
                                                <th style={{ padding: '8px', borderRadius: '0 5px 5px 0' }}>SPEEF</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: 'left' }}>
                                            {Object.entries(currentStockObj || {}).sort((a, b) => {
                                                const totalA = a[1][0] + a[1][1];
                                                const totalB = b[1][0] + b[1][1];
                                                return totalB - totalA;
                                            }).map(([group, values]) => {
                                                if (group !== 'totalInvestment') {
                                                    return (
                                                        <tr key={group} style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                                            <td style={{ padding: '8px', borderRadius: '5px 0 0 5px' }}>{getTeamName(group)}</td>
                                                            <td style={{ padding: '8px' }}>${values[0].toFixed(2)}</td>
                                                            <td style={{ padding: '8px', borderRadius: '0 5px 5px 0' }}>${values[1].toFixed(2)}</td>
                                                        </tr>
                                                    );
                                                }
                                                return null;
                                            })}
                                            <tr className="totalRow" style={{ fontWeight: 'bold', backgroundColor: 'rgba(255, 152, 20, 0.6)' }}>
                                                <td style={{ padding: '10px', borderRadius: '5px 0 0 5px' }}>Total</td>
                                                <td style={{ padding: '10px' }}>${currentStockObj?.totalInvestment[0].toFixed(2)}</td>
                                                <td style={{ padding: '10px', borderRadius: '0 5px 5px 0' }}>${currentStockObj?.totalInvestment[1].toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>}


                            </div>
                        )
                    }

                    {
                        currentView === 'answers' && (

                            <div className="answersChartContainer viewComponent">
                                <div className="questionText">
                                    Question {questionNumber + 1}
                                    <br />
                                    {gameshowQs[questionNumber].question}
                                    <br />
                                </div>

                                {/* <div className='answersList'>
                                    {gameshowQs[questionNumber].answers.map((answer, index) => (
                                        <div key={index}
                                            className={`answerListItem ${props.publicData.allCorrectAnswers &&
                                                props.publicData.allCorrectAnswers[questionNumber] !== undefined &&
                                                props.publicData.allCorrectAnswers[questionNumber][0] === index ? 'correctAnswer' &&
                                                    props.publicData.doneCalculating ? 'correctAnswer' : ''
                                                : ''
                                                }`}
                                        >
                                            {answerToLetter[index]}: {answer}
                                        </div>
                                    ))}
                                </div> */}

                                {props.publicData.doneCalculating && <ResponsiveContainer width="100%" height={'100%'} className="answersChart">
                                    <BarChart
                                        layout="horizontal"
                                        data={answersChartData}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" fontSize={35} />
                                        <YAxis />

                                        <Bar dataKey="answers" fill="rgba(255, 152, 20, 1)">

                                            <LabelList
                                                dataKey="answers"
                                                position="top"
                                                fill="white"
                                                fontSize={25}
                                                content={(props) => {
                                                    const { x, y, width, value, index } = props;
                                                    return (
                                                        value === 0 ? null :
                                                            <g>
                                                                <text x={x + width / 2} y={y + 50} fill="white" textAnchor="middle" fontSize={40}>
                                                                    {value}
                                                                </text>
                                                            </g>
                                                    );
                                                }}
                                            />
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>}
                            </div>
                        )
                    }

                    {
                        currentView === 'investment' && (
                            <div className="investmentChartContainer viewComponent">
                                <ResponsiveContainer width="100%" height={'100%'} className={"investmentChart"}>
                                    <BarChart layout="vertical" data={currentInvestmentData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis style={{ color: 'white' }} type="number" tickFormatter={(value) => `$${value}`} />
                                        <YAxis style={{ color: 'white' }} fontSize={'1.2em'} dataKey="name" type="category" width={100} />
                                        {Object.keys(currentInvestmentData[0]).filter(key => key !== 'name').map((key, index) => (
                                            <Bar key={key} dataKey={key} stackId="a" fill={colors[index % colors.length]}>
                                                <LabelList
                                                    dataKey={key}
                                                    position="center"
                                                    content={(props) => {
                                                        const { y, height, index } = props;
                                                        const dataKeys = Object.keys(currentInvestmentData[0]).filter(k => k !== 'name');
                                                        const isLastGroup = key === dataKeys[dataKeys.length - 1];

                                                        if (isLastGroup) {
                                                            const total = Object.values(currentInvestmentData[index]).reduce((sum, val) =>
                                                                typeof val === 'number' ? sum + val : sum, 0);

                                                            return (
                                                                <g>
                                                                    <text x={110} y={y + 10 + height / 2} fill="#fff" textAnchor="left"
                                                                        fontSize={'2em'}
                                                                        style={{ fontWeight: 'bold' }}
                                                                    >
                                                                        {`$${formatter.format(total.toFixed(2))}`}
                                                                    </text>
                                                                </g>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                />
                                            </Bar>
                                        ))}
                                        <Legend
                                            layout="vertical"
                                            verticalAlign="bottom"
                                            align="center"
                                            style={{
                                                fontSize: '20px',
                                                lineHeight: '10px',
                                                marginTop: '20px'
                                            }}
                                            formatter={(value, entry, index) => {
                                                const total = currentInvestmentData.reduce((sum, item) => sum + item[value], 0);
                                                return (
                                                    <table style={{ width: '100%', fontSize: '26px', lineHeight: '20px', backgroundColor: entry.color, borderRadius: '5px' }}>
                                                        <tr>
                                                            <td style={{ color: 'white', textAlign: 'left', padding: '5px' }}>{value}:</td>
                                                            <td style={{ color: 'white', textAlign: 'right', padding: '5px' }}>${formatter.format(total.toFixed(2))}</td>
                                                        </tr>
                                                    </table>
                                                );
                                            }}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        )
                    }


                    {
                        currentView === 'archetype' && (
                            <div className="archetypeChartContainer viewComponent">
                                <ArchetypeChartContainer />

                            </div>
                        )
                    }


                </div >
            </>
    )
}
