import { useEffect, useRef, useState, useContext } from 'react'
// import { getDatabase, ref, query, get, push, set, onValue, orderByChild, limitToFirst, limitToLast, onChildAdded } from "firebase/database";
import { Timestamp, getFirestore, doc, collection, query, orderBy, limitToLast, addDoc, updateDoc, deleteDoc } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { BiSend } from "react-icons/bi";

import political_quiz_json from '../data/political_quiz';

import { getAuth } from "firebase/auth";

import '../css/ChatRoom.css';
import { getTeamName } from '../commonFunctions';

export default function ChatRoom(props) {
    const dummy = useRef();
    const fs = getFirestore();
    const auth = getAuth();

    const globalContext = useContext(window.GlobalContext)
    let { user, userData, publicData } = globalContext

    const [formValue, setFormValue] = useState('');

    const messagesRef = collection(fs, 'messages');

    const messagesQuery = query(messagesRef, orderBy('createdAt'), limitToLast(150))

    const [messages] = useCollectionData(messagesQuery, { idField: 'id' });


    const sendMessage = async (e) => {
        e.preventDefault();

        const { uid, photoURL } = auth.currentUser;
        const userArchetypeName = userData.archetype.name
        const text = formValue

        setFormValue('');

        let docRef = await addDoc(messagesRef,
            {
                text,
                createdAt: Timestamp.now(),
                uid,
                userArchetypeName,
                photoURL,
                displayName: user.displayName
            }
        )

        await updateDoc(docRef, {
            'id': docRef.id
        })

        dummy?.current.scrollIntoView({ behavior: 'smooth' });
    }

    useEffect(() => {
        dummy?.current.scrollIntoView({ behavior: 'smooth' });
        // console.log('in useEffect, messages updated');
    }, [messages])

    const isRebranded = publicData.rebrand ? 'rebrand' : 'preRebrand'

    // console.log(messages);

    const archetypeName = userData.archetype.name

    const logoSVG = publicData.rebrand
        ? <div className="logo-container rebrand">
            <svg className="logo" viewBox="0 0 24 24" aria-hidden="true" class="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-lrsllp r-1nao33i r-16y2uox r-8kz0gk">
                <g> <path fill="white" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z">
                </path> </g></svg>
            <span className="logo-text">.gov</span>
        </div>
        : <div className="logo-container preRebrand">
            <span className='logo'>𝕋</span>
        </div>

    return (<>
        <div className={`chat `}>
            {!props?.adminPanel && <div className={"top-bar " + isRebranded}>
                {logoSVG}
            </div>}

            <div className={`chatContainer ${isRebranded}`}>


                {/* <ChatMessage userData={userData} publicData={publicData}
                    messageObject={{
                        text: `Welcome to the ${getTeamName(archetypeName)} Chat. You can chat with your group and you will participate as a team of individuals during the show to earn money and increase your voice.`,
                        uid: '123', displayName: '', createdAt: Timestamp.now(), id: '123', userArchetypeName: archetypeName
                    }} fs={fs}
                /> */}

                {/* <ChatMessage userData={userData} publicData={publicData}
                    messageObject={{ text: `You are one of us`, uid: '123', displayName: '', createdAt: Timestamp.now(), id: '123', userArchetypeName: archetypeName }} fs={fs}
                /> */}

                {/* <ChatMessage userData={userData} publicData={publicData}
                    messageObject={{ text: political_quiz_json.welcomeMessages[archetypeName], uid: '123', displayName: '', createdAt: Timestamp.now(), id: '123', userArchetypeName: archetypeName }} fs={fs}
                /> */}

                {messages && Object.keys(messages).map(key => <ChatMessage adminPanel={props.adminPanel} userData={userData} publicData={publicData} messageObject={messages[key]} fs={fs} />)}

                <span ref={dummy}></span>
            </div>

            <form className='sendMessageForm' onSubmit={sendMessage}>
                <input className='sendMessage' value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="type here to chat with your group" />
                <button type="submit" className='sendMessageButton' disabled={!formValue}><BiSend /></button>
            </form>
        </div >
    </>)


    function ChatMessage(props) {
        const { text, uid, displayName, createdAt, id, userArchetypeName } = props.messageObject;

        const auth = getAuth()

        if (!props.adminPanel && ((props.messageObject?.displayTo && !props.messageObject.displayTo.includes(props.userData.archetype.name))
            || (props.publicData.politicalChatEnabled && (props.userData.archetype.name !== props.messageObject?.userArchetypeName)))) return;

        const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

        let c = new Date((createdAt.seconds * 1000));
        let year = c.getFullYear();
        let month = c.getMonth();
        let day = c.getDate();
        let hour = c.getHours();
        let minute = c.getMinutes();
        let second = c.getSeconds();
        let date = new Date(year + 4, month, day, hour, minute, second);

        const isRebranded = props.publicData.rebrand ? 'rebrand' : 'preRebrand'

        const deletePost = async (id) => {
            await deleteDoc(doc(props.fs, 'messages', id))
        }

        return (<>
            <div className={`messageContainer ${messageClass}`}>
                <p className={`teamname ${isRebranded}`}>{`${getTeamName(userArchetypeName)}`}</p>
                <div className={`message ${messageClass}`} >
                    {props.userData.role === 'admin'
                        ? <span className='chatRoomDeletePost' onClick={() => deletePost(id)}>x</span>
                        : <></>}
                    < p > {`${displayName === '' ? '' : displayName + ":"} ${props.publicData.oneMessageEnabled ? props.publicData.oneMessage : text}`}</p >

                </div >
                {/* TODO: style teamname */}
                <p className={`messageCreatedAt ${isRebranded}`}>{date.toLocaleString()}</p>
            </div>

        </>)
    }
}
