import { Button } from 'semantic-ui-react'
import ChatRoom from './ChatRoom'
import SocialFeed from './SocialFeed'

import { onSnapshot, doc, } from 'firebase/firestore'

import '../css/AppView.css'
import { Suspense, useContext, useState } from 'react'

import { BiSolidVirus, BiMenu, BiConversation, BiMessageSquareDetail } from "react-icons/bi";
import { FaHeadSideVirus } from "react-icons/fa6";

import GiantSlider from '../components/GiantSlider'

import DelayedRender from '../components/DelayedRender'
import FakeMenu from '../components/FakeMenu'

import { IconContext } from "react-icons";

const messagesIcon = <svg><g><path d="M1.998 4.499c0-.828.671-1.499 1.5-1.499h17c.828 0 1.5.671 1.5 1.499v2.858l-10 4.545-10-4.547V4.499zm0 5.053V19.5c0 .828.671 1.5 1.5 1.5h17c.828 0 1.5-.672 1.5-1.5V9.554l-10 4.545-10-4.547z"></path></g></svg>

const feedIcon = <svg viewBox="0 0 24 24"><g><path d="M21.591 7.146L12.52 1.157c-.316-.21-.724-.21-1.04 0l-9.071 5.99c-.26.173-.409.456-.409.757v13.183c0 .502.418.913.929.913h6.638c.511 0 .929-.41.929-.913v-7.075h3.008v7.075c0 .502.418.913.929.913h6.639c.51 0 .928-.41.928-.913V7.904c0-.301-.158-.584-.408-.758zM20 20l-4.5.01.011-7.097c0-.502-.418-.913-.928-.913H9.44c-.511 0-.929.41-.929.913L8.5 20H4V8.773l8.011-5.342L20 8.764z"></path></g></svg>



export default function AppView(props) {

    // Warning: do not use hooks in here, they should not be necessary  not necessary + will break the component

    let [menu, setMenu] = useState(false)
    // let [giantSlider, setGiantSlider] = useState(false)

    const globalContext = useContext(window.GlobalContext)
    let { chat, setChat, user, userData, publicData, signOutUser } = globalContext
    let { forceFeed } = props.publicData

    return <>

        <Suspense>
            <IconContext.Provider value={{ color: "white", className: 'buttonIcons' }}>
                <div className={publicData.rebrand ? `appView rebrand` : `appView preRebrand`}>
                    {publicData.rebrand
                        ?
                        <>
                            {<FakeMenu signOutUser={signOutUser} className={menu ? 'enabled' : 'disabled'} />}
                            <div className={`contentWindow ${menu ? 'menuEnabled' : ''}`}>
                                {(chat && !forceFeed && (true)) ?
                                    <Suspense>
                                        <ChatRoom />
                                    </Suspense>
                                    :
                                    <Suspense>
                                        <SocialFeed />
                                    </Suspense>
                                }
                            </div>
                            {/* <hr id='top-divider' /> */}
                            {forceFeed ? <></> :
                                <div className='buttonBar'>
                                    <Button className='bottomPanelButton' onClick={() => setMenu(!menu)} content={<BiMenu value={{ color: 'black' }} />} />
                                    <hr className='button-divider' />
                                    <Button onClick={() => setChat(false)} content={<FaHeadSideVirus />} />
                                    <hr className='button-divider' />
                                    <Button onClick={() => setChat(true)} content={<BiConversation />} />
                                    {/* <hr className='button-divider' />
                <Button className='bottomPanelButton' onClick={() => setGiantSlider(!giantSlider)} content='tst' /> */}
                                </div>
                            }
                        </>
                        : <>
                            {publicData.forceFeed
                                ? <>
                                    <Suspense>
                                        <SocialFeed />
                                    </Suspense>
                                </>
                                :
                                <>
                                    <Suspense>
                                        <ChatRoom />
                                    </Suspense>
                                </>
                            }
                        </>
                    }

                </div>
            </IconContext.Provider>
        </Suspense >
    </>
}