import { Button } from 'semantic-ui-react'
import '../css/FakeMenu.css'

export default function FakeMenu(props) {

    return (
        <div className={`menuContainer`}>
            <div className={`${props.className} menu`}>
                <Button className="signOutButton" onClick={props.signOutUser} title={"sign out"}> Sign Out </Button>
                <span>Taxes</span>
                <span>Crime Record</span>
                <span>Healthcare</span>
                <span>Some shit</span>
                <span>Idk</span>
                <span>Social Credit score, idk</span>
                <span>Grindr.gov</span>
                <span>Draft Status</span>
                <span>Talk to AI models about policy</span>
                <span>Talk to AI models of your dead relatives</span>
                <span className="verificationMessage">
                    You must wait while officials verify your account before you can access these features
                </span>
            </div>
        </div>
    )

}