import { useEffect, useRef } from 'react';
import { push, update } from 'firebase/database'
import '../css/GiantSlider.css'

import StockTicker from './StockTicker';

import { useState, useContext } from 'react';
import { Button } from 'semantic-ui-react'

import { getFirestore, doc, onSnapshot, setDoc, updateDoc, addDoc, aggregateFieldEqual, getDoc } from 'firebase/firestore';

import HydraCanvas from './HydraCanvas';

import { getTeamName, fallbackResponse, getRound } from '../commonFunctions';

import '../css/PowerPlayPages.css'
import PowerPlayLogo from '../data/PowerPlay_Logo.png'

import { formatNumberWithCommas } from '../commonFunctions';

const getDataForAllQuestions = (data) => {
    //NOTE!!!! some of these will be undefined
    return [data[`quizQ0`], data[`quizQ1`], data[`quizQ2`],
    data[`quizQ3`], data[`quizQ4`], data[`quizQ5`],
    data[`quizQ6`], data[`quizQ7`], data[`quizQ8`],
    data[`quizQ9`], data[`quizQ10`], data[`quizQ11`]]
}


function InvestmentPage(props) {

    const globalContext = useContext(window.GlobalContext)
    let { user, userData, publicData, localQuizStats } = globalContext

    let { questionNumber, allCorrectAnswers } = publicData

    const fs = getFirestore()

    const stateRef = doc(fs, 'public', 'globalState');

    const [hasInvested, setHasInvested] = useState(false)

    const [roundScore, setRoundScore] = useState(0)
    const [totalBucks, setTotalBucks] = useState(0)

    // console.log(publicData.investmentPageEnabled, hasInvested);

    const quizRef = doc(getFirestore(), 'quiz', user.uid)
    const stockRef = doc(getFirestore(), 'stocks', user.uid)

    useEffect(() => {
        if (publicData.investmentPageEnabled && !hasInvested) {
            setHasInvested(false)
        } else if (!publicData.investmentPageEnabled) {
            // delay for when it's being disabled, the screen doesn't immediately switch
            setTimeout(() => {
                setHasInvested(false)
            }, 3000)
        }

        // getDoc(stockRef).then((snapshot) => {
        //     // console.log(snapshot.data());
        //     investments = calcStockValue(snapshot.data(), initialData, localStockIndex)
        // })


        getDoc(quizRef).then((snapshot) => {

            if (snapshot.data() === undefined) {
                setTotalBucks(0)
                setRoundScore(0)
                return;
            }

            // got this codebase is shit
            // this should be its own reusable function across multiple components
            // console.log(user.uid, snapshot.data());
            let quizAllQuestionData = getDataForAllQuestions(snapshot.data()).splice(0, questionNumber + 1)
            // let huh = fallbackResponse(quizAllQuestionData[questionNumber])
            // let questionCorrect = allCorrectAnswers[questionNumber].includes(huh.response)

            let updatedScores = quizAllQuestionData.map((b, i) => {
                // console.log(allCorrectAnswers[i], b.response, b.score);

                b = fallbackResponse(b)

                return ((allCorrectAnswers[i].includes(b.response)) ? Math.max(b.score, 0) : 0)
            })


            let newRoundScore = updatedScores.slice(questionNumber - 2, questionNumber + 1).reduce((a, b) => a + b, 0)
            setRoundScore(newRoundScore)

            if (window.investments === undefined || roundNumber === 0) {
                setTotalBucks(updatedScores.reduce((a, b) => a + b, 0))
            } else {
                setTotalBucks(window.investments[0] + window.investments[1] + newRoundScore)
            }

            // console.log(updatedScores, roundScore);


        })

    }, [publicData.investmentPageEnabled])


    const investmentImages = ["TechBro_Shield_V3.png", "SPEEF_icon_V3.png"]
    const investmentTitles = ["Atlas Tech Fund", "Sustainable Progress Equitable Equity Fund"]

    const investmentShortText = ["ATLAS", "SPEEF"]

    const roundNumber = getRound(questionNumber)

    const investInFinalRound = (investment, investmentTotal) => {
        setHasInvested(true)

        // NOTE: should only open investment page on 

        let roundData = {
            [`finalInvested`]: investmentTotal,
            [`finalInvestedIn`]: investment,
            archetypeName: userData.archetype.name
        }

        // console.log(roundData, user.uid);

        updateDoc(doc(fs, `stocks`, user.uid), roundData).catch(error => {
            switch (error.code) {
                case 'not-found':
                    setDoc(doc(fs, `stocks`, user.uid), roundData)
                    break;
                default:
                    console.error(error)
                    break;
            }
        })


    }


    const investIn = (investment) => {
        setHasInvested(true)


        // NOTE: should only open investment page on 

        let roundData = roundNumber === 3 ?
            {
                [`round${roundNumber}Score`]: roundScore,
                [`round1Investedin`]: investment,
                [`round2Investedin`]: investment,
                [`round3Investedin`]: investment,
                archetypeName: userData.archetype.name
            }
            : {
                [`round${roundNumber}Score`]: roundScore,
                [`round${roundNumber}Investedin`]: investment,
                archetypeName: userData.archetype.name
            }

        // console.log(roundData, user.uid);

        updateDoc(doc(fs, `stocks`, user.uid), roundData).catch(error => {
            switch (error.code) {
                case 'not-found':
                    setDoc(doc(fs, `stocks`, user.uid), roundData)
                    break;
                default:
                    console.error(error)
                    break;
            }
        })


    }

    return (
        <div className={`investmentPage ${props.className}`}>
            {
                hasInvested === false ?
                    <>
                        <table className='investmentSummary'>
                            <tbody>
                                <tr>
                                    <td className='investmentTitle'>Total bucks:</td>
                                    <td className='investmentValue'>${formatNumberWithCommas(totalBucks)}</td>
                                </tr>
                                <tr>
                                    <td className='investmentTitle'>Bucks this round:</td>
                                    <td className='investmentValue'>${formatNumberWithCommas(roundScore)}</td>
                                </tr>
                            </tbody>
                        </table>

                        <span className='investmentSubtitle'>choose your investment:</span>
                        <button className='investmentButton' onClick={() => {
                            if (hasInvested === false) {
                                if (roundNumber === 2) {
                                    investInFinalRound(0, totalBucks)
                                } else {
                                    investIn(0)
                                }
                                setHasInvested(0)

                            }
                        }}>
                            <img src={investmentImages[0]} alt="ATLAS" />
                            <span>ATLAS</span>
                        </button>
                        <button className='investmentButton' onClick={() => {
                            if (hasInvested === false) {
                                if (roundNumber === 2) {
                                    investInFinalRound(1, totalBucks)
                                } else {
                                    investIn(1)
                                }
                                setHasInvested(1)
                            }
                        }}>
                            <img src={investmentImages[1]} alt="SPEEF" />
                            <span>SPEEF</span>
                        </button>
                    </>
                    : <>
                        <div className='hasInvestedDiv'>

                            <span className='hasInvestedTitle'>You have invested ${roundNumber === 2 ? formatNumberWithCommas(totalBucks) : formatNumberWithCommas(roundScore)} in </span><br />
                            <span className='hasInvestedShortText'>{investmentShortText[hasInvested]}</span>
                            <br />
                            <span className='hasInvestedText'>(the {investmentTitles[hasInvested]})</span>
                        </div>

                        <button className='hasInvestedButton'>
                            <img className='hasInvestedImage' src={investmentImages[hasInvested]} alt={investmentShortText[hasInvested]} />
                            <span>{investmentShortText[hasInvested]}</span>
                        </button>
                    </>
            }

        </div >
    )

}


function PowerPlayLanding(props) {

    // const globalContext = useContext(window.GlobalContext)
    // let { questionNumber, setQuestionNumber, user, publicData, userData, playAudio, localQuizStats } = globalContext
    // const { allCorrectAnswers } = publicData


    return (
        <div className={`powerPlayLanding ${props.className}`}>
            <div className='powerplayEffectDiv'>
                <img className="powerPlayLogo" alt="PowerPlayLogo" src={PowerPlayLogo}></img>


            </div>
        </div>
    )
}

function XGOVLanding(props) {
    const globalContext = useContext(window.GlobalContext)
    let { questionNumber, setQuestionNumber, user, publicData, userData, playAudio, localQuizStats } = globalContext

    const logoSVG = <div className="xGOVLogoContainer">
        <svg className="logo" viewBox="0 0 24 24" aria-hidden="true" class="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-lrsllp r-1nao33i r-16y2uox r-8kz0gk">
            <g> <path fill="white" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z">
            </path>
            </g>
            <text fill="white" fontSize={'3px'} x={16} y={12} fontStyle={'bold italic'}>.gov</text>
        </svg>
    </div>

    return (
        <div className={`xGOVLanding ${props.className}`}>
            <span className='xGOVLandingSpan'>
                {/* TODO: have actual X.GOV logo */}
                {logoSVG}
                <img className="xGOVAvatarImg" src={'musk_LG.png'}></img>
            </span>
        </div>
    )
}

function fireLanding(props) {
    return (
        <div className={`fireLanding ${props.className}`}>
            <span className='fireLanding'>
                <img className="fireLandingImage" src={''}></img>
                FIRE LANDING!!!!!!!!!
            </span>
        </div>
    )
}

function AmericanFlagLanding(props) {
    return (
        <div className={`americanFlagLanding ${props.className}`}>
            <img className='americanFlagGif' src='american_flag.gif'></img>
        </div >
    )
}

function InterestPage(props) {

    return (
        <div className={`interestPage ${props.className}`}>
            <img className='fireGif' src='Fire2.gif'></img>
        </div >
    )
}


export { PowerPlayLanding, InvestmentPage, InterestPage, XGOVLanding, fireLanding, AmericanFlagLanding }
