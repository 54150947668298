import { doc, updateDoc, deleteDoc, getFirestore, collection, getDocs, query, getDoc, orderBy, where, addDoc, writeBatch, setDoc } from 'firebase/firestore'

import { useState, useContext } from 'react';
import { Checkbox } from 'semantic-ui-react';

import { getDatabase, ref, set, update } from "firebase/database";
import { deleteUser } from 'firebase/auth';

import { calcStockValue, stockConstants } from '../commonFunctions';

import political_quiz_json from '../data/political_quiz'

async function deleteCollection(db, collectionPath) {
    const q = query(collection(db, collectionPath));
    const querySnapshot = await getDocs(q);
    const deleteOps = [];
    querySnapshot.forEach((doc) => {
        deleteOps.push(deleteDoc(doc.ref));
    });
}


async function deleteUsersCollection(db) {
    const q = query(collection(db, 'users'));
    const querySnapshot = await getDocs(q);
    const deleteOps = [];
    querySnapshot.forEach((doc) => {
        console.log(doc.data().role);
        if (doc.data().role !== 'admin')
            deleteOps.push(deleteDoc(doc.ref));
    });
}

const genScore = (score) => {
    // don't care about 1-lining this lmao
    if (8000 <= score) {
        return 1000
    } else if (6000 <= score && score < 8000) {
        return 800
    } else if (4000 <= score && score < 6000) {
        return 600
    } else if (2000 <= score && score < 4000) {
        return 400
    } else if (0 <= score && score < 2000) {
        return 200
    } else if (score <= 0) {
        return 0
    }
}


const generateResponse = () => {
    return {
        response: Math.floor(Math.random() * 5) - 1,
        score: genScore(Math.random() * 10000),
    }
}

const generateUserResponses = async (fs) => {
    let obj = {}

    const quizCollectionRef = collection(fs, 'quiz')
    obj['archetypeName'] = political_quiz_json.archetypesNameList[Math.floor(Math.random() * political_quiz_json.archetypesNameList.length)]
    for (let i = 0; i < 12; i++) obj[`quizQ${i}`] = generateResponse()

    addDoc(quizCollectionRef, obj)
}


const generateFakeStockData = async (fs, stockIndex) => {
    const stocksCollectionRef = collection(fs, 'stocks')


    const genScoreX3 = () => {
        return genScore((Math.random() * 10000) - 1000) + genScore((Math.random() * 10000) - 1000) + genScore((Math.random() * 10000) - 1000)
    }

    let fakeStockData = {
        round0Investedin: Math.floor(Math.random() * 2),
        round0Score: genScoreX3(),
        round1Investedin: Math.floor(Math.random() * 2),
        round1Score: genScoreX3(),
        round2Investedin: Math.floor(Math.random() * 2),
        round2Score: genScoreX3(),
        archetypeName: political_quiz_json.archetypesNameList[Math.floor(Math.random() * political_quiz_json.archetypesNameList.length)],
    }

    const calculatedStockValues = calcStockValue(fakeStockData, stockConstants.initialData, stockIndex)

    fakeStockData.finalInvested = calculatedStockValues[0] + calculatedStockValues[1]
    fakeStockData.finalInvestedIn = Math.floor(Math.random() * 2)

    // console.log(fakeStockData);

    addDoc(stocksCollectionRef, fakeStockData)
}

const generateFakeStockDataSkewAtlas = async (fs, stockIndex) => {
    const stocksCollectionRef = collection(fs, 'stocks')


    const genScoreX3 = () => {
        return genScore((Math.random() * 10000) - 1000) + genScore((Math.random() * 10000) - 1000) + genScore((Math.random() * 10000) - 1000)
    }

    let fakeStockData = {
        round0Investedin: 0,
        // round0Investedin: Math.floor(Math.random() * 1.2),
        round0Score: genScoreX3(),
        round1Investedin: 0,
        // round1Investedin: Math.floor(Math.random() * 1.2),
        round1Score: genScoreX3(),
        // round2Investedin: Math.floor(Math.random() * 1.2),
        round2Investedin: 0,
        round2Score: genScoreX3(),
        archetypeName: political_quiz_json.archetypesNameList[Math.floor(Math.random() * political_quiz_json.archetypesNameList.length)],
    }

    const calculatedStockValues = calcStockValue(fakeStockData, stockConstants.initialData, stockIndex)

    fakeStockData.finalInvested = calculatedStockValues[0] + calculatedStockValues[1]
    fakeStockData.finalInvestedIn = Math.floor(Math.random() * 1.3)

    // console.log(fakeStockData);

    addDoc(stocksCollectionRef, fakeStockData)
}


const millionToAtlas = async (fs, stockIndex) => {
    const stocksCollectionRef = collection(fs, 'stocks')


    const genScoreX3 = () => {
        return genScore((Math.random() * 10000) - 1000) + genScore((Math.random() * 10000) - 1000) + genScore((Math.random() * 10000) - 1000)
    }

    let fakeStockData = {
        round0Investedin: 0,
        // round0Investedin: Math.floor(Math.random() * 1.2),
        round0Score: genScoreX3(),
        round1Investedin: 0,
        // round1Investedin: Math.floor(Math.random() * 1.2),
        round1Score: genScoreX3(),
        // round2Investedin: Math.floor(Math.random() * 1.2),
        round2Investedin: 0,
        round2Score: genScoreX3(),
        archetypeName: political_quiz_json.archetypesNameList[Math.floor(Math.random() * political_quiz_json.archetypesNameList.length)],
    }

    // const calculatedStockValues = calcStockValue(fakeStockData, stockConstants.initialData, stockIndex)

    fakeStockData.finalInvested = 100000
    fakeStockData.finalInvestedIn = 0

    // console.log(fakeStockData);

    addDoc(stocksCollectionRef, fakeStockData)
}

export default function AdminTestPanel(props) {

    const fs = getFirestore()
    const db = getDatabase();

    const [deleteQuizAnswers, setDeleteQuizAnswers] = useState(0)
    const deleteQuizConfirmText = ["Delete Quiz Answers", "are you sure?", "are you ABSOLUTELY sure?"]

    const [deleteMessages, setDeleteMessages] = useState(0)
    const deleteMessagesConfirmText = ["Delete Messages", "are you sure?", "are you ABSOLUTELY sure?"]

    const [deleteUsers, setDeleteUsers] = useState(0)
    const deleteUsersConfirmText = ["Delete Users", "are you sure?", "are you ABSOLUTELY sure?", "are you ULTRA sure?"]

    const [deletePosts, setDeletePosts] = useState(0)
    const deletePostsConfirmText = ["Delete Posts", "are you sure?", "are you ABSOLUTELY sure?", "are you ULTRA sure?"]

    const [resetPublicData, setResetPublicData] = useState(0)
    const resetPublicDataConfirmText = ["Reset publicData", "are you sure?", "are you ABSOLUTELY sure?", "are you ULTRA sure?"]

    const [deleteStocks, setDeleteStocks] = useState(0)
    const deleteStocksConfirmText = ["Delete Stocks", "are you sure?", "are you ABSOLUTELY sure?", "are you ULTRA sure?"]

    const [oneMessage, setOneMessage] = useState(props.publicData.oneMessage);
    const [oneMessageEnabled, setOneMessageEnabled] = useState(props.publicData.oneMessageEnabled);
    const oneMessageRef = doc(fs, 'public', 'globalState');

    const globalContext = useContext(window.GlobalContext)
    let { publicData, publicDataDefaults } = globalContext


    return (<>
        <div className='panel testPanel giantSliderPanel'>


            <br />

            <div className='startStopButtons'>
                <button type="submit"
                    onClick={(e) => {
                        setDeleteMessages(prev => prev + 1)
                        if (deleteMessages < 2) {
                            return
                        } else {
                            deleteCollection(fs, 'messages')
                            setDeleteMessages(0)
                        }
                    }} >{deleteMessagesConfirmText[deleteMessages]}</button>
            </div>

            <div className='startStopButtons'>
                <button type="submit"
                    onClick={(e) => {
                        setDeletePosts(prev => prev + 1)
                        if (deletePosts < 2) {
                            return
                        } else {
                            deleteCollection(fs, 'posts')
                            setDeletePosts(0)
                        }
                    }} >{deletePostsConfirmText[deletePosts]}</button>
            </div>


            <br />

            <div className='startStopButtons'>
                <button type="submit"
                    onClick={(e) => {
                        setDeleteQuizAnswers(prev => prev + 1)
                        if (deleteQuizAnswers < 2) {
                            return
                        } else {
                            deleteCollection(fs, 'quiz')
                            setDeleteQuizAnswers(0)
                        }
                    }} >{deleteQuizConfirmText[deleteQuizAnswers]}</button>
            </div>


            {/* <div className='startStopButtons'>
                <button type="submit"
                    onClick={(e) => {
                        setResetPublicData(prev => prev + 1)
                        if (resetPublicData < 3) {
                            return
                        } else {
                            setDoc(doc(fs, 'public', 'globalState'), publicDataDefaults)
                        }
                    }} >{resetPublicDataConfirmText[resetPublicData]}</button>
            </div>
            <br /> */}


            <div className='startStopButtons'>
                <button type="submit"
                    onClick={(e) => {
                        setDeleteStocks(prev => prev + 1)
                        if (deleteStocks < 3) {
                            return
                        } else {
                            deleteCollection(fs, 'stocks')
                            setDeleteStocks(0)
                        }
                    }} >{deleteStocksConfirmText[deleteStocks]}</button>
            </div>
            <br />


            <div className='startStopButtons'>
                <button type="submit"
                    onClick={(e) => {
                        setDeleteUsers(prev => prev + 1)
                        if (deleteUsers < 3) {
                            return
                        } else {
                            deleteUsersCollection(fs)
                            deleteCollection(fs, 'giantSliders')
                            deleteCollection(fs, 'quiz')
                            deleteCollection(fs, 'stocks')
                            setDeleteUsers(0)
                        }
                    }} >{deleteUsersConfirmText[deleteUsers]}</button>
            </div>
            <br />


            <div className='startStopButtons'>
                <button type="submit"
                    onClick={async (e) => {
                        const publicData = await getDoc(doc(fs, 'public', 'globalState'))
                        for (let i = 0; i < 10; i++) {
                            generateFakeStockData(fs, publicData.data()?.stockIndex || 2000)
                        }
                    }} >{'generateFakeStockData'}</button>
            </div>


            <div className='startStopButtons'>
                <button type="submit"
                    onClick={async (e) => {
                        const publicData = await getDoc(doc(fs, 'public', 'globalState'))
                        for (let i = 0; i < 10; i++) {
                            generateFakeStockDataSkewAtlas(fs, publicData.data()?.stockIndex || 2000)
                        }
                    }} >{'generateFakeStockDataSkewAtlas'}</button>
            </div>


            <div className='startStopButtons'>
                <button type="submit"
                    onClick={async (e) => {
                        const publicData = await getDoc(doc(fs, 'public', 'globalState'))
                        // for (let i = 0; i < 10; i++) {
                        millionToAtlas(fs, publicData.data()?.stockIndex || 2000)
                        // }
                    }} >{'100,000 to Atlas'}</button>
            </div>

            <br />
            <br />

            <div className='startStopButtons'>
                <button type="submit"
                    onClick={(e) => {
                        for (let i = 0; i < 10; i++) {
                            generateUserResponses(fs)
                        }
                    }} >{'generateFakeQuizAnswers'}</button>
            </div>


            <br />

            <div className='startStopButtons'>
                <button type="submit"
                    onClick={(e) => {
                        let messages = []
                        getDocs(query(collection(fs, 'messages')))
                            .then((snapshot) => {
                                messages = snapshot.docs.map(doc => {
                                    let displayName = doc.data().displayName
                                    let text = doc.data().text
                                    let archetypeName = doc.data().archetypeName
                                    let createdAt = doc.data().createdAt
                                    return { displayName, text, archetypeName, createdAt }
                                    // return doc.data()
                                });
                                console.log(messages);
                            })
                    }} >{'grab messages'}</button>
            </div>
            <br />

            <div className='adminOneMessagePanel'>
                <p>set/toggle oneMessage</p>
                <form className='oneMessage'>

                    <input value={oneMessage} onChange={(e) => setOneMessage(e.target.value)} placeholder="username" />

                    <input type="checkbox" value={oneMessageEnabled} checked={oneMessageEnabled} onChange={async (e) => {
                        setOneMessageEnabled(e.target.checked)
                        let docRef = await updateDoc(oneMessageRef,
                            {
                                oneMessage: oneMessage,
                                oneMessageEnabled: !oneMessageEnabled,
                            }
                        )
                    }} />

                </form>
            </div>

        </div>
    </>)
}