import { getDoc, doc, getFirestore } from "firebase/firestore"
import seedrandom from 'seedrandom';


const formatter = new Intl.NumberFormat('en-US');

const getTeamName = (archetypeName) => {
    const huh = {
        "justice-warrior": "Justice Warrior",
        "astute-logician": "Astute Logician",
        "stalwart-nationalist": "Stalwart Nationalist",
        "freedom-steward": "Freedom Capitalist",
        "social-guardian": "Social Guardian",
    }
    return huh[archetypeName]
}

const fallbackResponse = (b) => {
    // console.log('input: ', b);
    b = b
    let output
    if (b === undefined || b.response === undefined || b.score === undefined) {
        output = {
            score: 0,
            response: -1
        }
    } else {
        output = b
    }

    // console.log('output:', output);
    return output
}

const fallbackStockResponse = (b) => {
    b[`round0Score`] ||= 0;
    b[`round1Score`] ||= 0;
    b[`round2Score`] ||= 0;

    b[`round0Investedin`] ||= Math.floor(Math.random() * 2);
    b[`round1Investedin`] ||= Math.floor(Math.random() * 2);
    b[`round2Investedin`] ||= Math.floor(Math.random() * 2);

    return b
}

const getRound = (questionNumber) => {
    if (questionNumber <= 2) return 0
    else if (2 < questionNumber && questionNumber <= 5) return 1
    else if (5 < questionNumber && questionNumber <= 8) return 2
    else if (8 < questionNumber && questionNumber <= 11) return 3
}



const getUserInvestments = (uid, setUserInvestments) => {
    getDoc(doc(getFirestore(), `stocks`, uid))
        .then((snapshot) => {

            let data = snapshot.data()
            let investments = [0, 0]

            if (data.round0Investedin && data.round0Score)
                investments[data.round0Investedin] += data.round0Score


            if (data.round1Investedin && data.round1Score)
                investments[data.round1Investedin] += data.round1Score

            if (data.round2Investedin && data.round2Score)
                investments[data.round2Investedin] += data.round2Score

            // setUserInvestments(investments)
        })
        .catch(error => {
            switch (error.code) {
                case 'not-found':
                    return {}
                    break;
                default:
                    console.error(error)
                    break;
            }
        })
}





const calcStockValue = (snapshotData, initialData, stockIndex) => {
    // return sharesBought * currentSharePrice

    let investments = [0, 0]

    if ((snapshotData.round0Investedin !== undefined) && (snapshotData.round0Score !== undefined)) {
        const initialSharePrice = initialData[0][snapshotData.round0Investedin === 0 ? 'ATLAS' : 'SPEEF'];
        const currentSharePrice = initialData[stockIndex][snapshotData.round0Investedin === 0 ? 'ATLAS' : 'SPEEF'];
        const sharesBought = snapshotData.round0Score / initialSharePrice;
        investments[snapshotData.round0Investedin] += sharesBought * currentSharePrice;
        // console.log('round0', { initialSharePrice, currentSharePrice, sharesBought }, investments[snapshotData.round0Investedin]);
    }

    if ((snapshotData.round1Investedin !== undefined) && (snapshotData.round1Score !== undefined)) {
        const initialSharePrice = initialData[600][snapshotData.round1Investedin === 0 ? 'ATLAS' : 'SPEEF'];
        const currentSharePrice = initialData[stockIndex][snapshotData.round1Investedin === 0 ? 'ATLAS' : 'SPEEF'];
        const sharesBought = snapshotData.round1Score / initialSharePrice;
        investments[snapshotData.round1Investedin] += sharesBought * currentSharePrice;
        // investments[snapshotData.round1Investedin] += snapshotData.round1Score;
        // console.log('round1', { initialSharePrice, currentSharePrice, sharesBought }, investments[snapshotData.round1Investedin]);
    }


    if ((snapshotData.round2Investedin !== undefined) && (snapshotData.round2Score !== undefined)) {
        const initialSharePrice = initialData[1200][snapshotData.round2Investedin === 0 ? 'ATLAS' : 'SPEEF'];
        const currentSharePrice = initialData[stockIndex][snapshotData.round2Investedin === 0 ? 'ATLAS' : 'SPEEF'];
        const sharesBought = snapshotData.round2Score / initialSharePrice;
        investments[snapshotData.round2Investedin] += sharesBought * currentSharePrice;
        // console.log('round2', { initialSharePrice, currentSharePrice, sharesBought }, investments[snapshotData.round2Investedin]);
    }



    return investments
}

const N = 2, STEPS = 4000, NEG_CHANCE = 0.5, SEED = "huhwhat?????????", SIZE = 1;
const STOCKFRAME = 30;
const STOCKFLOOR = 26;
const STOCKINITIALOFFSET = 100;
const SCALEADJUSTMENT = 1000;

const ATLAS_SCALE = 2;
const SPEEF_SCALE = 1;


function rand(rng) {
    let val = (rng() * SIZE);
    if (rng() < NEG_CHANCE) val *= -1;
    return val;
}

function integrate(d) {
    let total1 = 0, total2 = 0;
    let result = [];
    d.forEach(({ ATLAS, SPEEF }) => {
        result.push({ ATLAS: total2, SPEEF: total1 });
        total1 += ATLAS;
        total2 += SPEEF;
    });
    return result;
}

const genInitialData = () => {
    const rng = seedrandom(SEED + "vxq");
    let data = [];


    for (let i = 0; i < STEPS; i++) {
        let [gen1, gen2] = [rand(rng), rand(rng)]
        data.push({ index: i, ATLAS: gen2, SPEEF: gen1 })
    }

    let derivs = nthDeriv(data)
    derivs.forEach((d) => {
        d.ATLAS = ((d.ATLAS / SCALEADJUSTMENT) + STOCKFLOOR) ** ATLAS_SCALE
        d.SPEEF = ((d.SPEEF / SCALEADJUSTMENT) + STOCKFLOOR) * SPEEF_SCALE
    })
    return derivs;
}


const nthDeriv = (data) => {
    let derivs = data;
    for (let i = 0; i < N; i++) derivs = integrate(derivs);
    return derivs;
}

const localInitialData = genInitialData()

const stockConstants = {
    N, STEPS, NEG_CHANCE, SEED, SIZE, STOCKFRAME, STOCKFLOOR, STOCKINITIALOFFSET, SCALEADJUSTMENT,
    initialData: localInitialData
}

const formatNumberWithCommas = (number) => {
    return formatter.format(number.toFixed(0))
}


export { getTeamName, getUserInvestments, fallbackResponse, getRound, fallbackStockResponse, calcStockValue, stockConstants, formatNumberWithCommas }




