import skirballSeats from "./skirballSeats.js"
import agreement from "./agreement.js"


const voteCaption = "1: strongly disagree, 3: neutral, 5: strongly agree"

// documentation: 
const introQuestions = {
    "description": "",
    "logo": "./old_logo.png",
    "logoWidth": "auto",
    "logoHeight": "80px",
    "logoFit": "cover",
    "width": "800",
    "pages": [

        // {
        //     "name": "finalPage",
        //     "elements": [
        //         {
        //             "type": "html",
        //             "html": "<span class='htmlTitleText'>Thank you for your answers!</span>",
        //         },
        //         {
        //             "type": "html",
        //             "html": "<div class='htmlFinalText' style='text-align: center;'>We are processing them now. Press 'Complete' to chat with other audience members who answered like you.</div>",
        //         }
        //     ]
        // },


        {
            "name": "namepage",
            "elements": [
                {
                    "type": "html",
                    "name": "question1",
                    "html": `<span class="htmlTitleText">\n Welcome to the Technocracy Foundation's Citizen Interface.`
                    // "html": `<span class="htmlTitleText">\n Welcome to the Technocracy Foundation's Citizen Interface.  Please answer the following questions on a scale from strongly disagree to strongly agree, with neutral feeling as the center choice. Your answers will help us create a custom profile and serve you information important to you! You will also use this app to chat with each other as well as interact with the stage. </span>`
                },
                {
                    "type": "text",
                    "name": "displayName", // key in {key : answer} database object
                    "title": "Enter your Display Name", // question asked
                    /* NOTE: might want to restructure to make contextualizing questions in the database easier, 
                     but will probably end up doing a bunch of specific shit based on keys `q1`, `q2`, (etc...) anyway */
                    // "autoGenerate": false,
                    "isRequired": true
                }
            ]
        },


        // {
        //     "name": "seatpage",
        //     "elements": [
        //         {
        //             "type": "dropdown",
        //             "name": "seatLetter", // key in {key : answer} database object
        //             "title": "What row is your seat in", // question asked
        //             "description": "your seat number is on the lip of the seat you're sitting on",
        //             /* NOTE: might want to restructure to make contextualizing questions in the database easier, 
        //              but will probably end up doing a bunch of specific shit based on keys `q1`, `q2`, (etc...) anyway */
        //             "choices": ["A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U"],
        //             // "autoGenerate": false,
        //             "isRequired": true
        //         },
        //         {
        //             "type": "dropdown",
        //             "name": "seatNumber", // key in {key : answer} database object
        //             "title": "What is your seat number", // question asked
        //             /* NOTE: might want to restructure to make contextualizing questions in the database easier, 
        //              but will probably end up doing a bunch of specific shit based on keys `q1`, `q2`, (etc...) anyway */
        //             "choices": ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
        //             // "autoGenerate": false,
        //             "isRequired": true
        //         }
        //     ]
        // },

        // {
        //     "name": "????",
        //     "elements": [
        //         {
        //             "type": "dropdown",
        //             "name": "comeToStage", // key in {key : answer} database object
        //             "title": "Are you willing to come to the stage to participate in the Game Show?", // question asked
        //             /* NOTE: might want to restructure to make contextualizing questions in the database easier, 
        //              but will probably end up doing a bunch of specific shit based on keys `q1`, `q2`, (etc...) anyway */
        //             "choices": ["Yes", "No"],
        //             "autoGenerate": false,
        //             "isRequired": true
        //         },
        //     ]
        // },
        {
            "name": "agreementPage",
            "elements": [
                {
                    "type": 'html',
                    'name': 'agreementPreText',
                    'html': '<span class="htmlTitleText">You must agree to the terms before you continue. Please hit "Agree" at the bottom to agree.</span>'

                },
                {
                    "type": "html",
                    "name": "agreement",
                    "html": `<span class="agreement">${agreement.content}</span>`
                }
            ]
        },
        {
            "name": "welcomePage",
            "elements": [

                {
                    "type": "html",
                    "name": "welcome",
                    "html": "<span class='htmlTitleText'>Welcome to the Technocracy Foundation's Citizen Interface.</span>"
                },
                {
                    "type": "html",
                    "name": "questionsIntro",
                    "html": "<span class='htmlCaptionText'>\nPlease answer the following questions on a scale from 'strongly disagree' to 'strongly agree', with neutral feeling as the center choice. Your answers will help us create a custom profile based on your political perspective! </span>"
                }
            ]
        },

        {
            "name": "page1",
            "elements": [
                {
                    "type": "rating",
                    "name": "q1",
                    "title": "I frequently re-post political articles or comment on them in my social media feeds",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page2",
            "elements": [
                {
                    "type": "rating",
                    "name": "q2",
                    "title": "I engage with a lot of political content every week",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page3",
            "elements": [
                {
                    "type": "rating",
                    "name": "q3",
                    "title": "I often feel people are talking about me in public spaces",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page4",
            "elements": [
                {
                    "type": "rating",
                    "name": "q4",
                    "title": "While Creationism is clearly compelling, it's just a theory",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page5",
            "elements": [
                {
                    "type": "rating",
                    "name": "q5",
                    "title": "Everything's fine",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page6",
            "elements": [
                {
                    "type": "rating",
                    "name": "q6",
                    "title": "I support the Supreme Court's Judeo-Christian Value Reinforcement Doctrine",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page7",
            "elements": [
                {
                    "type": "rating",
                    "name": "q7",
                    "title": "I think the withdrawl of federal research money from liberal universities was a mistake",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page8",
            "elements": [
                {
                    "type": "rating",
                    "name": "q8",
                    "title": "I miss Social Security",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page9",
            "elements": [
                {
                    "type": "rating",
                    "name": "q9",
                    "title": "The US should rejoin NATO & the UN",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page10",
            "elements": [
                {
                    "type": "rating",
                    "name": "q10",
                    "title": "Environmental alarmists sometimes have a point",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page11",
            "elements": [
                {
                    "type": "rating",
                    "name": "q11",
                    "title": "Gender Criminals should be allowed to leave cities and not have to pay the deviant tax",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page12",
            "elements": [
                {
                    "type": "rating",
                    "name": "q12",
                    "title": "Women who have been jailed for having abortions should be released",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page13",
            "elements": [
                {
                    "type": "rating",
                    "name": "q13",
                    "title": "Deportation of immigrants should cease",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page14",
            "elements": [
                {
                    "type": "rating",
                    "name": "q14",
                    "title": "Nonprofit corporations should be allowed again",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },

        {
            "name": "page15",
            "elements": [
                {
                    "type": "rating",
                    "name": "q15",
                    "title": "Maybe they should bring back Medicare",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },
        {
            "name": "page16",
            "elements": [
                {
                    "type": "rating",
                    "name": "q16",
                    "title": "Things have gotten better since Federal Regulatory Agencies were disbanded",
                    "description": voteCaption,
                    "autoGenerate": false,
                    "rateCount": 5,
                    "rateValues": [1, 2, 3, 4, 5],
                    "isRequired": true
                }
            ]
        },
        // {
        //     "name": "page17",
        //     "elements": [
        //         {
        //             "type": "rating",
        //             "name": "interestQ",
        //             "title": "Thank you for completing the questionare",
        //             "description": voteCaption,
        //             "autoGenerate": false,
        //             "rateCount": 5,
        //             "rateValues": [1, 2, 3, 4, 5],
        //             "isRequired": true
        //         }
        //     ]
        // },
        {
            "name": "page17",
            "elements": [
                {
                    "type": "checkbox",
                    "name": "question2",
                    "title": "Please check as many of these topics as you'd like for our candidate to address.",
                    "choices": [
                        {
                            "value": "Item 2",
                            "text": "Immigration"
                        },
                        {
                            "value": "Item 1",
                            "text": "International Relations"
                        },
                        {
                            "value": "Item 3",
                            "text": "Abortion Rights"
                        },
                        {
                            "value": "Item 4",
                            "text": "The Environment"
                        }
                    ]
                }
            ]
        },
        {
            "name": "finalPage",
            "elements": [
                {
                    "type": "html",
                    "html": "<span class='htmlTitleText'>Thank you for your answers!</span>",
                },
                {
                    "type": "html",
                    "html": "<div class='htmlFinalText'>We are processing them now. Press 'Complete' to chat with other audience members who answered like you.</div>",
                }
            ]
        }

    ]
}

export default introQuestions