import { useEffect, useContext, useRef, useState } from 'react'
// import { getDatabase, ref, query, get, push, set, onValue, orderByChild, limitToFirst, limitToLast, onChildAdded } from "firebase/database";
import { Timestamp, getFirestore, doc, collection, query, orderBy, limitToLast, addDoc, deleteDoc } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { getAuth } from "firebase/auth";

import '../css/SocialFeed.css'
import { Feed } from 'semantic-ui-react';

import LinkPreview from '@ashwamegh/react-link-preview';
import '@ashwamegh/react-link-preview/dist/index.css'

import FakeSocialButtonBar from '../components/FakeSocialButtonBar';
import { logDOM } from '@testing-library/react';


const moeVideos = ['output_time_0.mp4', 'output_time_1.mp4', 'output_time_2.mp4', 'output_time_3.mp4', 'output_time_4.mp4']

function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    let urls = text.match(urlRegex)
    if (urls === null) {
        return text
    } else {
        return <div> {text.replace(urlRegex, '')} {urls.map(url => '')} </div>
    }
}

export default function SocialFeed(props) {

    const dummy = useRef();
    const db = getFirestore();
    const auth = getAuth();

    const [formValue, setFormValue] = useState('');

    const postsRef = collection(db, 'posts');

    const postsQuery = query(postsRef, orderBy('createdAt'), limitToLast(25))

    const [posts] = useCollectionData(postsQuery, { idField: 'id' });

    // const dummy = useRef();

    const globalContext = useContext(window.GlobalContext)
    let { user, userData, publicData } = globalContext


    // const logoSVG = <div className="logo-container rebrand">
    //     <svg className="logo" viewBox="0 0 24 24" aria-hidden="true" class="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-lrsllp r-1nao33i r-16y2uox r-8kz0gk">
    //         <g> <path fill="white" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z">
    //         </path> </g></svg>
    //     <span className="logo-text">.gov</span>
    // </div>

    const logoSVG = publicData.rebrand
        ? <div className="logo-container rebrand">
            <svg className="logo" viewBox="0 0 24 24" aria-hidden="true" class="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-lrsllp r-1nao33i r-16y2uox r-8kz0gk">
                <g> <path fill="white" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z">
                </path> </g></svg>
            <span className="logo-text">.gov</span>
        </div>
        : <div className="logo-container preRebrand">
            <span className='logo'>𝕋</span>
        </div>

    const isRebranded = publicData.rebrand ? 'rebrand' : 'preRebrand'

    useEffect(() => {
        {
            dummy?.current.scrollIntoView({});
        }
    }, [posts])


    // const scrollToBottom = () => {
    //     window.scrollTo({
    //         top: document.documentElement.scrollHeight,
    //         behavior: 'smooth'
    //     });
    // };
    // useEffect(() => {
    //     scrollToBottom();
    // }, [posts]);

    // https://youtu.be/7Kf085NKvHw?si=9fIsGQ9n5n5lArVt
    return <>
        <div className={"top-bar " + isRebranded}>
            {logoSVG}
        </div>
        <div className="socialFeedContainer">

            {posts && Object.keys(posts).map(key => <FeedPost db={db} publicData={publicData} userData={userData} postObject={posts[key]} />)}

            <span ref={dummy}></span>

        </div >
    </>

}

function FeedPost(props) {
    const { username, displayName, avatar, text, media, id, db } = props.postObject;
    const { moeDancing } = props.publicData;

    const deletePost = async (postId) => {
        await deleteDoc(doc(props.db, 'posts', id))
    }

    if (props.postObject?.displayTo
        && !props.postObject.displayTo.includes(props.userData.archetype.name)) return;

    // very strange bug where useCollectionData returns all loaded messages when going from `messages` to `posts`
    // bandaid fix: skip if media===undefined
    // posts without images should have media = []
    if (media === undefined) return



    const randomMoeVideo = moeVideos[Math.floor(Math.random() * moeVideos.length)]


    // console.log(moeDancing);

    return (
        <>
            <div className={`post ${props.publicData.rebrand ? 'rebrand' : 'preRebrand'}`}>
                <div className='post_Body'>
                    <div className='post_Header'>
                        <div className='post_TopBar'>
                            <div className='post_Avatar'>
                                <img src={avatar}></img>
                            </div>
                            <div className='post_HeaderText'>
                                <h3>
                                    <span className='post_DisplayName'>{displayName}</span>
                                    <br />
                                    <span className='post_Username'>
                                        @{username}
                                        <svg viewBox="0 0 22 22" aria-label="Verified account" role="img" class="verifiedBadge"><g><path d="M20.396 11c-.018-.646-.215-1.275-.57-1.816-.354-.54-.852-.972-1.438-1.246.223-.607.27-1.264.14-1.897-.131-.634-.437-1.218-.882-1.687-.47-.445-1.053-.75-1.687-.882-.633-.13-1.29-.083-1.897.14-.273-.587-.704-1.086-1.245-1.44S11.647 1.62 11 1.604c-.646.017-1.273.213-1.813.568s-.969.854-1.24 1.44c-.608-.223-1.267-.272-1.902-.14-.635.13-1.22.436-1.69.882-.445.47-.749 1.055-.878 1.688-.13.633-.08 1.29.144 1.896-.587.274-1.087.705-1.443 1.245-.356.54-.555 1.17-.574 1.817.02.647.218 1.276.574 1.817.356.54.856.972 1.443 1.245-.224.606-.274 1.263-.144 1.896.13.634.433 1.218.877 1.688.47.443 1.054.747 1.687.878.633.132 1.29.084 1.897-.136.274.586.705 1.084 1.246 1.439.54.354 1.17.551 1.816.569.647-.016 1.276-.213 1.817-.567s.972-.854 1.245-1.44c.604.239 1.266.296 1.903.164.636-.132 1.22-.447 1.68-.907.46-.46.776-1.044.908-1.681s.075-1.299-.165-1.903c.586-.274 1.084-.705 1.439-1.246.354-.54.551-1.17.569-1.816zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z"></path></g></svg>
                                    </span>
                                    {props.userData.role === 'admin'
                                        ? <span className='deletePost' onClick={() => deletePost(id)}>x</span>
                                        : <></>}
                                </h3>
                            </div>
                        </div>

                        <div className='post_HeaderContent'>
                            <p> {text === undefined ? <></> : urlify(text)} </p>
                        </div>

                        {
                            (media.length === 0 || media[0] === "") // stupid fix, should really be sovled in the tweet grabber
                                ? <>
                                    <>
                                    </>
                                </>
                                :
                                (moeDancing
                                    ? <>
                                        <video className={`post_Video ${id}`}
                                            webkit-playsinline="true" muted loop autoPlay playsInline>
                                            {/* <source src="output.webm" type="video/webm" /> */}
                                            <source type="video/mp4" src={randomMoeVideo} />
                                        </video>
                                    </>
                                    : <>
                                        <img src={media[0]}></img>
                                    </>
                                )
                        }

                        {/* {props.postObject?.video ?
                            <video className='post_Video' muted loop>
                                <source src="dance_test.mp4" type="video/mp4" />
                            </video> : <></>
                        } */}


                        {/* <video className='post_Video' muted autoPlay> */}
                        {/* <source src="https://video.twimg.com/ext_tw_video/1816240485800747008/pu/vid/avc1/750x618/fogPhvTy1E3b__uj.mp4?tag=12" type="video/mp4" /> */}
                        {/* <source src="dance_test.mp4" type="video/mp4" /> */}
                        {/* </video> */}



                        {/* <FakeSocialButtonBar /> */}

                    </div>


                </div>
            </div>

        </>

    )
}