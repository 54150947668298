import { doc, updateDoc, getFirestore } from 'firebase/firestore'

import { useState, useContext, useRef } from 'react';


export default function AdminRebrandPanel(props) {

    const fs = getFirestore()

    const globalContext = useContext(window.GlobalContext)
    let { publicData } = globalContext

    const [rebrandEnabled, setRebrandEnabled] = useState(publicData.rebrand);
    const [forceFeed, setForceFeed] = useState(publicData.forceFeed);
    const [moeDancing, setMoeDancing] = useState(publicData.moeDancing);
    const [xGOVLanding, setXGOVLANDING] = useState(publicData.xGOVLandingEnabled);
    const [confettiEnabled, setConfettiEnabled] = useState(publicData.confetti);
    const [americanFlagLandingEnabled, setAmericanFlagLandingEnabled] = useState(publicData.americanFlagLandingEnabled);

    const powerPlayLandingCheckbox = useRef(null)
    const investmentPageCheckbox = useRef(null)
    const stockTickerOnLandingCheckbox = useRef(null)
    const rebrandCheckbox = useRef(null)
    const americanFlagLandingCheckbox = useRef(null)
    const confettiCheckbox = useRef(null)
    const interestPageCheckbox = useRef(null)
    const forceFeedCheckbox = useRef(null)
    const moeDancingCheckbox = useRef(null)
    const xGOVLandingCheckbox = useRef(null)
    const finalInvestmentPageCheckbox = useRef(null)

    const changeRebrand = async (e) => {
        const cueRef = doc(fs, 'public', 'globalState');
        setRebrandEnabled(e.target.checked)
        await updateDoc(cueRef, {
            'rebrand': e.target.checked
        })
    }

    // console.log(publicData)

    const changeForceFeed = async (e) => {
        const cueRef = doc(fs, 'public', 'globalState');
        setForceFeed(e.target.checked)
        await updateDoc(cueRef, {
            'forceFeed': e.target.checked
        })
    }

    const resendPublicData = async (e) => {
        const cueRef = doc(fs, 'public', 'globalState');
        await updateDoc(cueRef, {
            'junkValueDoNotUse': Math.random()
        })
    }


    return (<>


        <br />

        <div className={`boxLabelPair ${props.publicData.powerPlayLandingEnabled ? 'checked' : 'unchecked'}`}
            onClick={() => {
                powerPlayLandingCheckbox.current.click()
            }}
        >
            <span>"toggle power play landing"</span>
            <input type="checkbox" ref={powerPlayLandingCheckbox}
                checked={props.publicData.powerPlayLandingEnabled}
                value={props.publicData.powerPlayLandingEnabled}
                onChange={async (e) => {
                    const cueRef = doc(fs, 'public', 'globalState');
                    // setGiantSliderEnabled(e.target.checked)
                    // no fucking clue why it needs to be flipped 
                    // ??????
                    await updateDoc(cueRef, {
                        'powerPlayLandingEnabled': e.target.checked
                    })
                }} />
        </div>

        <div className={`boxLabelPair ${props.publicData.investmentPageEnabled ? 'checked' : 'unchecked'}`}
            onClick={() => {
                investmentPageCheckbox.current.click()
            }}
        >
            <span>"toggle investment page" <br />(should be )</span>
            <input type="checkbox" ref={investmentPageCheckbox}
                checked={props.publicData.investmentPageEnabled}
                value={props.publicData.investmentPageEnabled}
                // disabled={![2, 5, 8, 11].includes(props.publicData.questionNumber)}
                onChange={async (e) => {
                    const cueRef = doc(fs, 'public', 'globalState');
                    // setGiantSliderEnabled(e.target.checked)
                    // no fucking clue why it needs to be flipped 
                    // ??????
                    await updateDoc(cueRef, {
                        'investmentPageEnabled': e.target.checked
                    })
                }} />
        </div>

        <br />
        <br />



        <div className={`boxLabelPair ${props.publicData.finalInvestmentPageEnabled ? 'checked' : 'unchecked'}`}
            onClick={() => {
                finalInvestmentPageCheckbox.current.click()
            }}
        >
            <span>"toggle final investment page" <br />(should be )</span>
            <input type="checkbox" ref={finalInvestmentPageCheckbox}
                checked={props.publicData.finalInvestmentPageEnabled}
                value={props.publicData.finalInvestmentPageEnabled}
                // disabled={![2, 5, 8, 11].includes(props.publicData.questionNumber)}
                onChange={async (e) => {
                    const cueRef = doc(fs, 'public', 'globalState');
                    // setGiantSliderEnabled(e.target.checked)
                    // no fucking clue why it needs to be flipped 
                    // ??????
                    await updateDoc(cueRef, {
                        'finalInvestmentPageEnabled': e.target.checked
                    })
                }} />
        </div>

        <br />



        <div className={`boxLabelPair ${props.publicData.stockTickerOnLandingEnabled ? 'checked' : 'unchecked'}`}
            onClick={() => {
                stockTickerOnLandingCheckbox.current.click()
            }}
        >
            <span>"stock ticker on hold enabled"<br /></span>
            <input type="checkbox" ref={stockTickerOnLandingCheckbox}
                checked={props.publicData.stockTickerOnLandingEnabled}
                value={props.publicData.stockTickerOnLandingEnabled}
                onChange={async (e) => {
                    const cueRef = doc(fs, 'public', 'globalState');
                    // setGiantSliderEnabled(e.target.checked)
                    // no fucking clue why it needs to be flipped 
                    // ??????
                    await updateDoc(cueRef, {
                        'stockTickerOnLandingEnabled': e.target.checked
                    })
                }} />
        </div>


        <div className={`boxLabelPair ${rebrandEnabled ? 'checked' : 'unchecked'}`}
            onClick={() => {
                rebrandCheckbox.current.click()
            }}
        >
            <span>toggle rebrand</span>
            <input type="checkbox" ref={rebrandCheckbox}
                checked={rebrandEnabled}
                value={rebrandEnabled}
                onChange={(e) => {
                    changeRebrand(e)
                }} />
        </div>

        <div className={`boxLabelPair ${americanFlagLandingEnabled ? 'checked' : 'unchecked'}`}
            onClick={() => {
                americanFlagLandingCheckbox.current.click()
            }}
        >
            <span>toggle americanFlag</span>
            <input type="checkbox" ref={americanFlagLandingCheckbox}
                checked={americanFlagLandingEnabled}
                value={americanFlagLandingEnabled}
                onChange={(e) => {
                    setAmericanFlagLandingEnabled(e.target.checked)
                    updateDoc(doc(fs, 'public', 'globalState'), { americanFlagLandingEnabled: e.target.checked })
                }} />
        </div>

        <div className={`boxLabelPair ${confettiEnabled ? 'checked' : 'unchecked'}`}
            onClick={() => {
                confettiCheckbox.current.click()
            }}
        >
            <span>toggle confetti</span>
            <input type="checkbox" ref={confettiCheckbox}
                checked={confettiEnabled}
                value={confettiEnabled}
                onChange={(e) => {
                    setConfettiEnabled(e.target.checked)
                    updateDoc(doc(fs, 'public', 'globalState'), { confetti: e.target.checked })
                }} />
        </div>


        <div className={`boxLabelPair ${props.publicData.interestPageEnabled ? 'checked' : 'unchecked'}`}
            onClick={() => {
                interestPageCheckbox.current.click()
            }}
        >
            <span>toggle fire page <br /></span>
            <input type="checkbox" ref={interestPageCheckbox}
                checked={props.publicData.interestPageEnabled}
                value={props.publicData.interestPageEnabled}
                // disabled={![2, 5, 8, 11].includes(props.publicData.questionNumber)}
                onChange={async (e) => {
                    const cueRef = doc(fs, 'public', 'globalState');
                    await updateDoc(cueRef, {
                        'interestPageEnabled': e.target.checked
                    })
                }} />
        </div>


        <div className={`boxLabelPair ${forceFeed ? 'checked' : 'unchecked'}`}
            onClick={() => {
                forceFeedCheckbox.current.click()
            }}
        >
            <span>force social feed</span>
            <input type="checkbox" ref={forceFeedCheckbox}
                checked={forceFeed}
                value={forceFeed}
                onChange={(e) => {
                    changeForceFeed(e)
                }} />
        </div>

        <div className={`boxLabelPair ${moeDancing ? 'checked' : 'unchecked'}`}
            onClick={() => {
                moeDancingCheckbox.current.click()
            }}
        >
            <span>moeDancing</span>
            <input type="checkbox" ref={moeDancingCheckbox}
                checked={moeDancing}
                value={moeDancing}
                onChange={async (e) => {
                    const cueRef = doc(fs, 'public', 'globalState');
                    setMoeDancing(e.target.checked)
                    await updateDoc(cueRef, {
                        'moeDancing': e.target.checked
                    })
                }} />
        </div>

        <div className={`boxLabelPair ${xGOVLanding ? 'checked' : 'unchecked'}`}
            onClick={() => {
                xGOVLandingCheckbox.current.click()
            }}
        >
            <span>xGOVLanding</span>
            <input type="checkbox" ref={xGOVLandingCheckbox}
                checked={xGOVLanding}
                value={xGOVLanding}
                onChange={async (e) => {
                    const cueRef = doc(fs, 'public', 'globalState');
                    setXGOVLANDING(e.target.checked)
                    await updateDoc(cueRef, {
                        'xGOVLandingEnabled': e.target.checked
                    })
                }} />
        </div>

        <div className={`boxLabelPair`}>
            <span>resend send publicData</span>
            <input type="button"
                onClick={(e) => { resendPublicData() }} />
        </div>

    </>)
}