import logo from './logo.svg';

import { useState, useRef, createContext, useContext, useEffect, Suspense, useMemo } from 'react';

import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, deleteUser } from "firebase/auth";

import CueEnv from './containers/CueEnv';

import TstContainers from './containers/TstComponents';
import Quiz from './containers/Quiz';

import cueListData from './data/cue-list-data.json';
import { Button } from 'semantic-ui-react';
import SurveyComponent from './containers/SurveyComponent';

import Login from './components/Login';
import Loading from './components/Loading';
import DelayedRender from './components/DelayedRender';
import AdminPanel from './containers/AdminPanel';

import AppView from './containers/AppView'
import { getFirestore, doc, onSnapshot, setDoc, updateDoc, getDoc } from 'firebase/firestore';

import "./css/App.css"

import GiantSlider from './components/GiantSlider.js';

import { ReactNotifications, Store } from 'react-notifications-component';
import './css/react-notifications-theme.css'

// import useHydra from './components/useHydra.js'
import HydraCanvas from './components/HydraCanvas.js'
import { TbBackground } from 'react-icons/tb';
import StockTicker from './components/StockTicker.js';
import { InvestmentPage, PowerPlayLanding, InterestPage, XGOVLanding, AmericanFlagLanding } from './components/PowerPlayPages.js';
import VideoTeamView from './components/VideoTeamView.js';

// import useWindowSize from './components/useWindowSize.js'
import Confetti from 'react-confetti'

// TODO: this is just for testing, should store admin stuff in the database (not client-side obv.)
// definitely remove this before actually pushing to production, b/c anyone could just change this to their own uid
// and take control of admin stuff
const provider = new GoogleAuthProvider();

const cuedContainers = [AppView, HydraCanvas, Quiz, ...TstContainers]


const GlobalContext = createContext({})

let wakelock = null


function showNotification(notificationTitle, notification) {
  // navigator.serviceWorker.register("/src/sw.js");
  Notification.requestPermission().then((result) => {
    if (result === "granted") {
      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification(notificationTitle, notification);
      });
    }
  });
}


function App() {

  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);

  const publicDataDefaults = {
    cue: 0,
    oneMessage: '',
    oneMessageEnabled: false,
    giantSliderEnabled: false,
    rebrand: false,
    politcalChat: false,
    vibrating: false,
    flashPoliticalGroup: false,
    showQuizResults: false,
    questionNumber: 0,
    timeLeft: 0,
    showTopicsOfInterest: false,
    showBuildersTransitionScreen: false,
    powerPlayLandingEnabled: false,
    investmentPageEnabled: false,
    InterestPageEnabled: false,
    forceFeed: false,
    moeDancing: false,
    stockObj: {},
    stockIndex: 0,
    stockTickerOnLandingEnabled: true,
    xGOVLandingEnabled: false,
    fireLandingEnabled: false,
    americanFlagLandingEnabled: false
  }

  const [publicData, setPublicData] = useState(publicDataDefaults);

  const [currentCueNumber, setCurrentCueNumber] = useState(null);

  const [showCueList, setShowCueList] = useState(false);
  const [showVideoTeamView, setShowVideoTeamView] = useState(false);

  const [page, setPage] = useState('login');

  const [localQuizStats, setLocalQuizStats] = useState("TEST")

  const [surveyComplete, setSurveyComplete] = useState(false);
  const [userSetup, setUserSetup] = useState(false);

  // const [showQuizResults, setShowQuizResults] = useState(false);

  const [errorText, setErrorText] = useState('')

  let initalizedNotifications = false

  // HydraCanvas.js stuff
  // const [canvasRef, hydraLoaded] = useHydra()
  // const canvasRef = useRef(null)

  // Quiz.js stuff
  const [questionNumber, setQuestionNumber] = useState(0)

  // AppView stuff
  const [chat, setChat] = useState(true)

  // GiantSlider stuff
  const [giantSliderEnabled, setGiantSliderEnabled] = useState(false);
  const [isAggregatingSliderValues, setIsAggregatingSliderValues] = useState(false);
  const [isRunningStocks, setIsRunningStocks] = useState(false);


  // Vibrating stuff
  const [vibratingIntervalVar, setVibratingIntervalVar] = useState(null)

  const globalScreenEffectRef = useRef(null)

  const fs = getFirestore();


  const requestWakelock = async () => {
    try {
      if (wakelock === null) {
        wakelock = await navigator.wakeLock.request("screen");
        console.log("wakeLock active");
      }
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  }

  // audio stuff

  // const audioContext = window.AudioContext || window.webkitAudioContext
  // const audioCtx = new AudioContext();
  // window.audioCtx = audioCtx

  // const playAudio = (sound) => {
  //   // check if context is in suspended state (autoplay policy)
  //   try {

  //     switch (sound) {
  //       // trash fkn code
  //       case 'hit':
  //         if (audioCtx.state === 'suspended') {
  //           audioCtx.resume();
  //         }
  //         if (audioElement2.paused === true) {
  //           audioElement2.currentTime = 0
  //           audioElement2.play();
  //           // if track is playing pause it
  //         } else if (audioElement2.paused === false) {
  //           audioElement2.pause();
  //         }
  //         break;

  //       case 'music':
  //         if (audioCtx.state === 'suspended') {
  //           audioCtx.resume();
  //         }
  //         if (audioElement.paused === true) {
  //           audioElement.currentTime = 0
  //           audioElement.play();
  //           // if track is playing pause it
  //         } else if (audioElement.paused === false) {
  //           audioElement.pause();
  //         }

  //         setTimeout(() => {
  //           audioElement.pause();
  //         }, publicData.MAXTIME);
  //         break;

  //       default:
  //         console.log("options: 'hit', 'music'");
  //         break;
  //     }

  //   } catch (e) {
  //     console.error(e)

  //   }

  // }

  // window.playAudio = playAudio
  window.showLocalNotification = showLocalNotification

  // create an async function to request a wake lock
  requestWakelock()

  const genVideoTag = (url) => {
    let huh = document.createElement('div')
    huh.style.position = 'fixed'
    huh.style.left = `${window.innerWidth * Math.random()}px`
    huh.style.top = `${window.innerHeight * Math.random()}px`
    huh.style.zIndex = 9001
    // console.log(huh.style);
    huh.innerHTML = `
          <video class='smallVideo' autoplay='autoplay' >
            <source type="video/mp4" src=${url} />
          </video>
    `
    // return createElement('div', {}, "AHHHHH")
    return huh
  }

  function showLocalNotification(notificationTitle, notification) {

    let userData = window.userData
    // console.log(userDatawin, userData === null);
    if (userData === null) return

    if (window.userData === undefined || !notification.displayTo.includes(window.userData.archetype.name))
      return

    if (notificationTitle === "__vibrating__") {
      if (navigator.vibrate) navigator?.vibrate(1000)
    } else if (notificationTitle === "__pushVideo__") {
      let huh = genVideoTag(notification.body)
      globalScreenEffectRef.current?.appendChild(huh)
      // setTimeout
    } else {

      const avatarImage = {
        "justice-warrior": "AOC_200.png",
        "social-guardian": "kermit_200.png", // Mr. Rogers
        "stalwart-nationalist": "QAnon_Shaman_200.png",
        "freedom-steward": "musk_200.png",
        "astute-logician": "Spock_200.png", // get new image for spock, his head cuts off
        // "utopian-virtuoso": "kermit_.png"
      }[notification.displayTo[0]]
      // console.log(avatarImage);


      Store.addNotification({
        title: notificationTitle,
        content: <div style={{ position: 'relative' }}>
          <img style={{ width: '300px', height: '300px' }} src={avatarImage} />
          <span style={{ color: 'white', backgroundColor: '#2c4157', padding: '10px', borderRadius: '10px', fontWeight: 600 }}>{notification.body}</span>
        </div>,
        type: "default",
        insert: "center",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000
          // onScreen: true
        }
      });

    }
  }

  const signInWithGoogle = () => {
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.error(error);

      });

    return
  }

  const signInWithEmail = (email, password) => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up 
        const user = userCredential.user;
        setUser(user)
        // ...
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/invalid-email': // email malformed
            // var notification = new Notification('error', { body: 'invalid email address' });
            setErrorText("Error: invalid email address")
            break;

          case 'auth/weak-password': // email malformed
            // var notification = new Notification('error', { body: 'invalid email address' });
            setErrorText("Error: Password should be at least 6 characters")
            break;

          case 'auth/email-already-in-use': // email already in use

            signInWithEmailAndPassword(auth, email, password)
              .then((userCredential) => {
                // Signed up 
                const user = userCredential.user;
                setUser(user)
                getDoc(doc(fs, "users", user.uid)).then(doc => setUserData(doc.data()))

              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setErrorText("Error: Incorrect password")
                // ..
              });

            break;

          default:
            break;
        }
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(error)

        // ..
      });
  }


  const signOutUser = () => { // a callback function for logging out the current user
    // setState({ errorMessage: null }); // clear any old errors
    const auth = getAuth();
    signOut(auth)
      .catch((error) => {
        console.error(error.message);
        // setState({ errorMessage: error.message });
      });
    setUser(null);
    setUserData(null);
  }


  const changeCue = async (q) => {
    if (userData?.role === 'admin') {

      const cueRef = doc(fs, 'public', 'globalState');
      await updateDoc(cueRef, {
        'cue': q
      })

      // console.log(`cue set to ${q}: ${cueToCueNumber(q)}`);
    }
  }

  const cueToCueNumber = (c) => {
    return cueListData['cues'][c]['no'];
  };

  const getUserData = () => {
    return userData
  }

  let flashScreenAnimation
  useEffect(() => {
    const auth = getAuth();
    const authUnregFunc = onAuthStateChanged(auth, (user) => {

      if (user) {
        setUser(user)

        const unsubGlobalStateInit = onSnapshot(doc(fs, "public", "globalState"), (document) => {

          let d = document.data()
          const cueRef = doc(fs, 'public', 'globalState');
          if (d === undefined) {
            // set(ref(db, 'public/cue'), 0);

            setDoc(cueRef, publicDataDefaults)

            // set(ref(db, 'public/sound'), 0);
          } else {
            setPublicData(d);
            // console.log(d);
            setCurrentCueNumber(d['cue']);

            if (globalScreenEffectRef.current !== null) {
              // console.log(flashScreenRef.current);
              if (d.flashPoliticalGroup) {

                flashScreenAnimation = globalScreenEffectRef.current.animate([
                  // key frames
                  { backgroundColor: '#FF0000' },
                  { backgroundColor: '#FFFFFF' },
                  { backgroundColor: '#0000FF' },
                  { backgroundColor: '#FF0000' },
                ], {
                  // sync options
                  duration: 800,
                  iterations: Infinity
                });
                // flashScreenRef.current.pause()
                // flashScreenRef.current.play()
              } else {

                // flashScreenRef.current.style.backgroundColor = 'none !important'
                flashScreenAnimation?.cancel()

              }
            }

          }

        })

        const unsubUserData = onSnapshot(doc(fs, "users", user.uid), (doc) => {
          if (doc.data()?.introAnswers !== undefined) {
            // console.log(doc.metadata.fromCache);
            setUserData(doc.data());
            setSurveyComplete(true)
          } else {
            setSurveyComplete(false)
          }
        });


        const unsubNotifications = onSnapshot(doc(fs, "notifications", "public"), (doc) => {

          if (!initalizedNotifications) {
            initalizedNotifications = true
            return
          }


          if (doc.data() !== undefined) {

            // dumb fix, I don't like it. It triggers this funct twice and the second time userData is defined

            // console.log('in here: notifications/public onSnapshot');
            let { title, icon, body } = doc.data()
            // console.log({ title, icon, body, displayTo });
            // let userDataLocal = window.userData
            // console.log(userDataLocal);

            // if (userDataLocal === null) return;

            // let icon = doc.data().icon;
            // let body = doc.data().body;
            // let displayTo = doc.data().displayTo;

            // if (displayTo && !displayTo.includes(userDataLocal.archetype.name)) return;

            // console.log(window.userData);
            showLocalNotification(title, doc.data());

          } else {
            console.log('newNotification undefined??');
          }

        });


        window.onbeforeunload = () => {
          unsubGlobalStateInit()
          // unsubSound()
          unsubNotifications()
          unsubUserData()
        }

      } else { // firebaseUser undefined: is not logged in
        console.log('currently logged out');
      }

    });
    // Specify how to clean up after this effect:
    return () => {
      authUnregFunc();
      // firebase.database().ref('users').off('value');
    };

  }, []);

  // if ("serviceWorker" in navigator) {
  //   // declaring scope manually
  //   navigator.serviceWorker.register("./sw.js", { scope: "./" }).then(
  //     (registration) => {
  //       // console.log("Service worker registration succeeded:", registration);
  //     },
  //     (error) => {
  //       console.error(`Service worker registration failed: ${error}`);
  //     },
  //   );
  // } else {
  //   console.error("Service workers are not supported.");
  // }


  // this probably isn't great, but it works

  // window.showNotification = showNotification
  // window.showLocalNotification = showLocalNotification
  window.GlobalContext = GlobalContext

  useEffect(() => {
    console.log(userData);
    // this is fucking stupid
    window.userData = userData
  }, [userData])

  let globalContext = {
    questionNumber, setQuestionNumber, // Quiz.js
    chat, setChat, // AppView.js
    user, signOutUser,
    userData, setUserData,
    publicData, setPublicData,
    chat, setChat,
    isAggregatingSliderValues, setIsAggregatingSliderValues,
    isRunningStocks, setIsRunningStocks,
    giantSliderEnabled, setGiantSliderEnabled,
    vibratingIntervalVar, setVibratingIntervalVar,
    localQuizStats, setLocalQuizStats,
    // playAudio,
    publicDataDefaults
    // showQuizResults, setShowQuizResults
    // user={user} signOutUser={signOutUser} userData={userData} publicData={publicData} cuedContainers={cuedContainers} chat={chat} setChat={setChat}
    // canvasRef,
    // hydraLoaded, // Hydra.js
  }


  // dumb fucking fix, why did I ever use CueEnv.js and call jsx components as normal functions :'(, waste of my time
  const cueEnv = (index) => {
    switch (index) {
      case 0:
        return (
          <AppView user={user} signOutUser={signOutUser} userData={userData} publicData={publicData} cuedContainers={cuedContainers} chat={chat} setChat={setChat} />
        )
        break;

      case 1:
        return (
          <HydraCanvas publicData={publicData} userData={userData} />
        )
        break;

      case 2:
        return (
          <Quiz user={user} />
        )
        break;

      case 3:
        return (
          <StockTicker publicData={publicData} />
          // <iframe src="https://player.twitch.tv/?channel=loltyler1&parent=localhost" />
          // <img className='liveFeed' src="http://statenisland.dnsalias.net/mjpg/video.mjpg" ></img>
          // <img className='liveFeed' src="http://statenisland.dnsalias.net/mjpg/video.mjpg" ></img>
          // <video className='liveFeed' autoplay='autoplay' >
          //   <source type="video/mp4" src="https://video.twimg.com/ext_tw_video/1816240485800747008/pu/vid/avc1/750x618/fogPhvTy1E3b__uj.mp4?tag=12" />
          // </video>
          // <></>
        )
        break;

      default:
        break;
    }

  }

  // var myScreenOrientation = window.screen.orientation
  // myScreenOrientation?.lock('portrait')

  // window.onload = maxWindow;
  // function maxWindow() {
  //   // window.moveTo(0, 0);
  //   if (document.all) {
  //     top.window.resizeTo(screen.availWidth, screen.availHeight);
  //   }
  //   else if (document.layers || document.getElementById) {
  //     if (top.window.outerHeight < screen.availHeight || top.window.outerWidth < screen.availWidth) {
  //       top.window.outerHeight = screen.availHeight;
  //       top.window.outerWidth = screen.availWidth;
  //     }
  //   }
  // }

  let panelToShow;
  if (showCueList) {
    panelToShow = <AdminPanel publicData={publicData} changeCue={changeCue} />
  } else if (showVideoTeamView) {
    panelToShow = <VideoTeamView userData={userData} publicData={publicData} />
  }




  return (
    <div className={`App `}>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      <meta name="robots" content="noindex"></meta>
      <GlobalContext.Provider value={globalContext}>
        {!(showCueList || showVideoTeamView) && <ReactNotifications />}
        {publicData.confetti
          &&
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            style={{ zIndex: 10000 }}
          />
        }
        <DelayedRender delay={700}>
          <Suspense >
            {userData?.role === 'admin' ?
              <>
                <Button className={`cueListButton ${showCueList ? 'viewEnabled' : 'viewDisabled'}`} onClick={() => {
                  if (showVideoTeamView) {
                    setShowVideoTeamView(false)
                  }
                  setShowCueList(!showCueList)
                }}> AdminPanel </Button>
                <Button className={`videoTeamViewButton ${showVideoTeamView ? 'viewEnabled' : 'viewDisabled'}`} onClick={() => {
                  if (showCueList) {
                    setShowCueList(false)
                  }
                  setShowVideoTeamView(!showVideoTeamView)
                }}> VideoTeamView </Button>
              </>
              : null
            }
            {user === null ?
              <DelayedRender delay={400}>
                <header className="App-header">
                  {errorText !== "" && <div className='errorText'>{errorText}</div>}
                  <Login userData={userData} signInWithEmail={signInWithEmail} signInWithGoogle={signInWithGoogle} />
                </header>
              </DelayedRender>
              : <Suspense >
                {(!surveyComplete
                  ? <div className='surveyComponentContainer'>
                    <DelayedRender delay={600}>
                      <SurveyComponent user={user} setUserData={setUserData} onComplete={() => setSurveyComplete(true)} />
                    </DelayedRender>
                  </div>
                  : (userData?.role === 'admin' && (showCueList || showVideoTeamView)
                    ? panelToShow
                    : <>
                      {<GiantSlider user={user} userData={userData} publicData={publicData} className={publicData.giantSliderEnabled ? 'enabled' : 'disabled'} />}
                      {<PowerPlayLanding publicData={publicData} className={publicData.powerPlayLandingEnabled ? 'enabled' : 'disabled'} />}
                      {<InvestmentPage className={publicData.investmentPageEnabled ? 'enabled' : 'disabled'} user={user} userData={userData} publicData={publicData} />}
                      {<XGOVLanding className={publicData.xGOVLandingEnabled ? 'enabled' : 'disabled'} user={user} userData={userData} publicData={publicData} />}
                      {<InterestPage className={publicData.interestPageEnabled ? 'enabled' : 'disabled'} />}
                      {<AmericanFlagLanding className={publicData.americanFlagLandingEnabled ? 'enabled' : 'disabled'} />}
                      {<>
                        <div className='globalScreenEffectDiv' ref={globalScreenEffectRef}>
                          {/* {<HydraCanvas />} */}
                        </div>
                        {cueEnv(publicData.cue)}
                      </>}
                    </>
                  )
                )
                }
                {/* <Button onClick={playAudio} content="AHH" /> */}
              </Suspense>
            }
          </Suspense>
        </DelayedRender>
      </GlobalContext.Provider>
    </div >
  )
}


export default App;
