import CueList from "./CueList"
import { useContext } from "react"
import { AudioOneShots } from "./AudioOneShots"
import AdminNotificationPanel from "../components/AdminNotificationPanel"
import AdminPostPanel from "../components/AdminPostPanel"
import DragDropData from "../components/DragDropData"
import AdminChatPanel from "../components/AdminChatPanel"

import AdminRealtimeDataPanel from "../components/AdminRealtimeDataPanel"
import AdminRebrandPanel from "../components/AdminRebrandPanel"

import AdminQuizPanel from "../components/AdminQuizPanel"
import AdminScheduledFunctions from "../components/AdminScheduledFunctions"

import '../css/AdminPanel.css'
import AdminPremadePostPanel from "../components/AdminPremadePostPanel"
import AdminPremadeMessagePanel from "../components/AdminPremadeMessagesPanel"

import ChatRoom from '../containers/ChatRoom'

import AdminTestPanel from "../components/AdminTestPanel"

export default function AdminPanel(props) {

    const globalContext = useContext(window.GlobalContext)
    let { chat, setChat, user, userData, publicData, setPublicData, signOutUser } = globalContext

    return <>
        <div className="panels">

            <div>
                <CueList publicData={publicData} changeCue={props.changeCue} />
                <AdminQuizPanel publicData={publicData} />
                <br />
                <br />
                <br />
                <br />
                <AdminNotificationPanel />
            </div>
            <div>
                <AdminRealtimeDataPanel publicData={publicData} setPublicData={setPublicData} />
                <AdminRebrandPanel publicData={publicData} />
            </div>

            <ChatRoom adminPanel={true} />

            <div >
                <DragDropData />
                <AdminTestPanel publicData={publicData} />
            </div>

            {/* <ChatRoom /> */}
            {/* <AdminPostPanel /> */}
            {/* <AdminChatPanel publicData={publicData} /> */}

            <AdminPremadePostPanel publicData={publicData} />
            {/* <AdminPremadeMessagePanel publicData={publicData} /> */}
            {/* <AdminScheduledFunctions /> */}

        </div>
    </>
}