

const political_quiz_json = {
  "questions": [
    {
      "question": "I frequently re-post political articles or comment on them on social media.",
      "questioncode": "q1",
      "answers": [
        {
          "answer": "strong_disagree"
        },
        {
          "answer": "disagree",
          "change": "1",
          "assertiveness": "2"
        },
        {
          "answer": "neutral",
          "engagement": "2",
          "change": "3",
          "assertiveness": "6"
        },
        {
          "answer": "agree",
          "engagement": "8",
          "change": "5",
          "assertiveness": "8"
        },
        {
          "answer": "strong_agree",
          "engagement": "12",
          "change": "8",
          "assertiveness": "12"
        }
      ]
    },
    {
      "question": "I engage with a lot of political content every week.",
      "questioncode": "q2",
      "answers": [
        {
          "answer": "strong_disagree"
        },
        {

          "answer": "disagree",
          "decision": "1",
          "agreeableness": "1",
          "assertiveness": "2"
        },
        {
          "answer": "neutral",
          "engagement": "4",
          "decision": "2",
          "agreeableness": "2",
          "assertiveness": "4"
        },
        {
          "answer": "agree",
          "engagement": "9",
          "decision": "3",
          "agreeableness": "3",
          "assertiveness": "6"
        },
        {
          "answer": "strong_agree",
          "engagement": "12",
          "decision": "8",
          "agreeableness": "4",
          "assertiveness": "8"
        }
      ]

    },
    {
      "question": "I often feel people are talking about me in public spaces.",
      "questioncode": "q3",
      "answers": [
        {
          "answer": "strong_disagree"
        },
        {
          "answer": "disagree",
          "idealism": "1",
          "change": "2",
          "assertiveness": "1"
        },
        {

          "answer": "neutral",
          "engagement": "4",
          "idealism": "2",
          "change": "6",
          "assertiveness": "6"
        },
        {
          "answer": "agree",
          "engagement": "10",
          "idealism": "5",
          "change": "8",
          "assertiveness": "9"
        },
        {
          "answer": "strong_agree",
          "engagement": "14",
          "idealism": "8",
          "change": "12",
          "assertiveness": "12"
        }
      ]
    },

    {
      "question": "While Creationism is clearly compelling, it's just a theory.",
      "questioncode": "q4",
      "answers": [
        {
          "answer": "strong_disagree",
          "religious_fundamentals": "10"
        },
        {
          "answer": "disagree",
          "decision": "4",
          "agreeableness": "4",
          "education": "2",
          "religious_fundamentals": "6"
        },
        {
          "answer": "neutral",
          "engagement": "2",
          "decision": "2",
          "agreeableness": "6",
          "education": "6"
        },
        {
          "answer": "agree",
          "engagement": "4",
          "decision": "6",
          "agreeableness": "9",
          "education": "6"
        },
        {
          "answer": "strong_agree",
          "engagement": "8",
          "decision": "12",
          "agreeableness": "12"
        }
      ]

    },
    {
      "question": "Everything Fine.",
      "questioncode": "q5",
      "answers": [
        {
          "answer": "strong_disagree",
          "engagement": "10",
          "change": "12",
          "assertiveness": "12"
        },
        {
          "answer": "disagree",
          "engagement": "8",
          "change": "10",
          "assertiveness": "10"
        },
        {
          "answer": "neutral",
          "engagement": "2",
        },
        {
          "answer": "agree",
          "change": "4",
        },
        {
          "answer": "strong_agree",
          "change": "1",
          "assertiveness": "8"
        }
      ]

    },
    {
      "question": "I support the Supreme Court’s Judeo-Christian Value Reinforcement Doctrine.",
      "questioncode": "q6",

      "answers": [
        {
          "answer": "strong_disagree",
          "decision": "10",
          "agreeableness": "16",
          "social_healthcare": "8",
          "social_equality": "8",
          "education": "10",
          "law_regulation": "2"
        },
        {
          "answer": "disagree",
          "decision": "6",
          "agreeableness": "7",
          "social_healthcare": "6",
          "social_equality": "6",
          "education": "4",
          "religious_fundamentals": "1",
          "law_regulation": "1"
        },
        {
          "answer": "neutral",
          "decision": "4",
          "agreeableness": "4",
          "assertiveness": "3",
          "social_healthcare": "4",
          "social_equality": "4",
          "religious_fundamentals": "3"
        },
        {
          "answer": "agree",
          "change": "1",
          "agreeableness": "1",
          "assertiveness": "6",
          "social_healthcare": "1",
          "social_equality": "1",

          "religious_fundamentals": "8"
        },
        {
          "answer": "strong_agree",
          "change": "2",
          "assertiveness": "8",
          "religious_fundamentals": "10"
        }
      ]
    },
    {
      "question": "Withdrawing federal funding from liberal- rated universities was a mistake.",
      "questioncode": "q7",
      "answers": [
        {
          "answer": "strong_disagree",
          "education": "1",
          "religious_fundamentals": "8"
        },
        {
          "answer": "disagree",
          "decision": "2",
          "agreeableness": "1",
          "education": "2",
          "religious_fundamentals": "4",
          "law_regulation": "1"
        },
        {
          "answer": "neutral",
          "idealism": "1",
          "decision": "6",
          "agreeableness": "4",
          "environmental_health": "4",
          "social_equality": "2",
          "education": "2",
          "religious_fundamentals": "2",
          "law_regulation": "2"
        },
        {
          "answer": "agree",
          "idealism": "2",
          "decision": "8",
          "agreeableness": "7",
          "assertiveness": "1",
          "environmental_health": "8",
          "social_equality": "8",
          "education": "8",
          "law_regulation": "3"
        },
        {
          "answer": "strong_agree",
          "idealism": "6",
          "decision": "14",
          "agreeableness": "16",
          "assertiveness": "2",
          "environmental_health": "12",
          "social_equality": "16",
          "education": "16",
          "law_regulation": "4"
        }
      ]


    },
    {
      "question": "I miss Social Security.",
      "questioncode": "q8",
      "answers": [
        {
          "answer": "strong_disagree",
          "wealth_maximization": "6",
          "decision": "4"
        },
        {
          "answer": "disagree",
          "idealism": "2",
          "decision": "3",
          "agreeableness": "1",
          "wealth_maximization": "3",
          "social_equality": "1"
        },
        {
          "answer": "neutral",
          "decision": "2",
          "idealism": "4",
          "agreeableness": "2",
          "social_equality": "2"
        },
        {
          "answer": "agree",
          "idealism": "10",

          "agreeableness": "3",
          "social_equality": "4"
        },
        {
          "answer": "strong_agree",
          "idealism": "16",
          "agreeableness": "10",
          "social_equality": "6"
        }
      ]

    },
    {
      "question": "The US should rejoin NATO and the UN.",
      "questioncode": "q9",
      "answers": [
        {
          "answer": "strong_disagree",
          "assertiveness": "4"
        },
        {
          "answer": "disagree",
          "change": "1",
          "agreeableness": "1",
          "assertiveness": "2",
          "environmental_health": "1",
          "immigration_rights": "1",
          "social_equality": "1",
        },
        {
          "answer": "neutral",
          "idealism": "1",
          "change": "2",
          "agreeableness": "4",
          "decision": "2",
          "environmental_health": "2",
          "immigration_rights": "2",
          "social_equality": "2",
          "law_regulation": "4"
        },
        {
          "answer": "agree",
          "idealism": "2",

          "change": "3",
          "agreeableness": "6",
          "decision": "4",
          "assertiveness": "1",
          "environmental_health": "3",
          "immigration_rights": "3",
          "social_equality": "3",
          "global_relations": "8",
          "law_regulation": "8"
        },
        {
          "answer": "strong_agree",
          "idealism": "6",
          "change": "4",
          "agreeableness": "12",
          "decision": "10",
          "assertiveness": "2",
          "environmental_health": "4",
          "immigration_rights": "4",
          "social_equality": "4",
          "global_relations": "12",
          "law_regulation": "16"
        }
      ]

    },
    {
      "question": "Environmental alarmists sometimes have a point.",
      "questioncode": "q10",
      "answers": [
        {
          "answer": "strong_disagree",
          "religious_fundamentals": "2",
          "assertiveness": "6"
        },
        {
          "answer": "disagree",
          "change": "1",
          "decision": "1",
          "social_healthcare": "1",
          "environmental_health": "2",
          "social_equality": "1",
          "education": "1",
          "religious_fundamentals": "1",
          "assertiveness": "2",

          "global_relations": "2"
        },
        {
          "answer": "neutral",
          "change": "2",
          "decision": "2",
          "social_healthcare": "2",
          "social_equality": "3",
          "education": "2",
          "global_relations": "6"
        },
        {
          "answer": "agree",
          "change": "6",
          "decision": "3",
          "assertiveness": "2",
          "social_healthcare": "3",
          "environmental_health": "14",
          "social_equality": "5",
          "education": "5",
          "global_relations": "8"
        },
        {
          "answer": "strong_agree",
          "change": "10",
          "decision": "8",
          "assertiveness": "6",
          "social_healthcare": "4",
          "environmental_health": "16",
          "social_equality": "8",
          "education": "12",
          "global_relations": "12"
        }
      ]

    },
    {
      "question": "Gender criminals should be allowed to leave cities and not have to pay the deviant tax.",
      "questioncode": "q11",
      "answers": [
        {
          "answer": "strong_disagree",
          "assertiveness": "4",
          "social_equality": "1",
          "religious_fundamentals": "8"
        },
        {
          "answer": "disagree",
          "assertiveness": "2",
          "social_healthcare": "1",
          "social_equality": "2",
          "education": "1",
          "religious_fundamentals": "4",
          "global_relations": "1"
        },
        {
          "answer": "neutral",
          "idealism": "1",
          "change": "2",
          "social_healthcare": "4",
          "social_equality": "8",
          "education": "2",
          "religious_fundamentals": "1",
          "global_relations": "2",
          "law_regulation": "3"
        },
        {
          "answer": "agree",
          "idealism": "2",
          "change": "4",
          "agreeableness": "2",
          "assertiveness": "2",
          "social_healthcare": "5",
          "social_equality": "12",
          "education": "3",
          "global_relations": "3",
          "law_regulation": "6"
        },
        {
          "answer": "strong_agree",
          "idealism": "6",
          "change": "6",
          "agreeableness": "8",
          "assertiveness": "4",
          "social_healthcare": "7",
          "social_equality": "16",
          "education": "4",

          "global_relations": "4",
          "law_regulation": "12"
        }
      ]

    },
    {
      "question": "Women who have been jailed for having abortions should be released.",
      "questioncode": "q12",

      "answers": [
        {
          "answer": "strong_disagree",
          "idealism": "1",
          "change": "2",
          "agreeableness": "2",
          "assertiveness": "2",
          "social_healthcare": "1",
          "social_equality": "1",
          "education": "1",
          "global_relations": "1",
          "law_regulation": "3",
          "religious_fundamentals": "14"
        },
        {
          "answer": "disagree",
          "idealism": "4",
          "change": "4",
          "agreeableness": "4",
          "assertiveness": "4",
          "social_healthcare": "4",
          "social_equality": "2",
          "education": "2",
          "global_relations": "2",
          "law_regulation": "6",
          "religious_fundamentals": "6"
        },
        {
          "answer": "neutral",
          "idealism": "7",
          "change": "6",
          "social_healthcare": "5",
          "social_equality": "8",
          "education": "3",
          "religious_fundamentals": "1",
          "global_relations": "3",

          "law_regulation": "12"
        },
        {
          "answer": "agree",
          "assertiveness": "4",
          "social_healthcare": "7",
          "social_equality": "12",
          "education": "4",
          "global_relations": "4"
        },
        {
          "answer": "strong_agree",
          "assertiveness": "2",
          "social_equality": "14"
        }
      ]
    },
    {
      "question": "Summary deportation of immigrants should cease.",
      "questioncode": "q13",
      "answers": [
        {
          "answer": "strong_disagree",
          "assertiveness": "6",
          "religious_fundamentals": "2"
        },
        {
          "answer": "disagree",
          "assertiveness": "3",
          "social_equality": "1",
          "education": "1",
          "religious_fundamentals": "4",
          "global_relations": "1",
          "law_regulation": "1"
        },
        {
          "answer": "neutral",
          "idealism": "1",
          "agreeableness": "2",
          "change": "2",
          "immigration_rights": "1",
          "social_equality": "2",

          "education": "2",
          "global_relations": "2",
          "law_regulation": "3"
        },
        {
          "answer": "agree",
          "idealism": "4",
          "change": "4",
          "agreeableness": "6",
          "assertiveness": "3",
          "immigration_rights": "12",
          "social_equality": "3",
          "education": "3",
          "global_relations": "3",
          "law_regulation": "5"
        },
        {
          "answer": "strong_agree",
          "idealism": "12",
          "change": "8",
          "agreeableness": "12",
          "assertiveness": "6",
          "immigration_rights": "14",
          "social_equality": "4",
          "education": "4",
          "global_relations": "4",
          "law_regulation": "7"
        }
      ]


    },
    {
      "question": "Non - Profit organizations should be re - legalized.",
      "questioncode": "q14",
      "answers": [
        {
          "answer": "strong_disagree",
          "idealism": "6",
          "assertiveness": "6",
          "wealth_maximization": "12",
          "immigration_rights": "4",
        },

        {
          "answer": "disagree",
          "assertiveness": "1",
          "wealth_maximization": "10",
        },
        {
          "answer": "neutral",
          "social_healthcare": "1",
          "environmental_health": "2",
          "immigration_rights": "1",
          "social_equality": "4",
          "global_relations": "1"
        },
        {
          "answer": "agree",
          "idealism": "2",
          "assertiveness": "1",
          "social_healthcare": "2",
          "environmental_health": "4",
          "immigration_rights": "2",
          "social_equality": "6",
          "global_relations": "2"
        },
        {
          "answer": "strong_agree",
          "idealism": "6",
          "assertiveness": "2",
          "social_healthcare": "4",
          "environmental_health": "8",
          "global_relations": "4"

        }
      ]


    },
    {
      "question": "Maybe we should bring back Medicare.",
      "questioncode": "q15",
      "answers": [
        {
          "answer": "strong_disagree",
          "assertiveness": "6",
          "wealth_maximization": "6",
          "religious_fundamentals": "1"
        },
        {
          "answer": "disagree",
          "assertiveness": "3",
          "wealth_maximization": "5",
          "immigration_rights": "1",
          "social_equality": "1",
          "global_relations": "1"
        },
        {
          "answer": "neutral",
          "idealism": "1",
          "social_healthcare": "6",
          "immigration_rights": "4",
          "social_equality": "4",
          "global_relations": "4"
        },
        {
          "answer": "agree",
          "idealism": "2",
          "change": "6",
          "agreeableness": "2",
          "assertiveness": "2",
          "social_healthcare": "9",
          "environmental_health": "6",
          "immigration_rights": "6",
          "social_equality": "6",
          "global_relations": "6"
        },
        {
          "answer": "strong_agree",
          "idealism": "8",
          "change": "12",
          "agreeableness": "4",
          "assertiveness": "6",
          "social_healthcare": "14",
          "environmental_health": "8",
          "immigration_rights": "8",
          "social_equality": "8",
          "global_relations": "8"
        }
      ]

    },
    {
      "question": "Things have gotten better since Federal Regulatory agencies were disbanded.",
      "questioncode": "q16",

      "answers": [
        {
          "answer": "strong_disagree",
          "idealism": "6",
          "environmental_health": "4",
          "social_equality": "6",
          "law_regulation": "16"
        },
        {
          "answer": "disagree",
          "idealism": "3",
          "environmental_health": "2",
          "social_equality": "4",
          "law_regulation": "8"
        },
        {
          "answer": "neutral",
          "idealism": "1",
          "environmental_health": "1",
          "wealth_maximization": "3",
          "social_equality": "2",
          "law_regulation": "4"
        },
        {
          "answer": "agree",
          "wealth_maximization": "6",
          "law_regulation": "1"
        },
        {
          "answer": "strong_agree",
          "wealth_maximization": "12"
        }
      ]

    }
  ],
  "archetypes": [
    // {
    //   "name": "utopian-virtuoso",
    //   "engagement": "0.80",
    //   "idealism": "0.90",
    //   "change": "0.90",
    //   "decision": "0.40",
    //   "agreeableness": "0.90",
    //   "assertiveness": "0.70",
    //   "social_healthcare": "0.95",
    //   "environmental_health": "0.85",
    //   "wealth_maximization": "0.20",
    //   "immigration_rights": "0.80",
    //   "social_equality": "0.95",
    //   "education": "0.90",
    //   "religious_fundamentals": "0.20",
    //   "global_relations": "0.85",
    //   "law_regulation": "0.80"
    // },
    {
      "name": "justice-warrior",
      "engagement": "0.90",
      "idealism": "0.75",
      "change": "0.80",
      "decision": "0.65",
      "agreeableness": "0.75",
      "assertiveness": "0.85",
      "social_healthcare": "0.85",
      "environmental_health": "0.85",
      "wealth_maximization": "0.30",
      "immigration_rights": "0.70",
      "social_equality": "0.85",
      "education": "0.80",
      "religious_fundamentals": "0.30",
      "global_relations": "0.85",
      "law_regulation": "0.75"
    },
    {
      "name": "astute-logician",
      "engagement": "0.60",
      "idealism": "0.40",
      "change": "0.60",
      "decision": "0.90",
      "agreeableness": "0.85",
      "assertiveness": "0.50",
      "social_healthcare": "0.75",
      "environmental_health": "0.80",
      "wealth_maximization": "0.60",
      "immigration_rights": "0.65",
      "social_equality": "0.80",
      "education": "0.90",
      "religious_fundamentals": "0.20",
      "global_relations": "0.80",
      "law_regulation": "0.85"
    },
    {
      "name": "stalwart-nationalist",
      "engagement": "0.65",
      "idealism": "0.30",
      "change": "0.60",
      "decision": "0.30",
      "agreeableness": "0.20",
      "assertiveness": "0.80",
      "social_healthcare": "0.30",
      "environmental_health": "0.20",
      "wealth_maximization": "0.80",
      "immigration_rights": "0.15",
      "social_equality": "0.20",
      "education": "0.40",
      "religious_fundamentals": "0.85",
      "global_relations": "0.40",
      "law_regulation": "0.30"
    },
    {
      "name": "freedom-steward",
      "engagement": "0.20",
      "idealism": "0.50",
      "change": "0.30",
      "decision": "0.30",
      "agreeableness": "0.30",
      "assertiveness": "0.40",
      "social_healthcare": "0.10",
      "environmental_health": "0.40",
      "wealth_maximization": "0.70",
      "immigration_rights": "0.25",
      "social_equality": "0.25",
      "education": "0.60",
      "religious_fundamentals": "0.70",
      "global_relations": "0.15",
      "law_regulation": "0.05"
    },
    {
      "name": "social-guardian",
      "engagement": "0.50",
      "idealism": "0.60",
      "change": "0.60",
      "decision": "0.60",
      "agreeableness": "0.60",
      "assertiveness": "0.50",
      "social_healthcare": "0.70",
      "environmental_health": "0.70",
      "wealth_maximization": "0.40",
      "immigration_rights": "0.65",
      "social_equality": "0.75",
      "education": "0.75",
      "religious_fundamentals": "0.40",
      "global_relations": "0.75",
      "law_regulation": "0.70"
    },
    // {
    //   "name": "growth-capitalist",
    //   "engagement": "0.65",
    //   "idealism": "0.25",
    //   "change": "0.20",
    //   "decision": "0.70",
    //   "agreeableness": "0.30",
    //   "assertiveness": "0.80",
    //   "social_healthcare": "0.15",
    //   "environmental_health": "0.15",
    //   "wealth_maximization": "0.90",
    //   "immigration_rights": "0.10",
    //   "social_equality": "0.10",
    //   "education": "0.70",
    //   "religious_fundamentals": "0.30",
    //   "global_relations": "0.30",
    //   "law_regulation": "0.15"
    // },
    // {
    //   "name": "civic-observer",
    //   "engagement": "0.30",
    //   "idealism": "0.50",
    //   "change": "0.50",
    //   "decision": "0.40",
    //   "agreeableness": "0.60",
    //   "assertiveness": "0.20",
    //   "social_healthcare": "0.65",
    //   "environmental_health": "0.65",
    //   "wealth_maximization": "0.60",
    //   "immigration_rights": "0.60",
    //   "social_equality": "0.65",
    //   "education": "0.65",
    //   "religious_fundamentals": "0.30",
    //   "global_relations": "0.70",
    //   "law_regulation": "0.60"
    // }
  ],
  "archetypesNameList": [
    // "utopian-virtuoso",
    "justice-warrior",
    "astute-logician",
    "stalwart-nationalist",
    "freedom-steward",
    "social-guardian",
    // "growth-capitalist",
    // "civic-observer"
  ],
  "welcomeMessages": {
    "justice-warrior": `Your passion for equality and fairness burns as brightly as the summer sun. Use your voice wisely to advocate for those who cannot speak for themselves. But beware - your fierce dedication may sometimes cloud your judgment. Take time to listen and reflect, lest you mistake allies for adversaries.`,
    "astute-logician": `Your capacity for rational thought is as precise as an atomic clock. Debates, thought experiments, and complex systems energize your spirit. However, you may need to be reminded to ground your abstract reasoning in practical applications. Your quest for knowledge and understanding should include human beings here on planet Earth.`,
    "freedom-steward": `Like a lone wolf, you thrive on independence and self-reliance. Your instinct for opportunity is as keen as a shark sensing prey in vast economic waters. But remember to focus on innovation rather than mere accumulation. An unexpected encounter with regulation may lead to creative problem-solving.`,
    "stalwart-nationalist": `Like an oak tree deeply rooted in native soil, your patriotism stands firm against the winds of change. Your ability to perceive threats to national identity is as sharp as an eagle's talon. But beware - your unwavering dedication may sometimes narrow your vision.`,
    "social-guardian": `Like a vigilant shepherd, you stand ready to defend the vulnerable and uphold societal values. Your sense of duty is as unwavering as the North Star. But tread carefully - your desire for security may sometimes clash with the winds of change. Take time to distinguish between genuine threats and needed evolution, lest you become an inadvertent obstacle to progress.`,
    // "utopian-virtuoso": `You are the embodiment of idealism and virtue. The Utopian Virtuoso is highly empathetic, honest, and good-hearted, often seeing it as a life-calling to make a positive difference. You are a promoter of social and environmental justice, heroic in your pursuit of a brighter future. You're unafraid to express your views, especially when you champion causes you truly believe in, like sustainability, affordable healthcare, and equal treatment under the law.`,
    // "growth-capitalist": "message for growth-capitalist",
    // "civic-observer": "message for civic-observer"
  }
}

export default political_quiz_json