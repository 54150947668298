import { useState, useEffect } from 'react';
import { Button, Container, Header, Icon, Table } from 'semantic-ui-react';
import data from '../data/cue-list-data.json';

export default function CueList(props) {

    const [cue, setCue] = useState(props.publicData['cue']);

    useEffect(() => {
        setCue(props.publicData['cue']);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.publicData['cue']])

    const goNext = () => {
        if (cue < (data['cues'].length - 1)) props.changeCue(cue + 1);
    }

    const goBack = () => {
        if (cue > 0) props.changeCue(cue - 1);
    }

    const goToCue = (i) => {
        // if (i >= 0 && (i <= (data['cues'].length - 1))) {
        props.changeCue(i);
        // } else {
        // console.error(`error: cue index ${i} out of range 0:${data['cues'].length - 1}`);
        // }
    }

    let cues = [];
    for (let i = 0; i < data['cues'].length; i++) {
        let row;
        if (i === cue) {
            row = <Table.Row positive>
                <Table.Cell><Icon name='checkmark' />{i}</Table.Cell>
                <Table.Cell><Icon name='checkmark' />{data['cues'][i]['no']}</Table.Cell>
                <Table.Cell>{data['cues'][i]['time']}</Table.Cell>
                <Table.Cell>{data['cues'][i]['content']}</Table.Cell>
            </Table.Row>
        } else {
            row = <Table.Row>
                <Table.Cell>{i}</Table.Cell>
                <Table.Cell>{data['cues'][i]['no']}</Table.Cell>
                <Table.Cell>{data['cues'][i]['time']}</Table.Cell>
                <Table.Cell>{data['cues'][i]['content']}</Table.Cell>
            </Table.Row>
        }
        cues.push(row);
    }

    return (
        <div className='panel cueList'>

            <Header as='h2' textAlign='center'>Current Cue: {cue}</Header>
            cues:

            <Button
                content='0: App'
                icon='left arrow'
                labelPosition='left'
                onClick={() => goToCue(0)}
            />
            <Button
                content='1: Hydra'
                floated='right'
                color='blue'
                icon='right arrow'
                labelPosition='right'
                onClick={() => goToCue(1)}
            />
            <Button
                content='2: Gameshow'
                icon='left arrow'
                labelPosition='left'
                onClick={() => goToCue(2)}
            />
            <Button
                content='3: Video Feed'
                icon='left arrow'
                labelPosition='left'
                onClick={() => goToCue(3)}
            />

        </div>

    )
}//  //// // 