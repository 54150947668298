import { getTeamName, calcStockValue, stockConstants } from '../commonFunctions';
import atlas_stock_data from '../data/atlas_stock_data.json'
import political_quiz_json from '../data/political_quiz';

import React, { PureComponent, useContext, useEffect, useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import { getDoc, doc, getFirestore } from 'firebase/firestore';

import '../css/StockTicker.css';

// START https://observablehq.com/@smitop/prices-noise

// old seed: "huhwhat?"
// this seed ("huhwhat???") both start out the same, but ATLAS goes up faster,
//      SPEEF actually has a chance at catching up
// lowest value for SPEEF is like -8700, so start out with a floor of 9000
// the seed "huhwhat?????????" goes up consistently for ATLAS, SPEEF mostly stays the same
const formatter = new Intl.NumberFormat('en-US');

const StockTicker = (props) => {
    const globalContext = useContext(window.GlobalContext)
    let { chat, setChat, user, userData, publicData, signOutUser } = globalContext


    const [userInvestments, setUserInvestments] = useState([0, 0])
    const [userInvestmentsValue, setUserInvestmentsValue] = useState([0, 0])

    const [data, setData] = useState([])
    const [atlasValue, setAtlasValue] = useState(0)
    const [speefValue, setSpeefValue] = useState(0)

    const [atlasIncreased, setAtlasIncreased] = useState(false)
    const [speefIncreased, setSpeefIncreased] = useState(false)

    const [state, setState] = useState({
        activeIndex: 0
    })

    const { STOCKINITIALOFFSET, STOCKFRAME, initialData } = stockConstants

    const stockIndex = publicData.stockIndex;

    const fetchUserInvestments = async () => {
        try {
            const snapshot = await getDoc(doc(getFirestore(), `stocks`, user.uid));
            const snapshotData = snapshot.data();
            let investments = [0, 0];

            if (snapshotData === undefined) {
                return;
            }

            let localStockIndex
            if (stockIndex > initialData.length - 1) {
                localStockIndex = Math.floor(Math.random() * (initialData.length - STOCKFRAME * 2))
            } else {
                localStockIndex = stockIndex;
            }

            investments = calcStockValue(snapshotData, initialData, localStockIndex)
            // console.log(investments);
            window.investments = investments

            // console.log("shittyFix, ", investments);

            // console.log(snapshotData, investments, user.uid);
            setUserInvestments(investments);
        } catch (error) {
            console.error("Error fetching user investments:", error);
        }
    };

    useEffect(() => {

        let dataInFrame = []
        if (stockIndex + STOCKINITIALOFFSET > initialData.length - 1) {
            dataInFrame = initialData.slice(initialData.length - STOCKFRAME, initialData.length)

            for (let i = 0; i < STOCKFRAME; i++) {
                dataInFrame[i].ATLAS += (stockIndex - initialData.length) * 0.01 // add slight randomness
                dataInFrame[i].SPEEF -= (stockIndex - initialData.length) * 0.04 // add slight randomness
            }
        } else {
            dataInFrame = initialData.slice(STOCKINITIALOFFSET + stockIndex, STOCKINITIALOFFSET + stockIndex + STOCKFRAME)
        }

        // console.log(stockIndex, dataInFrame);

        setData(dataInFrame)
        // console.log(initialData, dataInFrame);
        // console.log({ ahh1: dataInFrame[STOCKFRAME - 1].SPEEF, ahh2: dataInFrame[STOCKFRAME - 2].SPEEF });

        setAtlasValue(`${dataInFrame[STOCKFRAME - 1].ATLAS.toFixed(2).padEnd(5, '0')} `)
        setSpeefValue(`${dataInFrame[STOCKFRAME - 1].SPEEF.toFixed(2).padEnd(5, '0')} `)

        // let localUserInvestmentsValueAtlas = [0, 0]

        if (userInvestmentsValue[0] === undefined || isNaN(userInvestmentsValue[0]))
            userInvestmentsValue[0] = 0;

        if (userInvestmentsValue[1] === undefined || isNaN(userInvestmentsValue[1]))
            userInvestmentsValue[1] = 0;

        setSpeefIncreased(dataInFrame[STOCKFRAME - 1].SPEEF > dataInFrame[STOCKFRAME - 2].SPEEF)
        setAtlasIncreased(dataInFrame[STOCKFRAME - 1].ATLAS > dataInFrame[STOCKFRAME - 2].ATLAS)

        // console.log({ speefIncreased, atlasIncreased });

        // setUserInvestments([20, 20])

        fetchUserInvestments()



    }, [publicData.stockIndex]);

    // console.log(userInvestmentsValue);

    // no red
    // green/yellow can look similar

    // let colorKeys = {
    //     "justice-warrior": "#eb00ff",
    //     "astute-logician": "#ebff00",
    //     "freedom-steward": "#008b01",
    //     "stalwart-nationalist": "#006fd8",
    //     "social-guardian": "#00ff0c"
    // }


    return (
        <>
            <div className={`stockTicker ${publicData.rebrand ? "rebrand" : "preRebrand"}`}>
                <div className={`userInvestments ${publicData.rebrand ? "rebrand" : "preRebrand"}`}>
                    <table>
                        <thead>
                            <tr>
                                <th colSpan="3">Account Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='investmentName'>ATLAS</td>
                                <td>${formatter.format(userInvestments[0].toFixed(2))}</td>
                            </tr>
                            <tr>
                                <td className='investmentName'>SPEEF</td>
                                <td>${formatter.format(userInvestments[1].toFixed(2))}</td>
                            </tr>
                            <tr>
                                <td className='investmentName'>Total</td>
                                <td>${formatter.format((userInvestments[0] + userInvestments[1]).toFixed(2))}</td>
                            </tr>
                            <tr>
                                {/* <td> <span className='atlas'>ATLAS:</span> </td> */}
                                <td> <span className={`atlas ${publicData.rebrand ? "rebrand" : "preRebrand"}`}>ATLAS: {atlasValue}</span> </td>
                                <td>{atlasIncreased ? <span className='green triangle'>▲</span> : <span className='red triangle'>▼</span>}</td>
                            </tr>
                            <tr>
                                {/* <td> <span className='speef'>SPEEF:</span> </td> */}
                                <td> <span className={`speef ${publicData.rebrand ? "rebrand" : "preRebrand"}`}>SPEEF: {speefValue}</span> </td>
                                <td>{speefIncreased ? <span className='green triangle'>▲</span> : <span className='red triangle'>▼</span>}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                {/* <ResponsiveContainer width={"70%"} height={"70%"} style={{ top: '15%', left: "10%", bottom: "auto", overflow: 'hidden' }}>
                    <LineChart data={data} >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="index" domain={['dataMin', 'dataMax + 1']} tickLine={false} tick={false} />
                        <YAxis style={{ paddingLeft: "0px", fontSize: "1.2em", fill: "#DDDDDD" }} padding={{ left: "20px" }} tickLine={false} />
                        <Tooltip />
                        <Legend fontWeight={"800"} />
                        <Line strokeWidth={5} isAnimationActive={false} dataKey="ATLAS" stroke="#FF0000" dot={false} />
                        <Line strokeWidth={5} isAnimationActive={false} dataKey="SPEEF" stroke="#0000FF" dot={false} />
                    </LineChart>
                </ResponsiveContainer> */}



                {/* <div className='tickerText'>
                    <span className='atlas'>{atlasText}</span> {atlasIncreased ? <span className='green'>▲</span> : <span className='red'>▼</span>}
                    <br />
                    <span className='speef'>{speefText}</span> {speefIncreased ? <span className='green'>▲</span> : <span className='red'>▼</span>}
                </div> */}

            </div>
        </>
    )
}

export default StockTicker
