import { Button } from 'semantic-ui-react'

import '../css/Quiz.css'
import { Suspense, useState, useContext, useEffect } from 'react'

import { getFirestore, doc, query, orderBy, limitToLast, collection, onSnapshot, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import HydraCanvas from '../components/HydraCanvas.js';

import gameshowQs from '../data/gameshowQs.js';
import DelayedRender from '../components/DelayedRender.js';

import { fallbackResponse, getTeamName } from '../commonFunctions.js';

// Add this constant at the top of the file, after imports
const FONT_SIZE_MULTIPLIER = 1.5; // Adjust this value to change the overall font size

const getDataForAllQuestions = (data) => {
    if (data === undefined) return []
    //NOTE!!!! some of these will be undefined
    return [data[`quizQ0`], data[`quizQ1`], data[`quizQ2`],
    data[`quizQ3`], data[`quizQ4`], data[`quizQ5`],
    data[`quizQ6`], data[`quizQ7`], data[`quizQ8`],
    data[`quizQ9`], data[`quizQ10`], data[`quizQ11`]]
}


const genScore = (score) => {
    // don't care about 1-lining this lmao
    if (8000 <= score) {
        return 1000
    } else if (6000 <= score && score < 8000) {
        return 800
    } else if (4000 <= score && score < 6000) {
        return 600
    } else if (2000 <= score && score < 4000) {
        return 400
    } else if (0 <= score && score < 2000) {
        return 200
    } else if (score <= 0) {
        return 0
    }
}


export default function Quiz(props) {

    const fs = getFirestore();

    const globalContext = useContext(window.GlobalContext)
    let { questionNumber, setQuestionNumber, user, publicData, userData, setLocalQuizStats, localQuizStats } = globalContext

    const [isAnswered, setIsAnswered] = useState(false)
    const [ranOutOfTime, setRanOutOfTime] = useState(false)

    const [questionStartTime, setQuestionStartTime] = useState(null)

    // let lastTimeUpdate = null
    const [lastQuestionNumber, setLastQuestionNumber] = useState(null)

    useEffect(() => {
        if (publicData.questionNumber !== lastQuestionNumber) {
            setQuestionNumber(publicData.questionNumber)
            setLastQuestionNumber(publicData.questionNumber)
            setIsAnswered(false)
            setRanOutOfTime(false)
            // console.log("IN HERE :)");
        } else if (publicData.questionDone) {
            setRanOutOfTime(true)
            // console.log("IN HERE :(");
            // NOTE: -1 indicates that the question was left unanswered
            if (!isAnswered) {
                // console.log("not answered ");

                recordResponse(-1)
            }
        } else if (publicData.timeLeft === publicData?.MAXTIME) {
            setQuestionStartTime(new Date())
            // playAudio('music')
        }
        setQuestionNumber(publicData.questionNumber)

        // console.log({ isAnswered, ranOutOfTime });

    }, [publicData])

    const recordResponse = (response) => {


        if (isAnswered) {
            console.log("already answered");
            return;
        }

        // console.log("IN recordResponse", isAnswered);

        if (response >= 0) {
            setIsAnswered(true)
        }

        const questionId = 'quizQ' + questionNumber
        const questionAnswerTime = new Date()
        // console.log(questionAnswerTime, questionStartTime, questionAnswerTime - questionStartTime);

        let score = Math.max(publicData.MAXTIME - (questionAnswerTime - questionStartTime), 0)
        score = genScore(score)
        if (response === -1)
            score = 0

        // console.log({ score });

        const questionData = {
            [questionId]: {
                response,
                // correct: isQuestionCorrect,
                score
            }
        }

        let quizData = {
            ...questionData,
            archetypeName: userData.archetype.name,
            displayName: userData.displayName
        }

        const ahhh = updateDoc(doc(fs, `quiz`, user.uid), quizData).catch(error => {
            switch (error.code) {
                case 'not-found':
                    console.error("not found");
                    setDoc(doc(fs, `quiz`, user.uid), quizData)
                    break;
                default:
                    console.error(error)
                    break;
            }
        })

        // console.log(ahhh);

    }

    const [topicChatMessageBoxes, setTopicChatMessageBoxes] = useState([false, false, false, false, false, false, false, false])
    const topicList = ["Immigration", "International Relations", "Abortion Rights", "The Environment"]

    const qAs = gameshowQs

    if (questionNumber === NaN || qAs[questionNumber] === undefined) return <></>
    // console.log(questionNumber);

    return <>
        <Suspense>
            <div className='quizContainer' style={{ fontSize: `${FONT_SIZE_MULTIPLIER}em` }}>
                <div className='question'>
                    {qAs[questionNumber].question}
                </div>

                {
                    (ranOutOfTime || publicData.timeLeft <= 0) ? <></> :
                        (
                            <div className='progressBarContainer'>
                                <progress className='timeLeftBar' max="10000" value={publicData.timeLeft}></progress>
                                <span className='timeLeftText'>{Math.ceil(publicData.timeLeft / 1000)}s</span>
                            </div>
                        )}

                {(isAnswered || ranOutOfTime)
                    ? <div>
                        {isAnswered ? <span className='postResponseMessage'>Your response has been recorded</span> : <span className='postResponseMessage'><i>You ran out of time</i></span>}
                        <br />
                    </div>
                    :
                    ((publicData.quizDone || publicData.timeLeft > 0) ? <div className='responses'>
                        {/* <Button disabled={publicData.timeLeft <= 0} content={`test`} /> */}
                        {qAs[questionNumber].answers.map((answer, i) => {
                            return <Button disabled={publicData.timeLeft <= 0} content={`${answer}`} onClick={(e) => {
                                recordResponse(i)
                            }} />
                        })}
                    </div> : <></>)
                }

                {publicData.showQuizResults ? <QuizResults fs={fs} user={user} localQuizStats={localQuizStats} setLocalQuizStats={setLocalQuizStats} publicData={publicData} userData={userData} /> : <></>}

            </div>
        </Suspense>
    </>
}

function UserResultsDisplay({ questionResults, questionNumber }) {
    if (!questionResults) return null;

    return (
        <div className="user-results-display">
            <div className={`result-status ${questionResults.questionCorrect ? 'correct' : 'incorrect'}`}>
                {questionResults.questionCorrect ? "CORRECT" : "INCORRECT"}
            </div>
            <div className="result-stats">
                <div className="stat-item">
                    <span className="stat-label">Score:</span>
                    <span className="stat-value">{questionResults.numberCorrect} / {questionNumber + 1}</span>
                </div>
                <div className="stat-item">
                    <span className="stat-label">Bucks Earned:</span>
                    <span className="stat-value">{questionResults.questionScore.toFixed(0)}</span>
                </div>
                <div className="stat-item">
                    <span className="stat-label">Total Bucks Earned:</span>
                    <span className="stat-value">{questionResults.totalScore.toFixed(0)}</span>
                </div>
            </div>
        </div>
    );
}


function QuizResults(props) {

    const questionNumber = props.publicData.questionNumber
    const [questionResults, setQuestionResults] = useState(undefined)

    const { quizTeamStats, allCorrectAnswers } = props.publicData
    const { localQuizStats, setLocalQuizStats } = props

    // how you're doing

    useEffect(() => {
        getDoc(doc(getFirestore(), 'quiz', props.user.uid)).then((snapshot) => {
            let quizAllQuestionData = getDataForAllQuestions(snapshot.data()).slice(0, questionNumber + 1)
            // console.log(snapshot.data(), questionNumber, quizAllQuestionData);

            // console.log(quizAllQuestionData);

            quizAllQuestionData.forEach((answer, index) => {
                // NOTE: should not get here, means you skipped a question
                // but you should account for it if someone refreshes the page or something :(
                if (answer === undefined) {
                    console.error(`ERROR: quizAllQuestionData[${index}] undefined`, {
                        quizAllQuestionData, 'uid': props.user.uid
                    }, "falling back to 'incorrect answer' data")
                    quizAllQuestionData[index] = {
                        correct: false,
                        score: 0,
                        response: -1
                    }
                }
            })

            // old method
            // let questionCorrect = quizAllQuestionData[questionNumber].correct

            // new method
            // console.log(allCorrectAnswers[questionNumber], quizAllQuestionData[questionNumber].response);
            let quizQuestionData = quizAllQuestionData[questionNumber]
            // console.log('before: ', quizQuestionData);
            quizQuestionData = fallbackResponse(quizQuestionData)
            // console.log('after: ', quizQuestionData);
            // console.log(allCorrectAnswers);
            let questionCorrect = allCorrectAnswers[questionNumber].includes(quizQuestionData.response)
            let questionScore = questionCorrect ? quizQuestionData.score : 0;

            // console.log(questionScore);
            // console.log({ allCorrectAnswers, questionCorrect, questionScore });

            // - \# of questions correct,
            let numberCorrect = quizAllQuestionData.filter((item, index) => {
                let thing = fallbackResponse(item)
                return thing && allCorrectAnswers[index].includes(thing.response)
            }).length,
                numberCorrectOutOf = quizAllQuestionData.length
            // - total score so far

            let totalScore = quizAllQuestionData.reduce((a, b, i) => {
                // allCorrectAnswers[i] ||= []// I HATE THIS I DO NOT WANNA HAVE TO DO THIS BUT I DON'T HAVE FKN TIME
                // console.log(allCorrectAnswers[i], b.response, b.score);
                b = fallbackResponse(b)
                return a + ((allCorrectAnswers[i].includes(b.response)) ? Math.max(b.score, 0) : 0)
            }, 0)



            let userQuizQuestionDataUpdate = {}
            userQuizQuestionDataUpdate[`q${questionNumber}Score`] = Math.max(questionScore, 0)

            let teamScore = quizTeamStats[props.userData.archetype.name]?.totalScore.toFixed(0)
            if (teamScore === undefined)
                teamScore = -1

            let userRank = quizTeamStats.allResults.userSortedTotalScoresWithIds.map((item) => item.id).indexOf(props.user.uid) + 1
            let userTeamRank = quizTeamStats[props.userData.archetype.name]?.userSortedTotalScoresWithIds.map((item) => item.id).indexOf(props.user.uid) + 1

            updateDoc(doc(getFirestore(), 'quiz', props.user.uid), {
                totalScore, teamScore, userRank, userTeamRank
            }).catch((e) => console.error(e))

            setQuestionResults({ questionCorrect, numberCorrect, numberCorrectOutOf, questionScore, totalScore, userRank, userTeamRank })

            setLocalQuizStats({
                totalScore: totalScore,
                teamScore: teamScore,
                userRank,
                userTeamRank
            })

        })
    }, [])

    // if(quizTeamStats[props.userData.archetype.name] !== undefined)


    // NEED TOP 10 USERS
    // GROUP TOTALS
    // INDIVIDUAL TOTAL


    let userResults = questionResults !== undefined ? <div>
        {questionResults.questionCorrect ? "CORRECT" : "INCORRECT"} <br />
        {`you're ${questionResults.numberCorrect} for ${questionNumber + 1}`}<br />
        {`bucks for this question: ${questionResults.questionScore.toFixed(0)}`}<br />
        {`total bucks: ${questionResults.totalScore.toFixed(0)}`}<br />
        {/* {`rank: ${localQuizStats.userRank}`} <br /> */}
        {/* {`top player: ${quizTeamStats.allResults.userSortedTotalScoresWithIds[0].displayName}`}<br /> */}
    </div> : <></>

    let userResultsDisplay = questionResults !== undefined ?
        <UserResultsDisplay questionResults={questionResults} questionNumber={questionNumber} /> : <></>

    return <>
        <div className='quizResults' style={{ fontSize: `${FONT_SIZE_MULTIPLIER}em` }}>
            {userResultsDisplay}
            <br />
            {/* <DelayedRender delay={1000} >{teamResults}</DelayedRender> */}
        </div>
    </>

}


export { QuizResults }
