import { useEffect, useRef } from 'react';
import { update } from 'firebase/database'
import '../css/GiantSlider.css'

import { useState, useContext } from 'react';

import { getFirestore, doc, onSnapshot, setDoc, updateDoc, addDoc, aggregateFieldEqual } from 'firebase/firestore';

import { getTeamName } from '../commonFunctions';

export default function GiantSlider(props) {

    const fs = getFirestore();

    const globalContext = useContext(window.GlobalContext)
    const giantSliderContainer = useRef(null)
    let { userData, publicData } = globalContext
    const [sliderValue, setSliderValue] = useState(50)

    const updateSliderValue = async (e) => {
        const uid = props.user.uid;
        // setSliderValue(e.target.value)

        const giantSlidersRef = doc(fs, 'giantSliders', uid);

        if (userData !== null) {


            await setDoc(giantSlidersRef, {
                "sliderValue": parseInt(e.target.value),
                "archetypeName": userData.archetype.name
            })

            updateCss(e)
        }

    }

    const updateCss = (e) => {
        let t = parseInt(e.target.value);
        let midColor = [67, 87, 109]; // rgba(67, 87, 109, 1)
        let r, g, b;

        if (t < 50) {
            // Fade from red to midColor
            let ratio = t / 50;
            r = Math.round(255 * (1 - ratio) + midColor[0] * ratio);
            g = Math.round(midColor[1] * ratio);
            b = Math.round(midColor[2] * ratio);
        } else {
            // Fade from midColor to green
            let ratio = (t - 50) / 50;
            r = Math.round(midColor[0] * (1 - ratio));
            g = Math.round(midColor[1] * (1 - ratio) + 255 * ratio);
            b = Math.round(midColor[2] * (1 - ratio));
        }

        if (giantSliderContainer.current !== null) {
            giantSliderContainer.current.style.background = `rgb(${r},${g},${b})`;
        }
    }
    const avatarImage = {
        "justice-warrior": "AOC_200.png",
        "social-guardian": "kermit_200.png", // Mr. Rogers
        "stalwart-nationalist": "QAnon_Shaman_200.png",
        "freedom-steward": "musk_200.png",
        "astute-logician": "Spock_200.png", // get new image for spock, his head cuts off
        // "utopian-virtuoso": "kermit_.png"
    }

    return (
        <div ref={giantSliderContainer} className={`giantSliderContainer ${props.className}`}>

            <img className='giantSliderAvatar' src={avatarImage[userData.archetype.name]} />

            <div className='giantSliderStuff'>

                <span className='audienceSentimentMeter'>Audience Sentiment Meter</span>

                <input className='giantSlider slider-pic' type='range'
                    value={sliderValue}

                    onTouchEnd={(e) => {
                        setTimeout(() => {
                            setSliderValue(50)
                            updateSliderValue(e)
                            updateCss(e)
                        }, 100)
                        setTimeout(() => {
                            updateSliderValue(e)
                        }, 200)
                    }}

                    onMouseUp={(e) => {
                        setTimeout(() => {
                            setSliderValue(50)
                            updateSliderValue(e)
                            updateCss(e)
                        }, 100)
                        setTimeout(() => {
                            updateSliderValue(e)
                        }, 200)
                    }}

                    onChange={(e) => {
                        setSliderValue(e.target.value)
                        updateSliderValue(e)
                    }}

                >
                </input>
                <br />
                <span className='sliderValueLabel'>{getTeamName(userData.archetype.name)}</span>
                <br />
                <span className='sliderValueLabel'>{(sliderValue - 50) * 2}</span>
            </div>
        </div >
    )

}